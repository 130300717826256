import axios from 'axios';

class MenuQuestionsService {
	static getItemsWithImageCount(params) {
		return axios.get('MenuQuestions/GetItemsWithImageCount', {
			params: {
				...params,
			},
		});
	}

	static getQX16Data() {
		return axios.get('MenuQuestions/GetQX16Data');
	}

	static getQX7Data() {
		return axios.get('MenuQuestions/GetQX7Data');
	}

	static updateChainConnection(request) {
		return axios.post('MenuQuestions/UpdateChainConnection', request);
	}

	static editX16Answer(request) {
		return axios.post('MenuQuestions/EditX16Answer', request);
	}

	static editX7Answer(request) {
		return axios.post('MenuQuestions/EditX7Answer', request);
	}

	static editX7Group(request) {
		return axios.post('MenuQuestions/EditX7Group', request);
	}

	static addX16Answer(request) {
		return axios.post('MenuQuestions/AddX16Answer', request);
	}

	static addX7Answer(request) {
		return axios.post('MenuQuestions/AddX7Answer', request);
	}

	static clearQuestionsCache() {
		return axios.get('Home/ClearQuestionsCache');
	}

	static addX7Group(request) {
		return axios.post('MenuQuestions/AddX7Group', request);
	}

	static getMenuQuestionsReport() {
		return axios.get('DataRequests/GetMenuQuestionsReport');
	}
}

export default MenuQuestionsService;
