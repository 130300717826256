import React, { useState, useEffect } from 'react';
import { getDataReportsLinks } from '../../utils/api';

const DataReports = function () {
	const [reports, setReports] = useState(null);

	useEffect(() => {
		getDataReportsLinks().then((response) => {
			if (response) setReports(response);
		});
	}, []);

	

	return (
		<div className="data-reports">
			{reports &&
				reports.map(({ data, name }, indexGroup) => {
					return (
						<div key={indexGroup} className="data-reports__group">
							<div className="data-reports__group-name">{name}</div>
							<div className="flex-row">
								{data.map(({ name, url }, indexButton) => {

									const href = (url.includes('https') === true) ? url : `${process.env.REACT_APP_API.slice(0,-1)}${url}`;

									return (
										<div key={indexButton} className="flex-col-3">
											<div key={name} className="button data-reports-button">
												<a
													className="data-reports-button__link"
													href={href}
													target="_blank"
													rel="noopener noreferrer"
												>
													{name.toLowerCase()}
												</a>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					);
				})}
		</div>
	);
};

export default DataReports;
