import React, { useRef, useState, useEffect } from 'react';
import classNames from 'classnames';

import { ReactComponent as IconSearch } from '../../icons/search.svg';
import { ReactComponent as IconRemove } from '../../icons/close.svg';

import './InputAutosuggest.scss';

const InputAutosuggest = ({
	className = '',
	autoComplete = 'off',
	placeholder = 'Search',
	value = '',
	typingDelay = 100,

	optionList = [],
	selectedId = null,
	onSelect = () => {},
}) => {
	const refNodeInput = useRef(null);
	const [searchValue, setSearchValue] = useState('');
	const [optionId, setOptionId] = useState(null);
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		if (Array.isArray(optionList) === true && selectedId) {
			const { name = '', id = null } = optionList.find(({ id }) => id === selectedId) || {};

			setSearchValue(name);
			setOptionId(id);
		}
	}, [selectedId, optionList]);

	const handleInputChange = ({ target }) => {
		const searchString = target.value;
		setSearchValue(searchString);

		const boolOpen = searchString ? true : false;
		setIsOpen(boolOpen);
	};

	const handleInputClick = () => {
		if (searchValue) setIsOpen(true);
	};

	const handleItemClick = (option) => () => {
		setIsOpen(false);
		setSearchValue('');
		if (typeof onSelect === 'function') onSelect(option);
	};

	const handleRemoveButtonClick = (event) => {
		setSearchValue('');
		setIsOpen(false);
	};

	const regex = new RegExp('\\b' + searchValue, 'ig');
	const options = optionList.filter((option) => option.name.toLowerCase().search(regex) !== -1);

	return (
		<div
			className={classNames(
				'input-autosuggest',
				{
					'is-open': isOpen === true && options.length > 0,
				},
				className
			)}
		>
			<input
				ref={refNodeInput}
				className="input-autosuggest__input"
				type="text"
				placeholder={placeholder}
				autoComplete={autoComplete}
				value={searchValue.trimStart()}
				onChange={handleInputChange}
				onClick={handleInputClick}
			/>
			<IconSearch className="input-autosuggest__icon-search" />
			{searchValue && (
				<button className="input-autosuggest__button-remove" onClick={handleRemoveButtonClick}>
					<IconRemove width={12} height={12} />
				</button>
			)}
			{isOpen === true && options.length > 0 && (
				<div className="input-autosuggest-menu">
					<div className="input-autosuggest-list">
						{options.map((option, index) => {
							const caption =
								optionId === option.id
									? option.name
									: option.name.replace(regex, ($1) => `<mark>${$1}</mark>`);

							return (
								<div
									key={index}
									className={classNames('input-autosuggest-item', {
										'input-autosuggest-item--selected': optionId === option.id,
									})}
									onClick={handleItemClick(option)}
									dangerouslySetInnerHTML={{
										__html: caption,
									}}
								/>
							);
						})}
					</div>
				</div>
			)}
		</div>
	);
};

export default InputAutosuggest;
