import React, { forwardRef, useMemo } from 'react';
import classNames from 'classnames';

import { Table, TableHeader, TableRows, TBody } from '../../components/Table';
import InputText from '../../components/InputText';
import DropdownPrimary from '../../components/DropdownPrimary';
import InputAutosuggest from '../../components/InputAutosuggest';

import { ReactComponent as IconTrash } from '../../icons/trash.svg';
import { ReactComponent as IconCheckMark } from '../../icons/check-mark.svg';
import { ReactComponent as IconClose } from '../../icons/close.svg';

import './PresetsTool.Editor.scss';

const PresetEditor = forwardRef(
	(
		{
			className = null,
			chainList = [],

			preset: {
				id = null,
				name = '',
				typeName = null,
				segmentNames = [],
				segmentGroupName = null,
				status = null,

				chains = [],
				companiesList = [],
				lastUpdate = [],
			},

			companies = [],
			statuses = [],

			onChange = () => {},
			onClose = () => {},
			onSave = () => {},
		},
		ref
	) => {
		const chainsTypeOfPreset =
			chains &&
			chains.reduce((chainsType, chainId) => {
				const { type = null } = chainList.find((chain) => chain.id === chainId) || {};

				if ((chainsType !== null && chainsType === type) || type !== null) return type;

				return chainsType;
			}, null);

		const handleAddChain = ({ id }) =>
			onChange({
				chains: [...chains, id],
			});

		const handleCompaniesChange = (options) => {
			onChange({
				companiesList: options,
			});
		};

		const handleRemoveChainClick = (chainId) => () =>
			onChange({
				chains: chains.filter((id) => id !== chainId),
			});

		const handleNameChange = ({ target }) =>
			onChange({
				name: target.value,
			});

		const handleSaveClick = () => onSave();

		const rows = chains.map((chainId) => {
			const chain = chainList.find((chain) => chain.id === chainId);
			return {
				...chain,
			};
		});

		const companyName = useMemo(() => {
			if (companiesList.length === 0 || companiesList.length === companies.length) return 'All';
			else if (companiesList.length > 1) return `${companiesList.length} companies`;
			else {
				const company = companies.find((company) => company.id === companiesList[0]);

				return company ? company.name : '1 company';
			}
		}, [companies, companiesList]);

		return (
			<div
				ref={ref}
				className={classNames(
					'preset-editor',
					{
						'preset-editor--floating': Boolean(id),
					},
					className
				)}
			>
				<div className="preset-editor-header">
					<table className="table table-preset-control">
						<tbody className="table-body">
							<tr>
								<td className="table-cell cell-id">{id ? id : '*'}</td>
								<td className="table-cell cell-name">
									<div>
										<InputText
											className="preset-editor-header__name"
											placeholder="Preset name"
											value={name}
											onChange={handleNameChange}
										/>
										<InputAutosuggest
											className="preset-editor-header__autosuggest"
											placeholder="Add chains"
											value=""
											optionList={chainList.filter(({ id, type }) => {
												if (chainsTypeOfPreset)
													return (
														chains.includes(id) === false &&
														type === chainsTypeOfPreset
													);

												return chains.includes(id) === false;
											})}
											onSelect={handleAddChain}
										/>
									</div>
								</td>
								<td className="table-cell cell-type">{typeName && typeName.split(' ')[0]}</td>
								<td className="table-cell cell-segment">{segmentNames.join(', ')}</td>
								<td className="table-cell cell-group">{segmentGroupName}</td>
								<td className="table-cell cell-company">
									<DropdownPrimary
										className="cell-dropdown"
										toggleLabel={companyName}
										isMultiselect={true}
										isManageButtons
										isSearchField={true}
										searchPlaceholder="Search company"
										optionList={companies}
										selectedList={companiesList}
										onChange={handleCompaniesChange}
										onToggle={() => {
											if (companiesList.length === 0) {
												handleCompaniesChange(companies.map(({ id }) => id));
											}
										}}
									/>
								</td>
								<td className="table-cell cell-chains">({chains.length})</td>
								<td className="table-cell cell-update">
									{lastUpdate && lastUpdate.date
										? new Date(lastUpdate.date)
												.toLocaleDateString('en')
												.replaceAll('/', '.')
										: '-'}
								</td>
								<td className="table-cell cell-editor-name">
									{lastUpdate && lastUpdate.user ? lastUpdate.user : '-'}
								</td>
								<td className="table-cell cell-buttons">
									<div className="cell-buttons-group">
										<button
											className="button-save"
											disabled={[name.length, chains.length].includes(0)}
											onClick={handleSaveClick}
										>
											<IconCheckMark />
										</button>

										<button className="button-close" onClick={onClose}>
											<IconClose height={12} width={12} />
										</button>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div className="preset-editor-content">
					<Table
						className="table-chains-control"
						columns={[
							{
								name: 'ID',
								selector: 'id',
								className: 'cell-id',
							},
							{
								name: 'Name',
								selector: 'name',
								className: 'cell-name',
								columnBody: ({ column, row }) => row.name,
							},
							{
								name: 'Display Name',
								selector: 'displayName',
								className: 'cell-display',
							},
							{
								name: 'Segment ID',
								selector: 'segmentId',
								className: 'cell-segment-id',
							},
							{
								name: 'Segment Name',
								selector: 'segmentName',
								className: 'cell-segment-name',
							},
							{
								name: 'Segment Group',
								selector: 'segmentGroupName',
								className: 'cell-segment-group-name',
							},
							{
								name: 'Default Preset ID',
								selector: 'defaultPresetId',
								className: 'cell-preset-id',
							},
							{
								name: 'Default Preset Name',
								selector: 'defaultPresetName',
								className: 'cell-preset-name',
								columnBody: ({ column, row }) => {
									return row.defaultPresetName;
								},
							},
							{
								selector: null,
								className: 'cell-buttons',
								columnBody: ({ column, row }) => (
									<div className="cell-buttons-group">
										<button
											className="button-remove"
											onClick={handleRemoveChainClick(row.id)}
										>
											<IconTrash />
										</button>
									</div>
								),
							},
						]}
					>
						<TableHeader />
						<TBody>
							<TableRows rows={rows} />
						</TBody>
					</Table>
					{rows.length === 0 && <div className="preset-editor-chains-message">No chains</div>}
				</div>
			</div>
		);
	}
);

export default PresetEditor;
