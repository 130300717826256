import axios from 'axios';

class SurveyService {
	getSurveys() {
		return axios.get('Home/GetSurveys');
	}

	getProviders() {
		return axios.get('Home/GetProviders');
	}

	changeSurveyStatus(params) {
		return axios.post('Home/ChangeSurveyStatus', {...params});
	}

	getSurveyLink(params) {
		return axios.get('Home/GetSurveyLink', {
			params: {
				...params,
			},
		});
	}

	addSurvey(request) {
		return axios.post('Home/AddSurvey', request);
	}
}

export default new SurveyService();
