import React from 'react';
import classNames from 'classnames';

import Modal from '../../components/Modal';
import Button from '../../components/Button';

export default function ModalDialog({
	className = null,
	children = null,
	header = null,
	buttons = [],
	onClose = () => {},
}) {
	return (
		<Modal isCloseOnEscapeKeyDown={false} onClose={onClose}>
			<div className={classNames('modal-dialog', className)}>
				<div className="modal-dialog-header">{header}</div>
				<div className="modal-dialog-content">{children}</div>
				<div className="modal-dialog-bottom">
					{buttons.map(({ name = 'Button', className = null, onClick = () => {} }, index) => {
						return (
							<div key={index} className="modal-dialog-bottom__button">
								<Button isPrimary className={className} onClick={onClick}>
									{name}
								</Button>
							</div>
						);
					})}
				</div>
			</div>
		</Modal>
	);
}
