import { SortDirection } from 'react-virtualized';

import { defaultSortMethod } from '../../utils/helpers';

export const sortRows = (rows, { sortBy = null, sortDirection = null }) => {
	const sortedRows = rows.slice().sort((a, b) => {
		a = a[sortBy];
		b = b[sortBy];

		return defaultSortMethod(a, b);
	});

	return sortDirection === SortDirection.DESC ? sortedRows.reverse() : sortedRows;
};
