import React, { useState, useEffect } from 'react';

import Button from '../../components/Button';
import { Table, TableHeader, TableRows, TBody } from '../../components/Table';
import InputSearch from '../../components/InputSearch';

import Preloader from '../../components/Preloader';
import Modal from '../../components/Modal';

import SurveyEdit from './Surveys.Edit';

import SurveyService from '../../services/survey-service';
import DownloadFileService from '../../services/download-file-service';
// import Notify from '../../utils/notify';

import { ReactComponent as IconDownload } from '../../icons/download.svg';

const SURVEY_STATUS = {
	LIVE: 1,
	PAUSED: 2,
	DRAFT: 3,
	CLOSED: 4,
};

// const notifyFactory = new Notify();

export default function Surveys() {
	const [isDownloading, setIsDownloading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const [isShowModal, setIsShowModal] = useState(false);

	const [surveys, setSurveys] = useState(null);
	const [providers, setProviders] = useState([]);
	const [searchName, setSearchName] = useState('');

	useEffect(() => {
		if (surveys === null) {
			setIsLoading(true);

			SurveyService.getSurveys().then(({ data }) => {
				Array.isArray(data) === true && setSurveys(data);
				setIsLoading(false);
			});
		}
	}, [surveys]);

	useEffect(() => {
		SurveyService.getProviders().then(({ data }) => {
			Array.isArray(data) === true && setProviders(data);
		});
	}, []);

	const handleReportClick = () => {
		setIsDownloading(true);

		DownloadFileService.get('DataRequests/GetSurveysReport').then(() => {
			setIsDownloading(false);
		});
	};

	const handleAddSurveyClick = () => setIsShowModal(true);

	const handleCloseModal = () => setIsShowModal(false);

	const handleSaveSurvey = (survey) => {
		setIsLoading(true);
		setIsShowModal(false);

		SurveyService.addSurvey(survey).then(() => {
			setSurveys(null);
		});
	};

	const handleChangeStatusClick =
		({ id, status }) =>
		() => {
			setIsLoading(true);

			SurveyService.changeSurveyStatus({
				id: id,
				status: status,
			}).then(() => {
				setSurveys(null);
			});
		};

	const handlePreviewClick = (surveyId) => (event) => {
		event.preventDefault();

		SurveyService.getSurveyLink({ surveyId })
			.then(({ data }) => {
				window.open(data, '_blank');
			})
			.catch(() => {});
	};

	// const handleDownLoadFullAnswersClick = (surveyId) => (event) => {
	// 	setIsLoading(true);
	// 	DownloadFileService.get(`DataRequests/DownLoadFullAnswers?surveyId=${surveyId}`).then(() => {
	// 		setIsLoading(false);
	// 	});
	// };

	const regex = new RegExp('\\b' + searchName.toLowerCase(), 'ig');

	const rows =
		Array.isArray(surveys) === true
			? surveys.filter(({ name }) => name.toLowerCase().search(regex) !== -1)
			: [];

	return (
		<div className="wrapper surveys">
			<div className="surveys-header">
				<div className="surveys-header-column">
					<Button
						onClick={handleReportClick}
						isLoading={isDownloading}
						className="button-report"
						hasIcon={true}
					>
						<IconDownload />
						Report
					</Button>
				</div>
				<div className="surveys-header-column">
					<Button className="button-add-survey" onClick={handleAddSurveyClick}>
						Create New Survey
					</Button>
				</div>
			</div>

			{isLoading === true ? (
				<div className="surveys-content">
					<Preloader />
				</div>
			) : (
				<div className="surveys-content">
					{isShowModal === true && (
						<Modal className="surveys-modal modal-w-x2" onClose={handleCloseModal}>
							<SurveyEdit
								providers={providers}
								onSave={handleSaveSurvey}
								onCancel={handleCloseModal}
							/>
						</Modal>
					)}

					<Table
						className="table-surveys"
						columns={[
							{
								name: 'ID',
								selector: 'id',
								sortable: false,
								className: 'cell-id',
							},
							{
								name: 'Name',
								selector: 'name',
								sortable: false,
								className: 'cell-name',
								columnHead: (column) => {
									return (
										<InputSearch
											className="name-search"
											value={searchName}
											placeholder={column.name}
											onChange={(valueText) => {
												const valueTextClean = valueText.trimStart() || '';
												setSearchName(valueTextClean);
											}}
										/>
									);
								},
								columnBody: ({ column, row }) => row.name,
							},
							{
								name: 'Status',
								selector: null,
								sortable: false,
								className: 'cell-status',
								columnBody: ({ column, row }) => {
									const statusName = (
										Object.keys(SURVEY_STATUS)
											.map((name) => ({
												name,
												statusId: SURVEY_STATUS[name],
											}))
											.find(({ statusId }) => statusId === row.status)?.name || ''
									).toLowerCase();

									return (
										<span className={`caption caption--${statusName}`}>{statusName}</span>
									);
								},
							},
							{
								name: 'Actions',
								selector: null,
								sortable: false,
								className: 'cell-actions',
								columnBody: ({ column, row }) => {
									if (row.status === SURVEY_STATUS.LIVE)
										return (
											<Button
												isSecondary
												className="button-close"
												onClick={handleChangeStatusClick({
													id: row.id,
													status: SURVEY_STATUS.CLOSED,
												})}
											>
												Close
											</Button>
										);

									if (row.status === SURVEY_STATUS.DRAFT)
										return (
											<Button
												isSecondary
												className="button-close"
												onClick={handleChangeStatusClick({
													id: row.id,
													status: SURVEY_STATUS.LIVE,
												})}
											>
												Go Live
											</Button>
										);
									return null;
								},
							},
							{
								name: 'Details',
								selector: null,
								sortable: false,
								className: 'cell-details',
								columnBody: ({ column, row }) => {
									return (
										<span>
											<span className="respondents-count">
												{row.currentRespondentsCount
													? row.currentRespondentsCount
													: '...'}
											</span>
											<span className="divider">|</span>
											{row.respondentsQuantity}
										</span>
									);
								},
							},
							{
								name: 'Demo Report',
								selector: null,
								sortable: false,
								className: 'cell-report',
								columnBody: ({ column, row }) => {
									return (
										<a
											className="href-link"
											href={row.demoReportLink}
											target="_blank"
											rel="noopener noreferrer"
										>
											Download
										</a>
									);
								},
							},
							{
								name: 'Survey Preview',
								selector: null,
								sortable: false,
								className: 'cell-preview',
								columnBody: ({ column, row }) => {
									return (
										<button className="href-link" onClick={handlePreviewClick(row.id)}>
											Preview
										</button>
									);
								},
							},
							/*{
								name: 'Full Answers',
								selector: null,
								sortable: false,
								className: 'cell-answers',
								columnBody: ({ column, row }) => {
									if (row.status === SURVEY_STATUS.CLOSED)
										return (
											<button
												onClick={handleDownLoadFullAnswersClick(row.id)}
												className="href-link"
											>
												Download
											</button>
										);

									return null;
								},
							},*/
						]}
					>
						<TableHeader />
						<TBody>
							<TableRows rows={rows} />
						</TBody>
					</Table>
					{rows.length === 0 && searchName && (
						<div className="chains-categories-message">Not found ...</div>
					)}
				</div>
			)}
		</div>
	);
}
