import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { defaultTableRowRenderer, AutoSizer, Column, Table, SortDirection } from 'react-virtualized';

import Preloader from '../../components/Preloader';
import Button from '../../components/Button';
import DropdownPrimary from '../../components/DropdownPrimary';
import InputSearch from '../../components/InputSearch';
import InputText from '../../components/InputText';
import InputSwitch from '../../components/InputSwitch';
import ButtonSwitch from '../../components/ButtonSwitch';
import ModalDialog from '../../components/ModalDialog';

import { DropdownTooltipChains, DropdownTooltipWordType, DropdownTooltipParents } from './DropdownTooltip';

import DropupKeywordResponses from './DropupKeywordResponses';
import KeywordExtensionCard from './KeywordExtensionCard';

import HeaderColumn from './HeaderColumn';

import { sortRows } from './helper';
import Notify from '../../utils/notify';

import { MIN_COUNT_SEARCH_LETTERS, EXTENTION_TYPE } from './constants';

import { ReactComponent as IconEye } from '../../icons/eye.svg';
import { ReactComponent as IconCrayon } from '../../icons/crayon.svg';
import { ReactComponent as IconCheck } from '../../icons/check.svg';

import 'react-virtualized/styles.css';

import DownloadFileService from '../../services/download-file-service';
import TOMService from '../../services/tom-service';

import { defaultSortMethod } from '../../utils/helpers';

const WORD_TYPE = {
	KEYWORD: 1,
	RESPONSE: 2,
};

const CHAIN_TYPES = [
	{
		name: 'RESTAURANTS',
		id: 1,
	},
	{
		name: 'C-STORES',
		id: 3,
	},
	{
		name: 'GROCERY',
		id: 2,
	},
];

const isAllowCreateKeyword = ({ searchWord = '', tomResponses = [] }) => {
	const responses = tomResponses || [];
	return (
		searchWord.length >= MIN_COUNT_SEARCH_LETTERS &&
		responses.some((word) => word.t === searchWord) === false
	);
};

const [initialChainType] = CHAIN_TYPES;

const initialFilter = {
	isOnlyParents: false,
	countFrom: null,
	countTo: null,
	searchWord: '',
	wordTypeId: null,
};

const initialTableConfig = {
	sortBy: 'n',
	sortDirection: SortDirection.DESC,
};

const initialOpenedTooltipChainsState = {
	indexId: null,
	useChainsState: []
};

const notifyFactory = new Notify();

export default function TopOfMind() {
	const refTable = useRef(null);
	const refTextEditNode = useRef(null);

	const [isLoadingExport, setIsLoadingExport] = useState(false);

	const [collection, setCollection] = useState({
		chains: null,
		periods: null,
		lastUpdate: null,
	});

	const [isLoadingInit, setIsLoadingInit] = useState(false);

	const [isLoadingResponses, setIsLoadingResponses] = useState(false);
	const [tomResponses, setTomResponses] = useState(null);

	const [isLoadingKeywordExtensions, setIsLoadingKeywordExtensions] = useState(false);
	const [keywordExtensions, setKeywordExtensions] = useState(null);
	const [keywordResponses, setKeywordResponses] = useState(null);

	const [chainTypeId, setChainTypeId] = useState(initialChainType.id);
	const [periodId, setPeriodId] = useState(null);
	const [chainId, setChainId] = useState(null);

	const [filter, setFilter] = useState(initialFilter);

	const [tableConfig, setTableConfig] = useState(initialTableConfig);
	
	const [isScrollToSelectedRow, setIsScrollToSelectedRow] = useState(false);
	const [pointerSelectKeywordId, setPointerSelectKeywordId] = useState(null);
	const [selectedWord, setSelectedWord] = useState(null);
	const [editWord, setEditWord] = useState(null);
	const [confirmStateWord, setConfirmStateWord] = useState(null);
	const [alertRenameWord, setAlertRenameWord] = useState(null);
	const [busyRowIndexId, setBusyRowIndexId] = useState(null);

	const [openedTooltipChainsState, setOpenedTooltipChainsState] = useState(initialOpenedTooltipChainsState);

	const [request, setRequest] = useState({
		tomResponses: false,
		keywordExtensions: false,
		keywordResponses: false,
	});

	useEffect(() => {
		const handleWindowClick = ({ target }) => {
			if (target.closest('.word-editor') === null) setEditWord(null);
		};

		window.addEventListener('click', handleWindowClick);

		return () => {
			window.removeEventListener('click', handleWindowClick);
		};
	}, []);

	useEffect(() => {
		if (editWord) refTextEditNode.current.value = editWord.t;
	}, [editWord]);

	useEffect(() => {
		setIsLoadingInit(true);

		TOMService.getInitialData({
			chainType: chainTypeId,
		}).then(({data}) => {
			setIsLoadingInit(false);

			if (data) {
				setCollection((current) => ({
					...current,
					chains: data.chains,
					periods: data.periods,
					lastUpdate: data.lastUpdate,
				}));

				const [firstPeriod = null] = (data.periods || []).slice(-1);

				if (firstPeriod) setPeriodId(firstPeriod.id);

				setRequest((current) => ({
					...current,
					tomResponses: true,
				}));
			}
		});
	}, [chainTypeId]);

	useEffect(() => {
		if (request.keywordExtensions === true) {

			setRequest((current) => ({
				...current,
				keywordExtensions: false,
			}));

			setIsLoadingKeywordExtensions(true);

			TOMService.getKeywordExtensions({
				chainType: chainTypeId,
				keywordId: selectedWord.k,
				periodId,
				chainId,
			}).then(({ data }) => {

				setIsLoadingKeywordExtensions(false);

				const keywordExtensions = data?.map((extension, index) => {
					return {
						...extension,
						indexId: index,
					};
				}) ?? null;
				
				setKeywordExtensions(keywordExtensions);

			});

		}
	}, [request.keywordExtensions, selectedWord, keywordExtensions, chainTypeId, periodId, chainId]);

	
	useEffect(() => {
		if (request.tomResponses === true) {
			setIsLoadingResponses(true);

			setRequest((current) => ({
				...current,
				tomResponses: false,
			}));

			TOMService.getTmiResponses({
				chainType: chainTypeId,
				periodId,
				chainId,
			}).then(({data}) => {
				if (Array.isArray(data) === true) {
					const responseWords = data.map((word, index) => {
						return {
							...word,
							indexId: index,
						};
					});
 
					const newSelectedWord = responseWords.find(word => (word.k === pointerSelectKeywordId)) ?? null;
 
					if (newSelectedWord){
						setSelectedWord(newSelectedWord);
						setPointerSelectKeywordId(null);
						setRequest((current) => ({
							...current,
							keywordExtensions: true,
						}));
					}

					setTomResponses(responseWords);
				}

				setIsLoadingResponses(false);
			});
		}
	}, [request.tomResponses, pointerSelectKeywordId, chainTypeId, periodId, chainId]);

	useEffect(() => {
		if (request.keywordResponses === true) {
			setRequest((current) => ({
				...current,
				keywordResponses: false,
			}));

			// @Todo: check periodId or periods
			TOMService.getKeywordResponses({
				chainType: chainTypeId,
				keywordId: selectedWord.k,
				periodId,
				chainId,
			}).then(({data}) => {
				if (Array.isArray(data) === true) setKeywordResponses(data);
			});
		}
	}, [request.keywordResponses, chainTypeId, periodId, chainId, selectedWord]);

	useEffect(() => {
		if (refTable.current) refTable.current.Grid._scrollingContainer.scrollTo(0, 0);
	}, [filter.searchWord]);

	useEffect(() => {
		if (refTable.current){
			const {scrollHeight, clientHeight} = refTable.current.Grid._scrollingContainer;
 
			refTable.current.Grid._scrollingContainer.style.overflow = (scrollHeight === clientHeight) ? `visible` : `hidden auto`;
			refTable.current.Grid._scrollingContainer.childNodes.forEach(node => {
				node.style.overflow = (scrollHeight === clientHeight) ? 'visible' : 'hidden';
			});
		}
	});

	// for scroll to select row
	useEffect(() => {
		if (selectedWord && isScrollToSelectedRow === true && isLoadingResponses === false){
			const nodeRowSecected = document.querySelector('.row.row--selected');
			if (nodeRowSecected === null){
				const currentRowIndex = refTable.current.props.dataRows.findIndex(row => row.indexId === selectedWord.indexId);
				refTable.current.scrollToRow(currentRowIndex);
			}
			
			setIsScrollToSelectedRow(false);
		}
	}, [isScrollToSelectedRow, isLoadingResponses, refTable, selectedWord]);

	const handleExportClick = () => {
		setIsLoadingExport(true);

		DownloadFileService.get(`DataRequests/${(chainId === null) ? 'GetKeywordsChainsReport' : 'GetTmiReport'}`, {
			params:{
				chainType: chainTypeId,
				periodId,
				chainId,				
			}
		}).then(() => {
			setIsLoadingExport(false);
		});
	};

	const handleChainTypeChange = ({ id }) => {
		setTomResponses(null);
		setKeywordExtensions(null);
		setKeywordResponses(null);
		setPeriodId(null);
		setChainId(null);
		setSelectedWord(null);

		setFilter(initialFilter);
		setChainTypeId(id);
	};

	const handlePeriodChange = ([periodId]) => {
		setTomResponses(null);
		setKeywordExtensions(null);
		setKeywordResponses(null);
		setSelectedWord(null);

		setFilter(initialFilter);
		setPeriodId(periodId);

		setRequest((current) => ({
			...current,
			tomResponses: true,
		}));
	};

	const handleChainChange = ([chainId = null]) => {
		setTomResponses(null);
		setKeywordExtensions(null);
		setKeywordResponses(null);
		setSelectedWord(null);

		setFilter(initialFilter);
		setChainId(chainId);

		setRequest((current) => ({
			...current,
			tomResponses: true,
		}));
	};

	const handleRowClick = ({ event, rowData }) => {
		if (['row', 'gridcell'].includes(event.target.getAttribute('role')) === true) {
			if (selectedWord === null || selectedWord.indexId !== rowData.indexId) {
				setSelectedWord(rowData);
				setEditWord(null);
				setKeywordExtensions(null);
				setKeywordResponses(null);

				if (rowData.k > 0) {
					setRequest((current) => ({
						...current,
						keywordExtensions: true,
						keywordResponses: chainId ? true : false,
					}));
				}
			}
		}
	};

	const handleOnlyParentsChange = (event) => {
		setFilter((current) => ({
			...current,
			isOnlyParents: event.target.checked,
		}));
	};

	const handleCountFilterChange = (prop) => (event) => {
		const numValue = parseInt(event.target.value);
		const number = isNaN(numValue) === true ? null : numValue;

		setFilter((current) => ({
			...current,
			[prop]: number,
		}));
	};

	const handleInputSearchChange = (valueText) => {
		setFilter((filter) => {
			return {
				...filter,
				searchWord: valueText || '',
			};
		});
	};

	const handleWordTypeIdChange = ({ id = null }) =>
		setFilter((filter) => {
			return {
				...filter,
				wordTypeId: id,
			};
		});

	const handleParentWordClick = (parentWord) => handleInputSearchChange(parentWord.t);

	const handleWordStateClick = (word) => (event) => {
		event.stopPropagation();
		event.preventDefault();

		if (word.k > 0) {
			setConfirmStateWord({
				...word,
				messages: ['Are you sure? Chains settings will be removed.'],
			});
		} else {
			const specialInclude = word.p?.find((parent) => parent.s === true) || null;

			if (specialInclude) {
				const parent =
					tomResponses?.find((responseWord) => {
						return specialInclude.i === responseWord.k;
					}) || null;

				setConfirmStateWord({
					...word,
					messages: [
						`"${word.t}" is a child of keyword '${parent.t}'.`,
						`If you want to make a keyword from "${word.t}", remove it from includes of keyword '${parent.t}'.`,
					],
				});
			} else {
				handleChangeWordState(word);
			}
		}
	};

	const handleChangeWordState = (word) => {
		setBusyRowIndexId(word.indexId);
		setConfirmStateWord(null);

		if (word.k > 0) {

			const serviceRemoveKeyword = () => {
				if (chainId === null)
					return TOMService.deleteKeyword({
							keywordId: word.k,
							periodId,
						});
			
					return TOMService.unassignKeyword({
							keywordId: word.k,
							periodId,
							chainId,
						});
			}

			serviceRemoveKeyword()
				.then(({data}) => {
					setBusyRowIndexId(null);

					if (data === null || data.n === 0) notifyFactory.add(`Response is removed`);

					if (selectedWord && word.k === selectedWord.k) {
 
						setSelectedWord((selectedWord) => ({
							...selectedWord,
							...data,
						}));

						setKeywordExtensions(null);
						setKeywordResponses(null);
					}

					setRequest((current) => ({
						...current,
						tomResponses: true,
					}));
				});
				
		} else {


			const serviceCreateKeyword = () => {
				if (chainId === null)
					return TOMService.createKeyword({
						chainType: chainTypeId,
						word: word.t,
						periodId,
						chainsIds: chainId === null ? word?.c.map(({ i }) => i) : null
					});

					return TOMService.assignWordToChain({
						chainType: chainTypeId,
						word: word.t,
						periodId,
						chainId
					});
			}

			
			serviceCreateKeyword().then(({data}) => {
				setBusyRowIndexId(null);

				if (selectedWord && word.indexId === selectedWord.indexId)
					setSelectedWord((selectedWord) => ({
						...selectedWord,
						...data,
					}));

				setRequest((current) => ({
					...current,
					tomResponses: true,
					keywordExtensions: (selectedWord && word.indexId === selectedWord.indexId),
					keywordResponses: (chainId !== null)
				}));

			});
		}
	};

	const handleChangeRowChains = ({ chains = [], rowData }) => {
		setBusyRowIndexId(rowData.indexId);

		TOMService.assignToChains({
			chainType: chainTypeId,
			keywordId: rowData.k,
			chainsIds: chains.filter((chain) => chain.u).map((chain) => chain.i),
			needToUpdateCounts: true,
		}).then(() => {
			setBusyRowIndexId(null);

			if (chainId === null) {
				setTomResponses((words) => {
					return words.map((word) => {
						if (word.indexId === rowData.indexId)
							return {
								...word,
								c: chains,
							};
						return word;
					});
				});

				if (selectedWord && selectedWord.indexId === rowData.indexId)
					setRequest((current) => ({
						...current,
						keywordExtensions: true,
					}));
			} else {
				setRequest((current) => ({
					...current,
					tomResponses: true,
				}));
			}
		});
	};

	const handleTooltipChainsState = useChains => {
		
 
		setOpenedTooltipChainsState(tooltipChainsState => ({
			...tooltipChainsState,
			useChainsState: useChains,
		}));

	}


	const handleTooltipChainsToggle = ({isOpen, rowData}) => {
		if (isOpen === true) {

			const useChains = Array.isArray(rowData.c) === true
			? rowData.c
			: [];

			const useChainList = useChains
				.map((useChain) => {
					const chain = collection.chains.find((chain) => useChain.i === chain.id) || null;

					if (chain)
						return {
							...useChain,
							...chain,
							uDefault: useChain.u,
						};

					return null;
				})
				.sort((a, b) => {
					a = a['name'];
					b = b['name'];

					return defaultSortMethod(a, b);
				});

			if (openedTooltipChainsState.indexId !== rowData.indexId)
				setOpenedTooltipChainsState({
					indexId: rowData.indexId,
					useChainsState: useChainList
				});
		}

		if (isOpen === false && rowData.indexId === openedTooltipChainsState.indexId)
			setOpenedTooltipChainsState(initialOpenedTooltipChainsState);
	}

	const handleCreateResponseClick = () => {

		setIsLoadingResponses(true);

		const serviceCreateKeyword = () => {
			if (chainId === null)
				return TOMService.createKeyword({
					chainType: chainTypeId,
					word: filter.searchWord,
					periodId,
					chainsIds: null,
				});

				return TOMService.assignWordToChain({
					chainType: chainTypeId,
					word: filter.searchWord,
					periodId,
					chainId,
					chainsIds: null,
				});
		}

 
		serviceCreateKeyword().then(() => {
			setRequest((current) => ({
				...current,
				tomResponses: true,
			}));
		});
	};

	const handleEditWordClick = (rowData) => (event) => {
		event.stopPropagation();
		setEditWord(rowData);
	};

	const handleEditWordApplyClick = (event) => {
		event.stopPropagation();

		const valueText = refTextEditNode.current.value.trim();

		if (valueText.length === 0 || valueText === editWord.t) {
			setEditWord(null);
			return;
		}

		const specialIncludeParent =
			tomResponses.find((row) => {
				return (
					row.t === valueText &&
					Array.isArray(row.p) === true &&
					row.p.some((parent) => parent.s === true) === true
				);
			}) || null;

		if (specialIncludeParent) {
			const specialInclude =
				specialIncludeParent.p
					.map((parent) => {
						const keyword = tomResponses.find((item) => item.k === parent.i) || null;
						return {
							...parent,
							t: keyword ? keyword.t : '',
						};
					})
					.find((parent) => parent.s === true) || null;

			setAlertRenameWord({
				messages: [
					`"${editWord.t}" couldn't be corrected to the '${specialIncludeParent.t}'.`,
					`'${specialIncludeParent.t}' is corrected already to '${
						specialInclude ? specialInclude.t : ``
					}'.`,
				],
			});

			setEditWord(null);

			return;
		}

		if (Array.isArray(editWord.p) === true) {
			const parentSpecialInclude = editWord.p.find((parent) => parent.s === true) || null;

			if (parentSpecialInclude) {
				const specialIncludeKeyword =
					tomResponses.find(({ k }) => parentSpecialInclude.i === k) || null;

				setAlertRenameWord({
					messages: [
						`"${editWord.t}" is already corrected to '${specialIncludeKeyword.t}'.`,
						`To correct "${editWord.t}" to the "${valueText}", remove "${editWord.t}" from '${specialIncludeKeyword.t}'' includes.`,
					],
				});

				setEditWord(null);

				return;
			}

			const isSameAsParent = editWord.p.some(({ i }) => {
				return tomResponses.some((word) => word.k === i && word.t === valueText);
			});

			if (isSameAsParent === true) {
				setAlertRenameWord({
					messages: [`${valueText} exists already as parent`],
				});

				setEditWord(null);

				return;
			}
		}

		if (Array.isArray(keywordExtensions) === true){

			const isInExtentionInclude = keywordExtensions.some(extension => (extension.t === EXTENTION_TYPE.INCLUDE && extension.txt === valueText));
			const isInResponses = tomResponses?.some(word => (word.k > 0 && word.t === valueText));
		 
			if (isInExtentionInclude && isInResponses){
				setAlertRenameWord({ 
					messages: [
						`"${editWord.t}" has already custom include "${valueText}".`,
						`If you want to rename "${editWord.t}" to keyword "${valueText}", remove custom include "${valueText}" from includes of keyword "${editWord.t}"`,
					],
				});	
				
				return;	
			}		
		}

		const notificationRenamedRow  = (response) => {
			const messages = [
				`You’ve changed response <b>“${editWord.t}”</b> to <b>“${valueText}”</b>`,
			];
			const similarRow = tomResponses.find(word => {
				if (response.isNewKeyword === true) 
					return (word.t === valueText)
				return (word.k === response.keywordId)
			}) || null;

			if (similarRow) {
				const newCount = similarRow.n + editWord.n;
				messages.push(`Total count for <b>“${similarRow.t}”</b> ${newCount}`);
			}

			setAlertRenameWord({
				messages,
			});
		}


		setBusyRowIndexId(editWord.indexId);

		if (editWord.k > 0){

			TOMService.renameKeyword({
				chainType: chainTypeId,
				keywordId: editWord.k,
				newText: valueText,
				periodId,
			}).then(({data}) => {

				setBusyRowIndexId(null);
				setEditWord(null);

				notificationRenamedRow(data.response);

				if (data.response.isNewKeyword === true){
					setPointerSelectKeywordId(data.response.keywordId);
				}
				setRequest((current) => ({
					...current,
					tomResponses: true,
					keywordExtensions: false,
				}));

			});

		} else {

			TOMService.renameResponse({
				chainType: chainTypeId,
				oldText: editWord.t,
				newText: valueText,
				periodId,
			}).then(({data}) => {

				setBusyRowIndexId(null);
				setEditWord(null);

				notificationRenamedRow(data.response);

				setRequest((current) => ({
					...current,
					tomResponses: true,
					keywordExtensions: false,
				}));

			});
		}

	};

	const handleSortChange = ({ sortBy, sortDirection }) => {
		setTableConfig((prevState) => {
			return {
				...prevState,
				sortBy,
				sortDirection,
			};
		});

		refTable.current && refTable.current.Grid._scrollingContainer.scrollTo(0, 0);
	};

	const handleRemoveExtension = (row) => {
		setKeywordExtensions((extensions) => {
			return extensions.filter((extension) => extension.indexId !== row.indexId);
		});

		setRequest((current) => ({
			...current,
			tomResponses: true,
			keywordExtensions: true,
		}));

		setIsScrollToSelectedRow(true);
	};

	const handleAddedExtension = () => {
		setRequest((current) => ({
			...current,
			tomResponses: true,
			keywordExtensions: true,
		}));
	};

	const handleChainsChangeExtension = () => {
		setRequest((current) => ({
			...current,
			tomResponses: true,
			keywordExtensions: true,
		}));
	};
	

	const regEx = new RegExp(filter.searchWord.toLowerCase(), 'ig');

	const responseWordsSelected =
		tomResponses
			?.filter((word) => {
				if (filter.isOnlyParents === true && word.ip === false) return false;

				if (Number.isInteger(filter.countFrom) === true && word.n < filter.countFrom) return false;

				if (Number.isInteger(filter.countTo) === true && word.n > filter.countTo) return false;

				if (filter.wordTypeId && filter.wordTypeId === WORD_TYPE.KEYWORD && word.k <= 0) return false;

				if (filter.wordTypeId && filter.wordTypeId === WORD_TYPE.RESPONSE && word.k > 0) return false;

				return word.t.toLowerCase().search(regEx) !== -1;
			})
			.map((word) => {
				return {
					...word,
					countUseChains: word.c?.reduce((sum, chain) => {
						return chain.u === true ? sum + 1 : sum;
					}, 0),
					countChains: word.c ? word.c?.length : null,
				};
			}) || [];

	const responseWords = sortRows(responseWordsSelected, {
		sortBy: tableConfig.sortBy,
		sortDirection: tableConfig.sortDirection,
	});

	const isAllowCreateRecord = isAllowCreateKeyword({
		searchWord: filter.searchWord,
		tomResponses,
	});

	const selectedPeriod = collection.periods?.find(({ id }) => id === periodId) || null;
	const selectedChain = collection.chains?.find(({ id }) => id === chainId) || null;


	return (
		<div className="wrapper tom">
			<div className="tom-header">
				<div className="tom-header__column">
					<ButtonSwitch
						options={CHAIN_TYPES}
						selectedOptionId={chainTypeId}
						onChange={handleChainTypeChange}
						name="sections"
					/>
				</div>
				<div className="tom-header__column">
					{collection.lastUpdate && (
						<div className="note-last-update">
							Last update was on{' '}
							{collection.lastUpdate &&
								new Date(collection.lastUpdate).toLocaleDateString('en', {
									month: 'long',
									day: 'numeric',
									year: 'numeric',
								})}
						</div>
					)}
					<NavLink
						to="/analytics-settings/top-of-mind-items/update-wordcloud-view/"
						activeClassName="is-selected"
						className="link-to-wordcloud"
					>
						UPDATE WORD CLOUD
					</NavLink>
				</div>
			</div>
			<div className="tom-card tom-header">
				<div className="tom-header__caption">
					{selectedChain ? `${selectedChain.name} mapping` : `Global mapping`}
				</div>
				<div className="tom-header__side">
					<Button
						isSecondary
						className="button-export"
						onClick={handleExportClick}
						isLoading={isLoadingExport}
					>
						Export
					</Button>

					{Array.isArray(collection.periods) && (
						<DropdownPrimary
							className="dropdown-period"
							placeholder="All period"
							toggleLabel={selectedPeriod ? selectedPeriod.name : `Period`}
							isMultiselect={false}
							optionList={collection.periods.map((period) => ({
								id: period.id,
								name: period.name,
							}))}
							selectedList={[periodId]}
							onChange={handlePeriodChange}
						/>
					)}
					{Array.isArray(collection.chains) && (
						<DropdownPrimary
							className="dropdown-chains"
							placeholder="All chains"
							toggleLabel={selectedChain ? selectedChain.name : `All chains`}
							isMultiselect={false}
							isSearchField={true}
							searchPlaceholder="Search chain"
							optionList={[
								{
									name: 'All chains',
									id: null,
								},
								...collection.chains,
							]}
							selectedList={chainId ? [chainId] : [null]}
							onChange={handleChainChange}
						/>
					)}
				</div>
				{isLoadingInit === true && <Preloader 
					isCurtain={true} 
					isCaption={false} 
				/>}
			</div>

			<div className="tom-cards">
				<KeywordExtensionCard
					className="excludes"
					caption="Excludes"
					extensionType={EXTENTION_TYPE.EXCLUDE}
					keyword={selectedWord && selectedWord.k > 0 ? selectedWord : null}
					chains={collection.chains}
					chainId={chainId}
					chainTypeId={chainTypeId}
					extensions={keywordExtensions ? keywordExtensions : []}
					isLoadingExtensions={isLoadingKeywordExtensions}
					onRemoveExtension={handleRemoveExtension}
					onAddedExtension={handleAddedExtension}
					onChainsChange={handleChainsChangeExtension}
				/>

				<div className="tom-card tom-card--words">
					<div className="tom-card-header">
						<div className="tom-card-header__row row-flex-end">
							<label className="filter-parent">
								<InputSwitch
									className="filter-parent__switch"
									type="checkbox"
									onChange={handleOnlyParentsChange}
									checked={filter.isOnlyParents}
									autoComplete="off"
								/>
								Only parents
							</label>
						</div>
						<div className="tom-card-header__row">
							<div className="tom-card-header__column">
								{tomResponses && (
									<div className="total-count">Total: {responseWords.length}</div>
								)}
							</div>
							<div className="tom-card-header__column">
								<div className="filter-range">
									From:
									<InputText
										value={filter.countFrom === null ? '' : filter.countFrom}
										onChange={handleCountFilterChange('countFrom')}
										className="input-text-from"
										maxLength="5"
									/>
									To:
									<InputText
										value={filter.countTo ? filter.countTo : ''}
										onChange={handleCountFilterChange('countTo')}
										className="input-text-to"
										maxLength="5"
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="tom-card-search">
						{isAllowCreateRecord === true && (
							<Button
								isSecondary
								className="tom-card-search__button"
								onClick={handleCreateResponseClick}
							>
								Create
							</Button>
						)}
						<InputSearch
							value={filter.searchWord}
							placeholder="Search or create response..."
							onChange={handleInputSearchChange}
						/>
					</div>
					<div className="tom-card-table">
						{tomResponses && (
							<AutoSizer>
								{({ width, height }) => (
									<Table
										dataRows={responseWords}
										className="tom-table"
										ref={refTable}
										rowClassName="row"
										gridClassName="grid"
										headerClassName="column"
										headerHeight={24}
										width={width}
										height={height}
										rowHeight={27}
										rowCount={responseWords.length}
										rowGetter={({ index }) => responseWords[index]}
										sort={handleSortChange}
										sortBy={tableConfig.sortBy}
										sortDirection={tableConfig.sortDirection}
										onRowClick={handleRowClick}
										rowRenderer={({ className, rowData, ...row }) => {
											return defaultTableRowRenderer({
												className: classNames(className, {
													'row--selected':
														selectedWord &&
														selectedWord.indexId === rowData.indexId,
													'row--busy': busyRowIndexId === rowData.indexId,
												}),
												rowData,
												...row,
											});
										}}
										noRowsRenderer={() => {
											return (
												<div className="tom-card-message tom-card-message--no-records">
													No matching records found
												</div>
											);
										}}
										rowStyle={{ overflow: 'visible' }}
									>
										<Column
											className="cell cell-parents"
											label={null}
											dataKey={'p'}
											width={20}
											style={{ overflow: 'visible' }}
											cellRenderer={({ rowData }) => {
												if (rowData.p && rowData.p.length > 0)
													return (
														<DropdownTooltipParents
															rowParents={rowData.p}
															wordResponses={tomResponses}
															nodeOffsetPosition={refTable.current.Grid._scrollingContainer}
															onWordClick={handleParentWordClick}
														/>
													);

												return null;
											}}
										/>
										<Column
											className="cell cell-text"
											dataKey={'t'}
											width={chainId === null ? width * 0.35 : width * 0.55}
											headerRenderer={(column) => HeaderColumn('Name', column)}
											cellRenderer={({ rowData }) => {
												const isSpecial =
													Array.isArray(rowData.p) === true
														? rowData.p.some(({ s }) => s === true)
														: false;

												const hasEdit = Array.isArray(rowData.c) === true && rowData.c.length > 0;

												if (editWord && editWord.indexId === rowData.indexId)
													return (
														<div className="word-editor">
															<input
																className="word-editor__input"
																type="text"
																ref={refTextEditNode}
															/>
															<button
																className="button-edit-apply"
																onClick={handleEditWordApplyClick}
															>
																<IconCheck
																	fill="#ffffff"
																	width={12}
																	height={12}
																/>
															</button>
														</div>
													);

												return (
													<div className="word-editor" role="gridcell">
														<span className="word-name" role="gridcell" title={rowData.t}>
															{isSpecial === true ? (
																<strike>{rowData.t}</strike>
															) : (
																rowData.t
															)}
														</span>
														{selectedWord && hasEdit === true &&
															selectedWord.indexId === rowData.indexId && (
																<button
																	className="button-edit"
																	onClick={handleEditWordClick(rowData)}
																	disabled={(isLoadingKeywordExtensions === true)}
																>
																	<IconCrayon
																		fill="#ffffff"
																		width={12}
																		height={12}
																	/>
																</button>
															)}
													</div>
												);
											}}
										/>
										<Column
											className="cell cell-count"
											headerClassName="column-count"
											headerRenderer={(column) => HeaderColumn('Count', column)}
											dataKey="n"
											width={60}
											defaultSortDirection={SortDirection.DESC}
											cellRenderer={({ rowData }) => {
												return rowData.n;
											}}
										/>
										<Column
											className="cell cell-word-state"
											headerClassName="column-word-state"
											headerRenderer={() => {
												return (
													<DropdownTooltipWordType
														options={[
															{
																id: null,
																name: 'All',
															},
															{
																id: WORD_TYPE.KEYWORD,
																name: 'Keywords',
															},
															{
																id: WORD_TYPE.RESPONSE,
																name: 'Responses',
															},
														]}
														selectedOptionId={filter.wordTypeId}
														onChange={handleWordTypeIdChange}
													/>
												);
											}}
											disableSort={true}
											dataKey="k"
											width={40}
											cellRenderer={({ rowData }) => {
												return (
													<div
														className="cell-word-state__switch"
														onClick={handleWordStateClick(rowData)}
													>
														<IconEye
															width={16}
															className="icon-word-state"
															fill={rowData.k > 0 ? `#30BFE7` : `#BEBEBE`}
														/>
													</div>
												);
											}}
										/>
										{chainId === null && (
											<Column
												className="cell cell-chains"
												headerClassName="column-chains"
												headerRenderer={(column) => HeaderColumn('Chains', column)}
												dataKey="countUseChains"
												defaultSortDirection={SortDirection.DESC}
												width={64}
												style={{ overflow: 'visible' }}
												cellRenderer={({ rowData }) => {
													const countCaption =
														rowData.c === null
															? `-`
															: `${rowData.countUseChains}/${rowData.countChains}`;

													if (rowData.countChains)
														return (
															<DropdownTooltipChains
																captionToggle={countCaption}
																nodeOffsetPosition={refTable.current.Grid._scrollingContainer}
																isOpened={rowData.indexId === openedTooltipChainsState.indexId}
																useChains={openedTooltipChainsState.useChainsState}
																isEditable={(rowData.k > 0)} 
																onToggle={(isOpen) => handleTooltipChainsToggle({isOpen, rowData})}
																onChange={handleTooltipChainsState}
																onApply={(chains) => {
																	handleChangeRowChains({
																		chains,
																		rowData,
																	});
																}}
															/>
														);
													
													return countCaption;
												}}
											/>
										)}
									</Table>
								)}
							</AutoSizer>
						)}
					</div>

					<div className="tom-card-footer">
						{selectedWord && keywordResponses && (
							<DropupKeywordResponses responses={keywordResponses} keyword={selectedWord} />
						)}
					</div>
					{isLoadingResponses === true && <Preloader isCurtain={true} />}
				</div>

				<KeywordExtensionCard
					className="includes"
					caption="Includes"
					extensionType={EXTENTION_TYPE.INCLUDE}
					keyword={selectedWord && selectedWord.k > 0 ? selectedWord : null}
					chains={collection.chains}
					chainId={chainId}
					chainTypeId={chainTypeId}
					extensions={keywordExtensions ? keywordExtensions : []}
					isLoadingExtensions={isLoadingKeywordExtensions}
					onRemoveExtension={handleRemoveExtension}
					onAddedExtension={handleAddedExtension}
					onChainsChange={handleChainsChangeExtension}
				/>
			</div>

			{confirmStateWord && confirmStateWord.k > 0 && (
				<ModalDialog
					className="tom-dialog"
					header={confirmStateWord.messages.map((message, index) => {
						return <div key={index}>{message}</div>;
					})}
					onClose={() => setConfirmStateWord(null)}
					buttons={[
						{
							className: 'button-apply',
							name: 'Yes',
							onClick: () => handleChangeWordState(confirmStateWord),
						},
						{
							className: 'button-cancel',
							name: 'No',
							onClick: () => setConfirmStateWord(null),
						},
					]}
				></ModalDialog>
			)}

			{confirmStateWord && confirmStateWord.k === 0 && (
				<ModalDialog
					className="tom-dialog"
					header={confirmStateWord.messages.map((message, index) => {
						return <div key={index}>{message}</div>;
					})}
					onClose={() => setConfirmStateWord(null)}
					buttons={[
						{
							className: 'button-ok',
							name: 'Ok',
							onClick: () => setConfirmStateWord(null),
						},
					]}
				></ModalDialog>
			)}

			{alertRenameWord && (
				<ModalDialog
					className="tom-dialog"
					header={alertRenameWord.messages.map((message, index) => {
						return (
							<div 
								key={index} 
								dangerouslySetInnerHTML={{ __html: message }} 
							/>
						)
					})}
					onClose={() => setAlertRenameWord(null)}
					buttons={[
						{
							className: 'button-ok',
							name: 'Ok',
							onClick: () => setAlertRenameWord(null),
						},
					]}
				></ModalDialog>
			)}
		</div>
	);
}
