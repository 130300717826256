import { COLLECTION_ACTIONS } from './actionTypes';

import { getGeneralCollections } from '../utils/api';

export const getCollection = () => (dispatch, state) => {
	getGeneralCollections().then((response) => {
		if (response)
			dispatch({
				type: COLLECTION_ACTIONS.STORE_COLLECTIONS,
				payload: {
					chains: response.chains,
					companies: response.companies,
					segmentGroups: response.segmentGroups,
					chainSegmentGroups: response.chainSegmentGroups,
					types: response.types,
					presetStatusList: response.statuses,
					segments: response.segments,
				},
			});
	});
};
