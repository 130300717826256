import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import classNames from 'classnames';
import { DropdownProvider } from './DropdownContext';

const Dropdown = forwardRef(
	(
		{
			className = null,
			children = null,
			isOpened = false,
			isCloseOnItemClick = true,
			onToggle = () => {},
		},
		ref
	) => {
		const refNode = useRef();
		const [isOpen, setIsOpen] = useState(isOpened);

		useImperativeHandle(ref, () => ({
			openDropdown() {
				setIsOpen(true);
			},
			closeDropdown() {
				setIsOpen(false);
			},
		}));
 
		useEffect(() => {
			const handleWindowClick = (event) => {
				if (
					refNode.current &&
					event.target !== refNode.current &&
					!refNode.current.contains(event.target)
				)
					setIsOpen(false);
			};
	
			window.addEventListener('click', handleWindowClick);
	
			return () => {
				window.removeEventListener('click', handleWindowClick);
			};
		}, [refNode]);
	
		useEffect(() => {
			onToggle(isOpen);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [isOpen]);
	
		const handleToggleClick = () => setIsOpen(!isOpen);
	
		const handleItemClick = (event) => {
			event.stopPropagation();
			isCloseOnItemClick && setIsOpen(false);
		};

		return (
			<DropdownProvider
				value={{
					isOpen,
					handleToggleClick,
					handleItemClick,
				}}
			>
				<div
					className={classNames(
						'dropdown',
						{
							opened: isOpen === true,
						},
						className
					)}
					ref={refNode}
				>
					{children}
				</div>
			</DropdownProvider>
		);
	}
);

export default Dropdown;