import React from 'react';
import classNames from 'classnames';

import { ReactComponent as IconGear } from '../../icons/gear.svg';

const UserMenu = ({ className = null, userFullName = null, userInitials = null }) => {
	return (
		<div className={classNames('user-menu header-navbar__dropdown', className)}>
			<span className="user-menu-initials" title={userFullName}>
				{userInitials}
			</span>
			<div className="user-menu-item user-menu-item--back-snap">
				<a className="user-menu-item__link" href={process.env.REACT_APP_ANALITYC}>
					<IconGear fill="#ffffff" className="icon" />
					<span className="user-menu-item__caption">Back to analytics</span>
				</a>
			</div>
			<div className="user-menu-item user-menu-item--back-snap">
				<a className="user-menu-item__link" href={process.env.REACT_APP_URL_SNAP}>
					<IconGear fill="#ffffff" className="icon" />
					<span className="user-menu-item__caption">Back to snap</span>
				</a>
			</div>
		</div>
	);
};

export default UserMenu;
