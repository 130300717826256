import React, { useState, useEffect, useRef } from 'react';

import Preloader from '../../components/Preloader';
import Card from '../../components/Card';

import { WORD_COLORS, WORD_FONT_WEIGHT_SIZE } from '../../utils/wordcloud/constants';

import WordCloud from '../../utils/wordcloud/wordcloud';
import domtoimage from '../../utils/dom-to-image-fixed';

import { updateWordCloud, getWordCloudData, saveWordCloudScreenshot } from '../../utils/api';

const ERROR = {
	CREATE_SCREEN: 'CREATE_SCREEN',
	NOT_SAVED: 'NOT_SAVED',
};

const PROCESS = {
	GENERATION: 'Cloud generation...',
	REQUEST: 'Geting data...',
	PROCESSING: 'Processing...',
	FINISH: 'Finish',
};

export default function UpdateWordCloudView() {
	const refNodeCloudScreen = useRef(null);
	const refNodeCloud = useRef(null);

	const [isLoading, setIsLoading] = useState(false);
	const [process, setProcess] = useState(PROCESS.GENERATION);
	const [summary, setSummary] = useState([]);

	const saveWordCloud = (chain, resolve) => {
		const node = refNodeCloudScreen.current;

		domtoimage
			.toPng(node)
			.then((dataUrl) => {
				const formData = new FormData();
				formData.append('fileData', dataUrl);
				formData.append('chainId', chain.chainId);

				setIsLoading(true);

				saveWordCloudScreenshot(formData).then((response) => {
					setSummary((summary) => {
						return [
							...summary,
							{
								...chain,
								error:
									response === null || response.success === false ? ERROR.NOT_SAVED : null,
							},
						];
					});

					setIsLoading(false);

					resolve();
				});
			})
			.catch((error) => {
				setSummary((summary) => {
					return [
						...summary,
						{
							...chain,
							error: ERROR.CREATE_SCREEN,
						},
					];
				});

				resolve();
			});
	};

	const createWordCloud = (chain, resolve) => {
		const nodeCanvas = document.createElement('canvas');
		nodeCanvas.setAttribute('width', 1500);
		nodeCanvas.setAttribute('height', 1500);

		const nodeCloudSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
		nodeCloudSvg.setAttribute('width', 1000);
		nodeCloudSvg.setAttribute('height', 550);

		WordCloud(nodeCanvas, {
			rotateRatio: 0,
			fontFamily: 'Nunito, sans-serif',
			gridSize: '8',
			shape: 'cardioid',
			origin: [750, 750],
			shuffle: true,
			nodeSvg: nodeCloudSvg,
			backgroundColor: 'rgba(255, 255, 255, 0)',
			list: ((words) => {
				const partCount = Math.ceil(words.length / WORD_COLORS.length);
				let indexColorPointer = 1;
				return words.map((word, index) => {
					if (index >= indexColorPointer * partCount) indexColorPointer++;

					return {
						word: word.text,
						weight: WORD_FONT_WEIGHT_SIZE[word.weight],
						attributes: {
							color: WORD_COLORS[indexColorPointer - 1],
						},
					};
				});
			})(chain.data),
		});

		nodeCanvas.addEventListener('wordcloudstop', () => {
			if (!refNodeCloud.current) return;

			while (refNodeCloud.current.firstChild) {
				refNodeCloud.current.removeChild(refNodeCloud.current.firstChild);
			}

			refNodeCloud.current.appendChild(nodeCloudSvg);

			const nodeTextList = nodeCloudSvg.querySelectorAll('text');
			const cloudBBox = Array.from(nodeTextList).reduce(
				(side, node, index) => {
					const nodeDimension = node.getBBox();
					const rightSide = node.positionX + nodeDimension.width;
					const bottomSide = node.positionY + nodeDimension.height / 2;
					const topSide = node.positionY - nodeDimension.height / 2;

					if (index === 0) {
						side.top = topSide;
						side.left = node.positionX;
						side.right = rightSide;
						side.bottom = bottomSide;
					} else {
						side = {
							top: side.top > topSide ? topSide : side.top,
							bottom: side.bottom < bottomSide ? bottomSide : side.bottom,
							left: side.left > node.positionX ? node.positionX : side.left,
							right: side.right < rightSide ? rightSide : side.right,
						};
					}

					return side;
				},
				{
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
				}
			);

			nodeCloudSvg.setAttribute(
				`viewBox`,
				`${cloudBBox.left} ${cloudBBox.top} ${cloudBBox.right - cloudBBox.left} ${
					cloudBBox.bottom - cloudBBox.top
				} `
			);

			saveWordCloud(chain, resolve);
		});
	};

	const buildProcess = (chains = []) => {
		chains
			.filter((chain) => chain.data.length > 0)
			.reduce((promise, chain) => {
				return promise.then(
					() =>
						new Promise((resolve) => {
							createWordCloud(chain, resolve);
						})
				);
			}, Promise.resolve())
			.then(() => {
				while (refNodeCloud.current.firstChild) {
					refNodeCloud.current.removeChild(refNodeCloud.current.firstChild);
				}

				setProcess(PROCESS.FINISH);
			});
	};

	useEffect(() => {
		setIsLoading(true);
		setProcess(PROCESS.GENERATION);

		updateWordCloud().then(() => {
			setProcess(PROCESS.REQUEST);
			setProcess(PROCESS.REQUEST);
			getWordCloudData().then((response) => {
				if (Array.isArray(response.data) === true) {
					setIsLoading(false);
					buildProcess(response.data);
				}
			});
		});
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const totalCount = summary.length;
	const successCount = summary.filter((item) => item.error === null).length;
	const errorCreateScreenCount = summary.filter((item) => item.error === ERROR.CREATE_SCREEN).length;
	const errorNotSavedCount = summary.filter((item) => item.error === ERROR.NOT_SAVED).length;
	const noDataCount = summary.filter((chain) => chain.data?.length === 0).length;

	return (
		<div className="wrapper word-cloud">
			<Card className="word-cloud-card">
				<div className="word-cloud-header">
					<div className="word-cloud-header__caption">Word Cloud</div>
					{process && <div className="word-cloud-header__process">{process}</div>}
				</div>
				<div className="word-cloud-content">
					<div ref={refNodeCloudScreen} className="wordcloud">
						<div className="wordcloud-screen">
							<div ref={refNodeCloud} className="wordcloud-screen-svg" />
							{process === PROCESS.FINISH && (
								<div className="wordcloud-screen__success">Update completed</div>
							)}
						</div>
					</div>
					{process === PROCESS.FINISH && (
						<div className="wordcloud-summary">
							<div className="wordcloud-summary__caption">Summary</div>
							<div className="wordcloud-summary__item">Total: {totalCount}</div>
							<div className="wordcloud-summary__item">Success: {successCount}</div>
							<div className="wordcloud-summary__item">No data: {noDataCount}</div>
							<div className="wordcloud-summary__item">
								Error screen: {errorCreateScreenCount}
							</div>
							<div className="wordcloud-summary__item">Not saved: {errorNotSavedCount}</div>
						</div>
					)}
				</div>
				{isLoading === true && <Preloader isCurtain={true} />}
			</Card>
		</div>
	);
}
