import React, { Fragment } from 'react';
import { SortDirection } from 'react-virtualized';

import { ReactComponent as IconSortDesc } from '../../icons/sort-desc.svg';
import { ReactComponent as IconSortAsc } from '../../icons/sort-asc.svg';

const HeaderColumn = (columnName = null, { dataKey, sortBy, sortDirection }) => {
	return (
		<Fragment>
			{sortBy === dataKey && sortDirection === SortDirection.DESC && (
				<IconSortDesc height="10" width="15" />
			)}
			{sortBy === dataKey && sortDirection === SortDirection.ASC && (
				<IconSortAsc height="10" width="15" />
			)}
			{columnName}
		</Fragment>
	);
};

export default HeaderColumn;
