import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';

export const PLACEMENT = {
	BOTTOM_LEFT: 'bottom-left',
	BOTTOM_CENTER: 'bottom-center',
	TOP_LEFT: 'top-left',
	TOP_CENTER: 'top-center',
};

const TooltipPopover = ({ className = '', children, nodeTarget, placement }) => {
	const nodePortal = document.createElement('div');
	const windowHeight = window.innerHeight;

	const nodeTooltip = useRef(null);
	const [placing, setPlacing] = useState(placement);

	useEffect(() => {
		document.body.appendChild(nodePortal);

		return () => {
			document.body.removeChild(nodePortal);
		};
	}, [nodePortal]);

	useEffect(() => {
		const { height: heightTooltip, width: widthTooltip } = nodeTooltip.current.getBoundingClientRect();

		const { height, left, top, width } = nodeTarget.getBoundingClientRect();

		switch (placing) {
			case PLACEMENT.BOTTOM_LEFT:
				nodeTooltip.current.style.top = `${top + height}px`;
				nodeTooltip.current.style.left = `${left}px`;
				break;
			case PLACEMENT.BOTTOM_CENTER:
				nodeTooltip.current.style.top = `${top + height}px`;
				nodeTooltip.current.style.left = `${left + width / 2 - widthTooltip / 2}px`;

				break;
			case PLACEMENT.TOP_LEFT:
				nodeTooltip.current.style.top = `${top - heightTooltip}px`;
				nodeTooltip.current.style.left = `${left}px`;

				break;
			case PLACEMENT.TOP_CENTER:
				nodeTooltip.current.style.top = `${top - heightTooltip}px`;
				nodeTooltip.current.style.left = `${left + width / 2 - widthTooltip / 2}px`;

				break;
			default:
		}

		if (nodeTooltip.current.style.top + heightTooltip >= windowHeight) {
			setPlacing(PLACEMENT.TOP_LEFT);
		}

		nodeTooltip.current.classList.add(`tooltip-${placing}`);
		nodeTooltip.current.classList.add(`tooltip--show`);
	}, [placing, windowHeight, nodeTarget, nodeTooltip]);

	return ReactDOM.createPortal(
		<div ref={nodeTooltip} className={classNames('tooltip', className)}>
			<div className="tooltip-arrow" />
			<div className="tooltip-content">{children}</div>
		</div>,
		nodePortal
	);
};

export default TooltipPopover;
