import React, { useState, useEffect, Fragment } from 'react';
import classNames from 'classnames';

import Preloader from '../../components/Preloader';
import Card from '../../components/Card';
import Button from '../../components/Button';
import InputText from '../../components/InputText';
import DropdownPrimary from '../../components/DropdownPrimary';
import ButtonSwitch from '../../components/ButtonSwitch';
import ImageSelector from '../../components/ImageSelector';
import Notify from '../../utils/notify';

import MenuQuestionsService from '../../services/menu-questions-service';

import { ReactComponent as IconCrayon } from '../../icons/crayon.svg';
import { ReactComponent as IconCheck } from '../../icons/check.svg';
import { ReactComponent as IconRemove } from '../../icons/remove.svg';

import DownloadFileService from '../../services/download-file-service';

const TYPE_QUESTION = {
	NOT_SPECIFIED: 1,
	DEFAULT: 2,
	SPECIAL: 3,
};

const FIT_IMAGE_SETTINGS = {
	WIDTH: 260,
	HEIGHT: 180,
};

const COUNT_IMAGE_MAX_PER_CHANN = 8;

const QUESTION_ID_QX16 = 1;
const QUESTION_ID_QX7 = 2;

const FILTER_SELECTED_ID = 1;
const FILTER_EXCLUDED_ID = 2;

const initialForm = {
	groupName: '',
	itemName: '',
	itemDisplayName: '',
	itemGroupId: null,
	itemImage: null,
};

const notifyFactory = new Notify();

const initialEditedAnswers = [];

export default function MenuQuestions() {
	const [isRefreshGroups, setIsRefreshGroups] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadUpdate, setIsLoadUpdate] = useState(false);
	const [isLoadExport, setIsLoadExport] = useState(false);

	const [countItemsWithImage, setCountItemsWithImage] = useState(null);

	const [questionId, setQuestionId] = useState(QUESTION_ID_QX16);
	const [chainId, setChainId] = useState(null);
	const [displayFilterId, setDisplayFilterId] = useState(null);

	const [chains, setChains] = useState(null);
	const [answersGroups, setAnswersGroups] = useState(null);
	const [editListAnswers, setEditListAnswers] = useState(initialEditedAnswers);

	const [editAnswer, setEditAnswer] = useState(null);
	const [editGroup, setEditGroup] = useState(null);

	const [form, setForm] = useState(initialForm);

	useEffect(() => {
		if (isRefreshGroups === true) {
			setIsLoading(true);
			setIsRefreshGroups(false);
			setEditListAnswers(initialEditedAnswers);

			const getQuestionsData =
				questionId === QUESTION_ID_QX16
					? MenuQuestionsService.getQX16Data
					: MenuQuestionsService.getQX7Data;

			getQuestionsData().then(({ data }) => {
				if (data) {
					const { chains = [], answersData = [] } = data;

					const [firstChain = null] = chains;
					if (firstChain) setChainId(firstChain.id);

					setChains(chains);
					setAnswersGroups(answersData);
					setIsLoading(false);
				}
			});
		}
	}, [isRefreshGroups, questionId]);

	useEffect(() => {
		if (chainId)
			MenuQuestionsService.getItemsWithImageCount({
				chainId,
			}).then(({ data }) => {
				setCountItemsWithImage(data);
			});
	}, [isRefreshGroups, chainId]);

	const handleUpdateClick = () => {
		setIsLoadUpdate(true);

		MenuQuestionsService.clearQuestionsCache().then(() => {
			setIsLoadUpdate(false);
			notifyFactory.add(`Update successful`);
		});
	};

	const handleExportClick = () => {
		setIsLoadExport(true);

		DownloadFileService.get('DataRequests/GetMenuQuestionsReport').then(() => {
			setIsLoadExport(false);
		});
	};

	const handlePropToAnswerChainChange = ({ groupId, answerId, ...prop }) => {
		setAnswersGroups((groups) => {
			return groups.map((group) => {
				if (group.id === groupId) {
					const answers = group.answers.map((answer) => {
						if (answer.id === answerId) {
							const chains = answer.chains.map((chain) => {
								if (chain.ffChainId === chainId)
									return {
										...chain,
										...prop,
									};
								return chain;
							});

							return {
								...answer,
								chains,
							};
						}

						return answer;
					});

					return {
						...group,
						answers,
					};
				}

				return group;
			});
		});

		setEditListAnswers((answerIds) => [
			...answerIds,
			{
				answerId,
				chainId,
			},
		]);
	};

	const handlePropToAnswerChange = ({ groupId, answerId, ...prop }) => {
		setAnswersGroups((groups) => {
			return groups.map((group) => {
				if (group.id === groupId) {
					const answers = group.answers.map((answer) => {
						if (answer.id === answerId)
							return {
								...answer,
								...prop,
							};

						return answer;
					});

					return {
						...group,
						answers,
					};
				}

				return group;
			});
		});

		setEditListAnswers((answerIds) => [
			...answerIds,
			{
				answerId,
				chainId,
			},
		]);
	};

	const handleSaveAnswerChainClick =
		({ groupId, answerId, answerChain }) =>
		() => {
			if (answerChain.type === TYPE_QUESTION.SPECIAL && answerChain.customText?.length === 0) {
				notifyFactory.add(`Chain specific term field is empty`);
				return;
			}

			setIsLoading(true);

			MenuQuestionsService.updateChainConnection({
				id: answerChain.id,
				answerId: answerId,
				chainId: chainId,
				type: answerChain.type,
				customText: answerChain.customText,
				display: answerChain.display,
				image: answerChain.image ? answerChain.image : null,
			}).then(() => {
				setIsRefreshGroups(true);
			});
		};

	const handleSaveAnswerClick =
		({ groupId, answer }) =>
		() => {
			if (answer.text?.length === 0) {
				notifyFactory.add(`Name field is empty`);
				return;
			}

			setIsLoading(true);

			MenuQuestionsService.editX7Answer({
				id: answer.id,
				name: answer.text,
				display: answer.display,
				image: answer.image,
				isDisabled: answer.isDisabled,
			}).then(() => {
				setIsRefreshGroups(true);
			});
		};

	const handleAnswerTypeChange =
		({ groupId, answerId, answerChain }) =>
		({ target }) => {
			setIsLoading(true);

			MenuQuestionsService.updateChainConnection({
				id: answerChain ? answerChain.id : 0,
				answerId: answerId,
				chainId: chainId,
				type: target.value,
				customText: answerChain ? answerChain.customText : '',
				display: answerChain ? answerChain.display : '',
				image: answerChain ? answerChain.image : null,
			}).then(() => {
				setIsRefreshGroups(true);
			});
		};

	const handleSaveAnswerFieldClick = () => {
		if (editAnswer.text.length === 0 || editAnswer.display.length === 0) {
			notifyFactory.add(`Field is empty`);
			return;
		}

		setIsLoading(true);
		setEditAnswer(null);

		MenuQuestionsService.editX16Answer({
			id: editAnswer.id,
			name: editAnswer.text,
			display: editAnswer.display,
		}).then(() => {
			setIsRefreshGroups(true);
		});
	};

	const handleSaveGroupFieldClick = () => {
		if (editGroup.text?.length === 0) {
			notifyFactory.add(`Name field is empty`);
			return;
		}

		setIsLoading(true);
		setEditGroup(null);

		MenuQuestionsService.editX7Group({
			id: editGroup.id,
			name: editGroup.text,
		}).then(() => {
			setIsRefreshGroups(true);
		});
	};

	const handleOpenAnswerClick = (answer) => () => setEditAnswer(answer);

	const handleQuestionChange = ({ id }) => {
		setQuestionId(id);
		setAnswersGroups(null);
		setDisplayFilterId(null);
		setForm(initialForm);
		setIsRefreshGroups(true);
		setEditListAnswers(initialEditedAnswers);
	};

	const handleDisplayFilterChange = ({ id }) => setDisplayFilterId(id);

	const handleChainChange = ([chainId]) => setChainId(chainId);

	const handleSaveFormGroupClick = () => {
		if (form.groupName.length === 0) {
			notifyFactory.add(`Group name field is empty`);
			return;
		}

		const isPresentInGroup = answersGroups.some(
			(group) =>
				group.text &&
				group.ffChainId === chainId &&
				group.text?.toLowerCase() === form.groupName.toLowerCase()
		);

		if (isPresentInGroup) {
			notifyFactory.add(`Group already exists`);
			return;
		}

		setIsLoading(true);

		MenuQuestionsService.addX7Group({
			name: form.groupName,
			chainId: chainId,
		}).then(() => {
			setForm((form) => ({
				...form,
				groupName: '',
			}));

			setIsRefreshGroups(true);
		});
	};

	const handleSaveFormItemClick = () => {
		if (form.itemName.length === 0) {
			notifyFactory.add(`Name field is empty`);
			return;
		}

		if (form.itemGroupId === null) {
			notifyFactory.add(`Category is not selected`);
			return;
		}

		const currentGroup = answersGroups.find((group) => group.id === form.itemGroupId) || null;

		if (currentGroup) {
			const isSimilarNameInGroup = currentGroup.answers.some(
				({ text }) => text?.toLowerCase() === form.itemName.toLowerCase()
			);
			if (isSimilarNameInGroup === true) {
				notifyFactory.add(`Item within ${currentGroup.text} already exists`);
				return;
			}
		}

		setIsLoading(true);
		setForm(initialForm);

		const addQuestionData =
			questionId === QUESTION_ID_QX16
				? MenuQuestionsService.addX16Answer
				: MenuQuestionsService.addX7Answer;

		addQuestionData({
			groupId: form.itemGroupId,
			name: form.itemName,
			display: form.itemDisplayName,
			image: form.itemImage ? form.itemImage : null,
		}).then(() => {
			setIsRefreshGroups(true);
		});
	};

	return (
		<div className="wrapper menu-questions">
			<Card className="menu-questions-card">
				<div className="menu-questions-card-caption">Menu questions</div>
				<div className="menu-questions-header">
					<div className="menu-questions-header__column">
						<ButtonSwitch
							options={[
								{
									name: 'QX1-6',
									id: QUESTION_ID_QX16,
								},
								{
									name: 'QX7',
									id: QUESTION_ID_QX7,
								},
							]}
							selectedOptionId={questionId}
							onChange={handleQuestionChange}
							name="questions"
						/>
						<Button
							isPrimary
							className="button-update"
							onClick={handleUpdateClick}
							isLoading={isLoadUpdate}
						>
							Update
						</Button>
						<Button
							isSecondary
							className="button-export"
							onClick={handleExportClick}
							isLoading={isLoadExport}
						>
							Export
						</Button>
					</div>
					<div className="menu-questions-header__column">
						{questionId === QUESTION_ID_QX16 && (
							<div className="buttons-witch-filter">
								<div className="buttons-witch-filter__caption">Display:</div>
								<ButtonSwitch
									options={[
										{
											name: 'All',
											id: null,
										},
										{
											name: 'SELECTED',
											id: FILTER_SELECTED_ID,
										},
										{
											name: 'EXCLUDED',
											id: FILTER_EXCLUDED_ID,
										},
									]}
									selectedOptionId={displayFilterId}
									onChange={handleDisplayFilterChange}
									name="filter"
								/>
							</div>
						)}
						<DropdownPrimary
							className="dropdown-chains"
							placeholder="All chains"
							toggleLabel={chains ? chains.find(({ id }) => id === chainId)?.name : null}
							isMultiselect={false}
							isSearchField={true}
							searchPlaceholder="Search chain"
							optionList={chains ? chains : []}
							selectedList={chainId ? [chainId] : []}
							onChange={handleChainChange}
						/>
					</div>
				</div>
				{answersGroups === null ? (
					<div className="menu-questions-content">
						<Preloader />
					</div>
				) : (
					<div className="menu-questions-content">
						{questionId === QUESTION_ID_QX16 && (
							<table className="table-menu-questions">
								<thead>
									<tr className="table-head">
										<th className="thead-cell">Survey Terminology</th>
										<th className="thead-cell">Survey term – Live Site</th>
										<th className="thead-cell" width="70">
											Type
										</th>
										<th className="thead-cell" width="15%">
											Chain Specific Term - Survey
										</th>
										<th className="thead-cell" width="15%">
											Chain Specific – Live Site
										</th>
										<th className="thead-cell" width="80">
											Image
										</th>
										<th className="thead-cell" width="60">
											Analytics
										</th>
										<th className="thead-cell" width="60">
											inChainUse
										</th>
										<th className="thead-cell" width="50"></th>
									</tr>
								</thead>
								<tbody>
									{answersGroups?.map((group) => {
										return (
											<Fragment key={group.id}>
												<tr>
													<th
														className="tbody-cell tbody-cell-category"
														colSpan="9"
													>
														{group.text}
													</th>
												</tr>

												{group.answers
													.filter((answer) => {
														if (displayFilterId === null) return true;

														const answerChain =
															answer.chains?.find(
																(chain) => chain.ffChainId === chainId
															) || null;

														if (displayFilterId === FILTER_SELECTED_ID)
															return answerChain === null ||
																answerChain?.type === TYPE_QUESTION.NOT_SPECIFIED
																? false
																: true;

														if (displayFilterId === FILTER_EXCLUDED_ID)
															return answerChain?.type !== TYPE_QUESTION.NOT_SPECIFIED
																? false
																: true;

														return false;
													})
													.map((answer) => {
														const answerChain =
															answer.chains?.find(
																(chain) => chain.ffChainId === chainId
															) || null;

														return (
															<tr
																key={answer.id}
																className={classNames('tbody-row', {
																	'tbody-row--default':
																		answerChain?.type ===
																		TYPE_QUESTION.DEFAULT,
																	'tbody-row--special':
																		answerChain?.type ===
																		TYPE_QUESTION.SPECIAL,
																	'tbody-row--empty-custom-text':
																		answerChain?.customText?.length === 0,
																})}
															>
																<td className="tbody-cell">
																	<div className="word-editor">
																		<div className="word-editor__text">
																			{answer.text}
																			{editAnswer?.id === answer.id &&
																				editAnswer.isEditText ===
																					true && (
																					<input
																						className="word-editor__input"
																						type="text"
																						value={
																							editAnswer.text
																						}
																						onChange={({
																							target,
																						}) => {
																							setEditAnswer(
																								(answer) => {
																									return {
																										...answer,
																										text: target.value,
																									};
																								}
																							);
																						}}
																					/>
																				)}
																		</div>
																		{editAnswer?.id === answer.id &&
																		editAnswer.isEditText === true ? (
																			<button
																				className="word-editor__button-check"
																				onClick={
																					handleSaveAnswerFieldClick
																				}
																			>
																				<IconCheck />
																			</button>
																		) : (
																			<button
																				className="word-editor__button-edit"
																				onClick={handleOpenAnswerClick(
																					{
																						...answer,
																						isEditText: true,
																						isEditDisplay: false,
																					}
																				)}
																			>
																				<IconCrayon />
																			</button>
																		)}
																	</div>
																</td>
																<td className="tbody-cell">
																	<div className="word-editor">
																		<div className="word-editor__text">
																			{answer.display}
																			{editAnswer?.id === answer.id &&
																				editAnswer.isEditDisplay ===
																					true && (
																					<input
																						className="word-editor__input"
																						type="text"
																						value={
																							editAnswer.display
																						}
																						onChange={({
																							target,
																						}) => {
																							setEditAnswer(
																								(answer) => {
																									return {
																										...answer,
																										display:
																											target.value,
																									};
																								}
																							);
																						}}
																					/>
																				)}
																		</div>
																		{editAnswer?.id === answer.id &&
																		editAnswer.isEditDisplay === true ? (
																			<button
																				className="word-editor__button-check"
																				onClick={
																					handleSaveAnswerFieldClick
																				}
																			>
																				<IconCheck />
																			</button>
																		) : (
																			<button
																				className="word-editor__button-edit"
																				onClick={handleOpenAnswerClick(
																					{
																						...answer,
																						isEditText: false,
																						isEditDisplay: true,
																					}
																				)}
																			>
																				<IconCrayon />
																			</button>
																		)}
																	</div>
																</td>
																<td className="tbody-cell tbody-cell--type">
																	<select
																		value={answerChain?.type ?? ''}
																		className="select-type"
																		onChange={handleAnswerTypeChange({
																			groupId: group.id,
																			answerId: answer.id,
																			answerChain,
																		})}
																	>
																		<option value={TYPE_QUESTION.NOT_SPECIFIED}/>
																		<option value={TYPE_QUESTION.DEFAULT}>
																			default
																		</option>
																		<option value={TYPE_QUESTION.SPECIAL}>
																			special
																		</option>
																	</select>
																</td>
																<td className="tbody-cell">
																	{answerChain?.type ===
																		TYPE_QUESTION.SPECIAL && (
																		<input
																			type="text"
																			className="text-input"
																			value={
																				answerChain?.customText ?? ''
																			}
																			onChange={({ target }) =>
																				handlePropToAnswerChainChange(
																					{
																						groupId: group.id,
																						answerId: answer.id,
																						customText:
																							target.value,
																					}
																				)
																			}
																		/>
																	)}
																</td>
																<td className="tbody-cell">
																	{!!answerChain && answerChain.type !== TYPE_QUESTION.NOT_SPECIFIED && (
																		<input
																			type="text"
																			className="text-input"
																			value={answerChain?.display ?? ''}
																			onChange={({ target }) =>
																				handlePropToAnswerChainChange(
																					{
																						groupId: group.id,
																						answerId: answer.id,
																						display: target.value,
																					}
																				)
																			}
																		/>
																	)}
																</td>
																<td className="tbody-cell">
																	{!!answerChain && answerChain.type !== TYPE_QUESTION.NOT_SPECIFIED && (
																		<ImageSelector
																			fitWidth={
																				FIT_IMAGE_SETTINGS.WIDTH
																			}
																			fitHeight={
																				FIT_IMAGE_SETTINGS.HEIGHT
																			}
																			src={answerChain?.image}
																			onChange={(source) =>
																				handlePropToAnswerChainChange(
																					{
																						groupId: group.id,
																						answerId: answer.id,
																						image: source,
																					}
																				)
																			}
																			onAccept={(event) => {
																				if (
																					countItemsWithImage >=
																					COUNT_IMAGE_MAX_PER_CHANN
																				) {
																					notifyFactory.add(
																						`Up to 8 images per chain`
																					);
																					return false;
																				}
																				return event;
																			}}
																		/>
																	)}
																</td>
																<td className="tbody-cell">
																	<IconCheck
																		width={14}
																		height={14}
																		fill={
																			answer.inUse === true
																				? '#1CBB5B'
																				: '#E4E4E4'
																		}
																	/>
																</td>
																<td className="tbody-cell">
																	<IconCheck
																		width={14}
																		height={14}
																		fill={
																			answerChain?.inChainUse === true
																				? '#1CBB5B'
																				: '#E4E4E4'
																		}
																	/>
																</td>
																<td className="tbody-cell">
																	<Button
																		isSecondary
																		className="button-save"
																		onClick={handleSaveAnswerChainClick({
																			groupId: group.id,
																			answerId: answer.id,
																			answerChain,
																		})}
																		disabled={!editListAnswers.some(
																			(option) => option.answerId === answer.id && option.chainId === chainId
																		)}
																	>
																		Save
																	</Button>
																</td>
															</tr>
														);
													})}
											</Fragment>
										);
									})}
								</tbody>
							</table>
						)}

						{questionId === QUESTION_ID_QX7 && (
							<table className="table-menu-questions table-menu-questions--secondary">
								<thead>
									<tr className="table-head">
										<th className="thead-cell">Survey Terminology</th>
										<th className="thead-cell">Chain Specific – Live Site</th>
										<th className="thead-cell thead-cell--center" width="80">
											Image
										</th>
										<th className="thead-cell thead-cell--center" width="60">
											Analytics
										</th>
										<th className="thead-cell thead-cell--center" width="60">
											No longer menued
										</th>
										<th className="thead-cell thead-cell--center" width="50"></th>
									</tr>
								</thead>
								<tbody>
									{answersGroups
										?.filter((group) => {
											return group.ffChainId === chainId ? true : false;
										})
										.map((group) => {
											return (
												<Fragment key={group.id}>
													<tr>
														<th
															className="tbody-cell tbody-cell-category"
															colSpan="6"
														>
															<div className="word-editor">
																<div className="word-editor__text">
																	{group.text}
																	{editGroup?.id === group.id && (
																		<input
																			className="word-editor__input"
																			type="text"
																			value={editGroup.text}
																			onChange={({ target }) => {
																				setEditGroup((group) => {
																					return {
																						...group,
																						text: target.value,
																					};
																				});
																			}}
																		/>
																	)}
																</div>
																{editGroup?.id === group.id ? (
																	<button
																		className="word-editor__button-check"
																		onClick={handleSaveGroupFieldClick}
																	>
																		<IconCheck />
																	</button>
																) : (
																	<button
																		className="word-editor__button-edit"
																		onClick={() => setEditGroup(group)}
																	>
																		<IconCrayon />
																	</button>
																)}
															</div>
														</th>
													</tr>
													{group.answers.map((answer) => {
														return (
															<tr key={answer.id} className="tbody-row">
																<td className="tbody-cell">
																	<input
																		type="text"
																		className="text-input"
																		value={answer.text ?? ''}
																		onChange={({ target }) =>
																			handlePropToAnswerChange({
																				groupId: group.id,
																				answerId: answer.id,
																				text: target.value,
																			})
																		}
																	/>
																</td>
																<td className="tbody-cell">
																	<input
																		type="text"
																		className="text-input"
																		value={answer.display ?? ''}
																		onChange={({ target }) =>
																			handlePropToAnswerChange({
																				groupId: group.id,
																				answerId: answer.id,
																				display: target.value,
																			})
																		}
																	/>
																</td>
																<td className="tbody-cell tbody-cell--center">
																	<ImageSelector
																		fitWidth={FIT_IMAGE_SETTINGS.WIDTH}
																		fitHeight={FIT_IMAGE_SETTINGS.HEIGHT}
																		src={answer?.image}
																		onChange={(source) =>
																			handlePropToAnswerChange({
																				groupId: group.id,
																				answerId: answer.id,
																				image: source,
																			})
																		}
																		onAccept={(event) => {
																			if (
																				countItemsWithImage >=
																				COUNT_IMAGE_MAX_PER_CHANN
																			) {
																				notifyFactory.add(
																					`Up to 8 images per chain`
																				);
																				return false;
																			}
																			return event;
																		}}
																	/>
																</td>
																<td className="tbody-cell tbody-cell--center">
																	<IconCheck
																		width={14}
																		height={14}
																		fill={
																			answer.inUse === true
																				? '#1CBB5B'
																				: '#E4E4E4'
																		}
																	/>
																</td>
																<td className="tbody-cell tbody-cell--center">
																	<button
																		className="button-switch"
																		onClick={() =>
																			handlePropToAnswerChange({
																				groupId: group.id,
																				answerId: answer.id,
																				isDisabled:
																					!answer.isDisabled,
																			})
																		}
																	>
																		{answer.isDisabled === false ? (
																			<IconCheck
																				width={14}
																				height={14}
																				fill="#1CBB5B"
																			/>
																		) : (
																			<IconRemove
																				width={14}
																				height={14}
																				fill="#ff2525"
																			/>
																		)}
																	</button>
																</td>
																<td className="tbody-cell tbody-cell--center">
																	<Button
																		isSecondary
																		className="button-save"
																		onClick={handleSaveAnswerClick({
																			groupId: group.id,
																			answer,
																		})}
																		disabled={
																			editListAnswers.some(
																				(option) =>
																					option.answerId ===
																						answer.id &&
																					option.chainId === chainId
																			)
																				? false
																				: true
																		}
																	>
																		Save
																	</Button>
																</td>
															</tr>
														);
													})}
												</Fragment>
											);
										})}
								</tbody>
							</table>
						)}
						<div className="menu-questions-form">
							{questionId === QUESTION_ID_QX7 && (
								<div className="menu-questions-form-fieldset">
									<div className="menu-questions-form-fieldset__caption">Group:</div>
									<div className="menu-questions-form-fieldset-column">
										<InputText
											placeholder="Group name"
											value={form.groupName}
											onChange={({ target }) => {
												setForm((form) => ({
													...form,
													groupName: target.value,
												}));
											}}
										/>
									</div>
									<div className="menu-questions-form-fieldset-column">
										<Button
											isPrimary
											className="button-save"
											onClick={handleSaveFormGroupClick}
										>
											Add
										</Button>
									</div>
								</div>
							)}

							<div className="menu-questions-form-fieldset">
								<div className="menu-questions-form-fieldset__caption">Item:</div>
								<div className="menu-questions-form-fieldset-column">
									<InputText
										placeholder="Item name"
										value={form.itemName}
										onChange={({ target }) => {
											setForm((form) => ({
												...form,
												itemName: target.value,
											}));
										}}
									/>
								</div>
								{questionId === QUESTION_ID_QX7 && (
									<div className="menu-questions-form-fieldset-column">
										<InputText
											placeholder="Item display name"
											value={form.itemDisplayName}
											onChange={({ target }) => {
												setForm((form) => ({
													...form,
													itemDisplayName: target.value,
												}));
											}}
										/>
									</div>
								)}
								<div className="menu-questions-form-fieldset-column">
									<DropdownPrimary
										className="dropdown-groups"
										placeholder="Select a group"
										toggleLabel={
											answersGroups
												? answersGroups?.find(({ id }) => id === form.itemGroupId)
														?.text
												: null
										}
										isMultiselect={false}
										isSearchField={false}
										searchPlaceholder=""
										optionList={answersGroups?.map(({ id, text }) => {
											return {
												id,
												name: text,
											};
										})}
										selectedList={[form.itemGroupId]}
										onChange={([itemGroupId]) => {
											setForm((form) => ({
												...form,
												itemGroupId,
											}));
										}}
									/>
								</div>
								{questionId === QUESTION_ID_QX7 && (
									<div className="menu-questions-form-fieldset-column">
										<ImageSelector
											fitWidth={FIT_IMAGE_SETTINGS.WIDTH}
											fitHeight={FIT_IMAGE_SETTINGS.HEIGHT}
											src={form.itemImage}
											onChange={(source) => {
												setForm((form) => ({
													...form,
													itemImage: source,
												}));
											}}
										/>
									</div>
								)}
								<div className="menu-questions-form-fieldset-column">
									<Button
										isPrimary
										className="button-save"
										onClick={handleSaveFormItemClick}
									>
										Add
									</Button>
								</div>
							</div>
						</div>
					</div>
				)}

				{isLoading === true && <Preloader isCurtain={true} />}
			</Card>
		</div>
	);
}
