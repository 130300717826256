import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';

import Portal from '../../components/Portal';

export default function Modal({
	className = null,
	onClose = () => {},
	isCloseOnEscapeKeyDown = false,
	children = null,
}) {
	const refNode = useRef();

	useEffect(() => {
		const handleWindowClick = (event) => {
			event.stopPropagation();
			if (typeof onClose === 'function' && event.target === refNode.current) onClose(event);
		};

		const hendleKeydown = (event) => {
			if (typeof onClose === 'function' && event.code === 'Escape') onClose(event);
		};

		window.addEventListener('click', handleWindowClick);

		if (isCloseOnEscapeKeyDown === true) document.addEventListener('keydown', hendleKeydown);

		return () => {
			window.removeEventListener('click', handleWindowClick);

			if (isCloseOnEscapeKeyDown === true) document.removeEventListener('keydown', hendleKeydown);
		};
	}, [refNode, isCloseOnEscapeKeyDown, onClose]);

	return (
		<Portal>
			<div className={classNames('modal', className)} ref={refNode}>
				<div className="modal-window">
					<button onClick={onClose} className="modal-window__close">
						×
					</button>
					<div className="modal-body">{children}</div>
				</div>
			</div>
		</Portal>
	);
}
