import axios from 'axios';

class TOMService {

	getInitialData(params) {
		return axios.get('TopOfMind/GetInitialData',{
			params: {
				...params,
			},
		});
	}

	getKeywordResponses(params) {
		return axios.get('TopOfMind/GetKeywordResponses', {
			params: {
				...params,
			},
		});
	}

	getKeywordExtensions(params) {
		return axios.get('TopOfMind/GetKeywordExtensions', {
			params: {
				...params,
			},
		});
	}

	getTmiResponses(params) {
		return axios.get('TopOfMind/GetTmiResponses', {
			params: {
				...params,
			},
		});
	}

	renameKeyword(params) {
		return axios.post('TopOfMind/renameKeyword', params);
	}

	renameResponse(params) {
		return axios.post('TopOfMind/renameResponse', params);
	}

	assignToChains(params) {
		return axios.post('TopOfMind/AssignToChains', params);
	}

	createGlobalExtension(params) {
		return axios.post('TopOfMind/createGlobalExtension', params);
	}

	createChainExtension(params) {
		return axios.post('TopOfMind/createChainExtension', params);
	}

	deleteExtensionByText(params) {
		return axios.post('TopOfMind/DeleteExtensionByText', params);
	}

	deleteExtensionById(params) {
		return axios.post('TopOfMind/DeleteExtensionById', params);
	}	

	deleteKeyword(params) {
		return axios.post('TopOfMind/DeleteKeyword', params);
	}	

	unassignKeyword(params) {
		return axios.post('TopOfMind/UnassignKeyword', params);
	}

	createKeyword(params) {
		return axios.post('TopOfMind/CreateKeyword', params);
	}

	assignWordToChain(params) {
		return axios.post('TopOfMind/AssignWordToChain', params);
	}

	assignExtensionToChains(params) {
		return axios.post('TopOfMind/assignExtensionToChains', params);
	}
}

export default new TOMService();
