import React, { useState, useRef } from 'react';
import classNames from 'classnames';

import { Dropdown, DropdownToggle, DropdownMenu } from '../../../components/Dropdown';

import { ReactComponent as IconList } from '../../../icons/list.svg';

export default function DropdownTooltipParents({
	className = null,

	rowParents = [],
	wordResponses = [],
	onWordClick = () => {},
	nodeOffsetPosition = null,
	isOpened = false,
}) {
	const refDropdownComponent = useRef(null);
	const refNodeContainer = useRef(null);

	const [parentWords, setParentWords] = useState(null);

	const handleWordClick = (parentWord) => () => {
		refDropdownComponent.current.closeDropdown();
		onWordClick(parentWord);
	};

	const handleToggle = (isOpen) => {
		if (isOpen === true && parentWords === null) {
			const parents = rowParents.map((parent) => {
				const parentWord = wordResponses.find((word) => word.k === parent.i);

				return {
					...parent,
					t: parentWord ? parentWord.t : null,
				};
			});

			setParentWords(parents);
		} else {
			setParentWords(null);
		}

		if (isOpen === true && nodeOffsetPosition) {
			const containerClientRect = refNodeContainer.current.getBoundingClientRect();
			const offsetPositionRect = nodeOffsetPosition.getBoundingClientRect();
			const nodeOffsetXCenter = offsetPositionRect.height / 2;
			const containerClientTopOfNodeOffset = containerClientRect.top - offsetPositionRect.top;
			const isReverse = containerClientTopOfNodeOffset > nodeOffsetXCenter;
			refNodeContainer.current.classList.toggle('dropdown-tooltip-chains--reverse', isReverse);
		}
	};

	return (
		<div className="dropdown-tooltip-parents" ref={refNodeContainer}>
			<Dropdown
				className={classNames('dropdown-tooltip', className)}
				isOpened={isOpened}
				onToggle={handleToggle}
				ref={refDropdownComponent}
			>
				<DropdownToggle className="dropdown-tooltip-toggle">
					<IconList className="dropdown-tooltip-toggle__icon" height={14} width={14} />
				</DropdownToggle>
				<DropdownMenu className="dropdown-tooltip-menu">
					<div className="arrow-icon" />
					{parentWords && parentWords.length > 0 ? (
						<div className="dropdown-tooltip-words">
							{parentWords.map((parentWord, index) => {
								return (
									<div
										className={classNames('dropdown-tooltip-words-option', {
											'dropdown-tooltip-words-option--global': parentWord.s === true,
										})}
										onClick={handleWordClick(parentWord)}
										key={`${index}-${parentWord.id}`}
									>
										{parentWord.t}
									</div>
								);
							})}
						</div>
					) : (
						<div className="dropdown-tooltip-menu__message">Nothing found...</div>
					)}
				</DropdownMenu>
			</Dropdown>
		</div>
	);
}
