import React, { useState, useRef } from 'react';
import classNames from 'classnames';

import InputSearch from '../../../components/InputSearch';
import Button from '../../../components/Button';

import { Dropdown, DropdownToggle, DropdownMenu } from '../../../components/Dropdown';


export default function DropdownTooltipChains({
	className = null,
	useChains = [],
	captionToggle = null,
	nodeOffsetPosition = null,
	searchPlaceholder = 'Search chain...',
	isOpened = false,
	isEditable = false,
	onToggle = () => {},
	onChange = () => {},
	onApply = () => {},
}) {
	const refDropdownComponent = useRef(null);
	const refNodeContainer = useRef(null);
	const [searchText, setSearchText] = useState('');
 

	const handleChainClick = (chain) => () => {
		const useChainList = useChains.map((useChain) => {
			if (chain.i === useChain.i)
				return {
					...useChain,
					u: !useChain.u,
				};
			return useChain;
		});

		onChange(useChainList);
	};

	const handleChainBulk = (state) => () => {
		const useChainList = useChains.map((useChain) => {
			return {
				...useChain,
				u: state,
			};
		});

		onChange(useChainList);
	};

	const handleSaveClick = () => {
		refDropdownComponent.current.closeDropdown();
		if (Array.isArray(useChains)) {
			onApply(useChains.map(({ i, u }) => ({ i, u })));
		}
	};

	const handleToggle = (isOpen) => {
		if (isOpen === false) 
			setSearchText('');

		if (isOpen === true && nodeOffsetPosition) {
			const containerClientRect = refNodeContainer.current.getBoundingClientRect();
			const offsetPositionRect = nodeOffsetPosition.getBoundingClientRect();
			const nodeOffsetXCenter = offsetPositionRect.height / 2;
			const containerClientTopOfNodeOffset = containerClientRect.top - offsetPositionRect.top;
			const isReverse = containerClientTopOfNodeOffset > nodeOffsetXCenter;
			refNodeContainer.current.classList.toggle('dropdown-tooltip-chains--reverse', isReverse);
		}

		onToggle(isOpen);
	};

	const regex = new RegExp('\\b' + searchText.toLowerCase(), 'ig');

	const useChainStateList = useChains
		? useChains.filter((chain) => {
				return chain ? chain.name.toLowerCase().search(regex) !== -1 : false;
		  })
		: [];

	const isDisabledSave = useChains && useChains.every(({ u, uDefault }) => u === uDefault);

	return (
		<div className="dropdown-tooltip-chains" ref={refNodeContainer}>
			<Dropdown
				className={classNames('dropdown-tooltip', className)}
				isOpened={isOpened}
				onToggle={handleToggle}
				ref={refDropdownComponent}
			>
				<DropdownToggle className="dropdown-tooltip-toggle">
					<div className="dropdown-tooltip-toggle__caption">{captionToggle}</div>
				</DropdownToggle>
				<DropdownMenu className="dropdown-tooltip-menu">
					<div className="arrow-icon" />
					<div className="dropdown-tooltip-menu__caption">This keyword is mapped to chains:</div>
					<div className="dropdown-tooltip-menu__search">
						<InputSearch
							value={searchText}
							placeholder={searchPlaceholder}
							onChange={(valueText) => {
								const valueTextClean = valueText.trimStart() || '';
								setSearchText(valueTextClean);
							}}
						/>
					</div>
					{isEditable === true && (
						<div className="dropdown-tooltip-menu-buttons">
							<div className="dropdown-tooltip-menu-buttons__column">
								<button
									className="manage-buttons button-manage"
									onClick={handleChainBulk(true)}
								>
									Select all
								</button>
								<button
									className="manage-buttons button-manage"
									onClick={handleChainBulk(false)}
								>
									Clear all
								</button>
							</div>
							<div className="dropdown-tooltip-menu-buttons__column">
								<Button
									disabled={isDisabledSave}
									className="button-save"
									onClick={handleSaveClick}
								>
									Save
								</Button>
							</div>
						</div>
					)}
					{useChainStateList.length > 0 ? (
						<div className="dropdown-tooltip-chain-list">
							{useChainStateList.map((chain, index) => {
								return (
									<div
										className={classNames('dropdown-tooltip-chain-list-option', {
											'dropdown-tooltip-chain-list-option--selected': chain.u === true,
											'dropdown-tooltip-chain-list-option--frozen':
												isEditable === false,
										})}
										onClick={handleChainClick(chain)}
										key={`${index}-${chain.id}`}
									>
										{chain.name}
									</div>
								);
							})}
						</div>
					) : (
						<div className="dropdown-tooltip-menu__message">Nothing found...</div>
					)}
				</DropdownMenu>
			</Dropdown>
		</div>
	);
}
