import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { /* defaultTableRowRenderer, */ AutoSizer, Column, Table, SortDirection } from 'react-virtualized';

import HeaderColumn from './../HeaderColumn';
import { sortRows } from './../helper';

import { ReactComponent as IconArrowDown } from '../../../icons/arrow-down.svg';

const initialTableConfig = {
	sortBy: 'c',
	sortDirection: SortDirection.DESC,
};

export default function DropupKeywordResponses({
	className = null,
	keyword = null,
	responses = [],
	isOpened = false,
}) {
	const keywordId = keyword?.k || null;

	const refTable = useRef(null);
	const [isOpen, setIsOpen] = useState(isOpened);
	const [tableConfig, setTableConfig] = useState(initialTableConfig);

	useEffect(() => {
		setIsOpen(false);
		handleSortChange({
			sortBy: 'c',
			sortDirection: SortDirection.DESC,
		});
	}, [keywordId]);

	const handleSortChange = ({ sortBy, sortDirection }) => {
		setTableConfig((prevState) => {
			return {
				...prevState,
				sortBy,
				sortDirection,
			};
		});

		if (refTable.current) refTable.current.Grid._scrollingContainer.scrollTo(0, 0);
	};

	const handleToggleClick = (event) => setIsOpen(!isOpen);

	const responsesSorted = sortRows(responses, {
		sortBy: tableConfig.sortBy,
		sortDirection: tableConfig.sortDirection,
	});

	return (
		<div
			className={classNames(
				'dropup-keyword-responses',
				{
					opened: isOpen === true,
				},
				className
			)}
		>
			<div className="dropup-keyword-responses-toggle" onClick={handleToggleClick}>
				<div className="dropup-keyword-responses-toggle__caption">
					Responses for “<span className="word">{keyword.t}</span>”
				</div>
				<IconArrowDown className="dropup-keyword-responses-toggle__icon" width="14" height="14" />
			</div>
			<div className="dropup-keyword-responses-content">
				<div className="dropup-keyword-responses-table">
					<AutoSizer>
						{({ width, height }) => (
							<Table
								className="tom-mapping-table"
								ref={refTable}
								rowClassName="row"
								gridClassName="grid"
								headerClassName="column"
								headerHeight={24}
								width={width}
								height={height}
								rowHeight={27}
								rowCount={responsesSorted.length}
								rowGetter={({ index }) => responsesSorted[index]}
								sort={handleSortChange}
								sortBy={tableConfig.sortBy}
								sortDirection={tableConfig.sortDirection}
								noRowsRenderer={() => {
									return (
										<div className="tom-mapping-card-message tom-mapping-card-message--no-records">
											No responses
										</div>
									);
								}}
								rowStyle={{ overflow: 'visible' }}
							>
								<Column
									className="cell cell-text"
									dataKey={'t'}
									width={width * 0.75}
									headerRenderer={(column) => HeaderColumn('Response', column)}
									cellRenderer={({ rowData }) => {
										return <span className="word-name">{rowData.t}</span>;
									}}
								/>
								<Column
									className="cell cell-count"
									headerClassName="column-count"
									headerRenderer={(column) => HeaderColumn('Count', column)}
									dataKey="c"
									width={60}
									defaultSortDirection={SortDirection.DESC}
									cellRenderer={({ rowData }) => {
										return rowData.c;
									}}
								/>
							</Table>
						)}
					</AutoSizer>
				</div>
			</div>
		</div>
	);
}
