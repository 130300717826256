import React, { useRef, useState } from 'react';

import Notify from '../../utils/notify';
import Modal from '../../components/Modal';

import { ReactComponent as IconCamera } from '../../icons/camera.svg';
import { ReactComponent as IconDelete } from '../../icons/delete.svg';

const notifyFactory = new Notify();

const ImageSelector = ({
	src = null,
	fitHeight = null,
	fitWidth = null,
	onChange = () => {},
	onAccept = (event) => event,
}) => {
	const refInputNode = useRef(null);
	const refImageNode = useRef(null);

	const [viewImage, setViewImage] = useState(null);

	const handleRemoveClick = (event) => {
		event.preventDefault();
		refImageNode.current.removeAttribute('src');
		onChange(null);
	};

	const handleViewImageClick = (event) => {
		event.preventDefault();
		setViewImage(event.target.src);
	};

	const handleCloseViewImageClick = () => setViewImage(null);

	const handleAcceptFile = (file) => {
		const fileReader = new FileReader();

		fileReader.onload = () => {
			refImageNode.current.onload = (event) => {
				if (
					event.target.src &&
					fitHeight &&
					fitWidth &&
					(event.target.naturalHeight !== fitHeight || event.target.naturalWidth !== fitWidth)
				) {
					notifyFactory.add(`The image doesn't fit to size: ${fitWidth} x ${fitHeight} px`);
					event.target.removeAttribute('src');
					onChange(null);
				}

				if (refImageNode.current.src) {
					onChange(refImageNode.current.src);
				} else {
					onChange(null);
				}
			};

			refImageNode.current.src = fileReader.result;
		};
		fileReader.readAsDataURL(file);
	};

	const handleInputChange = (event) => {
		event.preventDefault();
		event.stopPropagation();

		const acceptEvent = onAccept(event);
		if (acceptEvent) {
			const files = acceptEvent.target?.files;

			if (files?.length) {
				const [file] = files;
				handleAcceptFile(file);
			}
		}
	};

	return (
		<label className="image-selector">
			<input
				ref={refInputNode}
				type="file"
				className="image-selector__input"
				accept="image/x-png,image/gif,image/jpeg"
				autoComplete="off"
				onChange={handleInputChange}
			/>
			<img
				ref={refImageNode}
				src={src}
				className="image-selector__image"
				onClick={handleViewImageClick}
				alt=""
			/>
			<IconCamera height={22} width={22} className="image-selector__icon" />
			<button className="image-selector__button" onClick={handleRemoveClick}>
				<IconDelete height={16} width={16} />
			</button>
			{viewImage && (
				<Modal
					className="image-selector-modal"
					onClose={handleCloseViewImageClick}
					isCloseOnEscapeKeyDown
				>
					<img src={viewImage} className="image-selector-modal__image" alt="" />
				</Modal>
			)}
		</label>
	);
};

export default ImageSelector;
