import React, { useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import UserMenu from './../UserMenu';

import { ReactComponent as IconLogo } from '../../icons/logo.svg';

import ConfigService from '../../services/config-service';
import { HEADER_MENU } from '../../utils/constants';



const MainHeader = () => {
	const location = useLocation();
	const [config, setConfig] = useState(null);

	useEffect(() => {
		ConfigService.getConfig()
			.then(({ data }) => {
				if (data) setConfig(data);
			})
			.catch(() => {});
	}, []);

	return (
		<header className="main-header">
			<div className="container main-header-container">
				<div className="main-header-logo">
					<Link className="main-header-logo__link" to="/">
						<IconLogo />
					</Link>
				</div>
				<ul className="nav-main">
					{HEADER_MENU.map((item, indexMenuItem) => {
						return item.subMenu ? (
							<li
								className={classNames('nav-main__item', {
									'is-selected': location.pathname.includes(item.menuNamePath),
								})}
								key={indexMenuItem}
							>
								<div className="nav-main__caption">
									{location.pathname.includes(item.menuNamePath)
										? item.subMenu.find(({ path }) => location.pathname.includes(path)).name
										: item.menuName}
								</div>
								<div className="nav-icon" />
								<div className="submenu">
									<div className="submenu__wrapper">
										<div className="submenu__item nav-main-item-name">
											{item.menuName}
										</div>
										{item.subMenu.map((submenuItem, indexSubmenuItem) => {
											return (
												<NavLink
													key={`${indexMenuItem}-${indexSubmenuItem}`}
													to={submenuItem.path}
													activeClassName="is-selected"
													className="submenu__link"
												>
													{submenuItem.name}
												</NavLink>
											);
										})}
									</div>
								</div>
							</li>
						) : (
							<li className="nav-main__item" key={indexMenuItem}>
								<NavLink
									to={item.path}
									activeClassName="is-selected"
									className="nav-main__link"
								>
									{item.name}
								</NavLink>
							</li>
						);
					})}
				</ul>
				<UserMenu
					userInitials={config ? config.userInitials : '☰'}
					userFullName={config ? config.userFullName : null}
				/>
			</div>
		</header>
	);
};

export default MainHeader;
