import React from 'react';
import classNames from 'classnames';
import BoxFormMultiselect from '../../components/BoxFormMultiselect';
import TipBlock from '../../components/TipBlock';
import PropTypes from 'prop-types';

const CustomReportScoreCardDemos = function ({
	className,
	onChange = () => {},
	collection,
	selected,
	isClearSearchValue,
}) {
	return (
		<div className={classNames(className)}>
			<div className="flex-row">
				<div className="flex-col-3">
					<BoxFormMultiselect
						className="multiselect"
						caption="Pick a Wave(s)"
						optionList={collection.surveys}
						selectedList={selected.surveys}
						isClearSearchValue={isClearSearchValue}
						isRequired={true}
						onChange={(options) => {
							onChange({
								...selected,
								surveys: options,
							});
						}}
					/>
				</div>
			</div>

			<TipBlock title="Select a chain: " />
			<div className="flex-row">
				<div className="flex-col-3">
					<BoxFormMultiselect
						className="multiselect"
						caption="Chains"
						optionList={collection.chains}
						selectedList={selected.chainId === null ? [] : [selected.chainId]}
						isClearSearchValue={isClearSearchValue}
						isMultiselect={false}
						isTooltipOption={true}
						isRequired={true}
						onChange={([option = null]) => {
							onChange({
								...selected,
								chainId: option,
							});
						}}
					/>
				</div>
			</div>
		</div>
	);
};

CustomReportScoreCardDemos.propTypes = {
	className: PropTypes.string,
	collection: PropTypes.object,
	selected: PropTypes.object,
	onChange: PropTypes.func,
	isClearSearchValue: PropTypes.bool,
};

CustomReportScoreCardDemos.defaultProps = {
	className: null,
	collection: null,
	selected: null,
	onChange: () => {},
	isClearSearchValue: false,
};

export default CustomReportScoreCardDemos;
