import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { store } from './store/store';
import './utils/axios-interceptors';

import { getCollection } from './store/collectionActions';

import { ROOT_URL } from './utils/constants';

import CustomReport from './router/CustomReport';
import ChainsCategories from './router/ChainsCategories';
import MappingTool from './router/MappingTool';
import PresetsTool from './router/PresetsTool';
import Surveys from './router/Surveys';
import UpdateWordCloudView from './router/UpdateWordCloudView';
import MenuQuestions from './router/MenuQuestions';
import TopOfMind from './router/TopOfMind';
import NotFound from './router/NotFound';

import MainHeader from './components/MainHeader';
import Footer from './components/Footer';

window.store = store;

export default function App() {
	// console.log(process.env.NODE_ENV);
	// console.log(process.env.REACT_APP_API);

	useEffect(() => {
		store.dispatch(getCollection());
	}, []);

	return (
		<Provider store={store}>
			<BrowserRouter basename={`${ROOT_URL}`}>
				<MainHeader />
				<Switch>
					<Route exact path="/survey-settings/survey-panel/" component={Surveys} />
					<Route
						exact
						path="/survey-settings/chains-menu-categories/"
						component={ChainsCategories}
					/>
					<Route exact path="/survey-settings/menu-questions/" component={MenuQuestions} />

					<Route exact path="/analytics-settings/mapping-tool/" component={MappingTool} />
					<Route exact path="/analytics-settings/top-of-mind-items/" component={TopOfMind} />
					<Route
						exact
						path="/analytics-settings/top-of-mind-items/update-wordcloud-view/"
						component={UpdateWordCloudView}
					/>

					<Route exact path="/custom-generation-reports/" component={CustomReport} />
					<Route exact path="/presets-tool/" component={PresetsTool} />

					<Route exact path="/404" component={NotFound} />

					<Redirect exact from="/" to="/survey-settings/survey-panel/" />
					<Redirect exact from="/*" to="/404" />
				</Switch>
				<Footer />
			</BrowserRouter>
		</Provider>
	);
}
