import axios from 'axios';

axios.defaults.baseURL = `${process.env.REACT_APP_API}Admin/`;

axios.interceptors.request.use(function (config) {
	config.withCredentials = true;

	return config;
});

axios.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		if (error?.response?.status === 401) window.location.href = `${process.env.REACT_APP_API}UserAuth`;

		if (error?.response?.status === 403) window.location.href = `${process.env.REACT_APP_URL_SNAP}`;

		return Promise.reject(error);
	}
);
