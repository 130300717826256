import React from 'react';
import classNames from 'classnames';

export default function InputSwitch(props) {
	const { className = null, onChange = () => {}, disabled, ...property } = props;

	const { type = 'radio', ...otherProps } = property;

	return (
		<div
			className={classNames(
				'input-switch',
				`input-switch--${type}`,
				{
					'input-switch--disabled': disabled === true,
				},
				className
			)}
		>
			<input
				className="input-switch__input"
				type={type}
				onChange={onChange}
				disabled={disabled}
				{...otherProps}
			/>
			<div className={`input-switch-display input-switch-display--${type}`}>
				<div className={`input-switch-display__${type}`} />
			</div>
		</div>
	);
}
