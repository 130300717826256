import React, { Fragment, useState, useRef, useEffect } from 'react';
import TooltipPopover from './Tooltip.Popover';

export default function Tooltip(props) {
	const {
		children,
		enterDelay = 100,
		leaveDelay = 10,
		onClose,
		onOpen,
		isOpen,
		placement = 'bottom-center',
		content = null,
		className = null,
	} = props;

	// const [, forceUpdate] = useState(0);
	const enterTimer = useRef();
	const leaveTimer = useRef();
	const refNodeTarget = useRef(null);

	const [nodeTarget, setTargetNode] = useState(null);

	useEffect(() => {
		if (isOpen) {
			setTargetNode(refNodeTarget);
		}
		// forceUpdate((n) => !n);
	}, [isOpen]);

	useEffect(() => {
		return () => {
			clearTimeout(enterTimer.current);
			clearTimeout(leaveTimer.current);
		};
	}, []);

	const handleOpen = () => {
		setTargetNode(refNodeTarget);
		if (onOpen) onOpen(refNodeTarget);
	};

	const handleClose = (event) => {
		setTargetNode(null);
		if (onClose) onClose(event);
	};

	const handleEnter = (event) => {
		const childrenProps = children.props;

		if (event.type === 'mouseenter' && childrenProps.onMouseOver) {
			childrenProps.onMouseOver(event);
		}

		clearTimeout(enterTimer.current);
		clearTimeout(leaveTimer.current);

		if (enterDelay) {
			event.persist();
			enterTimer.current = setTimeout(() => {
				handleOpen(event);
			}, enterDelay);
		} else {
			handleOpen(event);
		}
	};

	const handleLeave = (event) => {
		const childrenProps = children.props;
		setTargetNode(null);

		if (event.type === 'mouseleave' && childrenProps.onMouseLeave) {
			childrenProps.onMouseLeave(event);
		}

		clearTimeout(enterTimer.current);
		clearTimeout(leaveTimer.current);

		event.persist();
		leaveTimer.current = setTimeout(() => {
			handleClose(event);
		}, leaveDelay);
	};

	return (
		<Fragment>
			{React.cloneElement(children, {
				...children.props,
				onMouseEnter: handleEnter,
				onMouseLeave: handleLeave,
				ref: (node) => {
					refNodeTarget.current = node;
					return node;
				},
			})}

			{nodeTarget && (
				<TooltipPopover className={className} nodeTarget={nodeTarget.current} placement={placement}>
					{content}
				</TooltipPopover>
			)}
		</Fragment>
	);
}
