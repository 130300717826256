import React, { useState, useEffect } from 'react';

import classNames from 'classnames';
import Button from '../../components/Button';
import InputSwitch from '../../components/InputSwitch';
import BoxFormMultiselect from '../../components/BoxFormMultiselect';
import Preloader from '../../components/Preloader';
import Notification from '../../components/Notification';
import InputText from '../../components/InputText';

import CustomReportStandart from './CustomReport.Standart';
import CustomReportScoreCard from './CustomReport.ScoreCard';
import CustomReportScoreCardDemos from './CustomReport.ScoreCardDemos';
import CustomReportNetPromoter from './CustomReport.NetPromoter';
import CustomReportUnmetNeeds from './CustomReport.UnmetNeeds';
import DataReports from './DataReports';

import {API_ROOT_URL, defaultFetchOptions, getCustomReportCollections} from '../../utils/api';
import DownloadFileService from '../../services/download-file-service';

const TAB = {
	RESTAURANT_ID: 1,
	GROCERY_ID: 2,
	CSTORE_ID: 3,
};

export const TYPE_EXPORT = {
	STANDARD: {
		id: 1,
		method: 'AllMetricsCustomGenerationReport',
	},
	SCORE_CARD: {
		id: 2,
		method: 'ScoreCardCustomGenerationReport',
	},
	SCORE_CARD_DEMOS: {
		id: 3,
		method: 'ScoreCardByRespondentTypesCustomGenerationReport',
	},
	DATA_REPORTS: {
		id: 4,
	},
	NET_PROMOTER: {
		id: 5,
		method: 'NetPromoterCustomGenerationReport',
	},
	UNMET_NEEDS: {
		id: 6,
		method: 'UnmetNeedsCustomGenerationReport',
	},
};

export const RESPONDENT_TYPE = {
	TOTAL_US: 1,
	WHITE: 9,
	NON_WHITE: 10,
	BLACK: 20,
	HISPANIC: 21,
	ASIAN: 22,
	OTHER: 23,
	_21_YEAR_OLD_PLUS: 8,
	GEN_Z: 4,
	MILLENNIALS: 5,
	GEN_X: 6,
	BOOMERS: 7,
};

const RESPONDENTSTATUSES = {
	CLOSED: 1,
	MANNUALLY_DETECTED: 13,
};

const initialSelected = {
	isCombined: true,
	isCombinedFilterByData: true,
	isRawData: false,
	tableTypes: [],
	surveys: [],

	respondentTypes: [],
	states: [],
	regions: [],
	regionAreas: [],
	metroAreas: [],
	dmaRegions: [],
	respondentStatuses: [RESPONDENTSTATUSES.CLOSED],

	questionId: null,
	answers: [],
	options: [],

	chains: [],
	questionChains: [],

	chainId: null,

	historySurveys: [],

	specificBrandId: null, // or presetId
	presetId: null, // or specificBrandId

	email: null,
};

export default function CustomGenerationReportView() {
	const [switchTab, setSwitchTab] = useState(TAB.RESTAURANT_ID);
	const [typeExport, setTypeExport] = useState(TYPE_EXPORT.STANDARD);
	// const [ isRawData, setIsRawData ] = useState(false);

	const [isLoading, setIsLoading] = useState(false);
	const [selected, setSelected] = useState(initialSelected);
	const [collection, setCollection] = useState(null);
	const [isClearSearchValue, setIsClearSearchValue] = useState(false);

	useEffect(() => {
		if (isClearSearchValue === true) setIsClearSearchValue(false);
	}, [isClearSearchValue]);

	useEffect(() => {
		if (collection === null)
			getCustomReportCollections(switchTab).then((response) => {
				if (response) setCollection(response);
			});
	}, [collection, switchTab]);

	const handleDownloadClick = () => {
		setIsLoading(true);

		let link = `DataRequests/${typeExport.method}`;
		let requestData = {
			chainType: switchTab,
			combinedSurveys: selected.isCombined,
			combinedFilterByData: selected.isCombinedFilterByData,
			rawData: selected.isRawData,

			surveys: selected.surveys,
			historySurveys: selected.historySurveys.length ? selected.historySurveys : null,
			respondentTypes: selected.respondentTypes.length ? selected.respondentTypes : null,

			tableTypes: selected.tableTypes.length
				? selected.tableTypes
				: collection.tableTypes.map((item) => item.id),

			states: selected.states,
			regions: selected.regions,
			regionAreas: selected.regionAreas,
			metroAreas: selected.metroAreas,
			dmaRegions: selected.dmaRegions,
			respondentStatuses: selected.respondentStatuses,

			question: selected.questionId,
			answers: selected.answers.length ? selected.answers : null,
			options: selected.options.length ? selected.options : null,
			isChainQuestion: selected.questionChains ? true : false,
			questionChains: selected.questionChains,

			chainId: selected.chainId,
			presetId: selected.presetId,
			chainToCompareId: selected.specificBrandId,
			email: selected.email,
		};
		
		let request;
		if(requestData.email != null) {
			request = fetch(`${API_ROOT_URL}${link}`, {
				...defaultFetchOptions,
				method: 'POST',
				body: JSON.stringify(requestData)
			})
		} 
		else {
			request = DownloadFileService.post(link, requestData)
		}
		
		request.then(() => {
			setIsLoading(false);
		});
	};

	const handleTabClick = (tab) => () => {
		setSelected(initialSelected);
		setSwitchTab(tab);
		setCollection(null);
		setTypeExport(TYPE_EXPORT.STANDARD);
	};

	const handleClearAllClick = () => {
		setIsClearSearchValue(true);
		setSelected(initialSelected);
	};

	const handleTypeExportCnange = (typeId) => () => {
		setTypeExport(typeId);
		setSelected(initialSelected);
	};

	const validTabs = [
		[
			isLoading === false,
			typeExport.id === TYPE_EXPORT.STANDARD.id,
			selected.surveys.length > 0,
			selected.respondentStatuses.length > 0,
		],
		[
			isLoading === false,
			typeExport.id === TYPE_EXPORT.SCORE_CARD.id,
			selected.chainId !== null,
			[selected.surveys, selected.historySurveys].every((list) => list.length > 0),

			[selected.presetId, selected.specificBrandId].some((item) => item !== null),
		],
		[
			isLoading === false,
			typeExport.id === TYPE_EXPORT.SCORE_CARD_DEMOS.id,
			selected.surveys.length > 0,
			selected.chainId !== null,
		],
		[
			isLoading === false,
			typeExport.id === TYPE_EXPORT.NET_PROMOTER.id,
			selected.surveys.length > 0,
			selected.respondentStatuses.length > 0,
		],
		[
			isLoading === false,
			typeExport.id === TYPE_EXPORT.UNMET_NEEDS.id,
			selected.surveys.length > 0,
			selected.respondentStatuses.length > 0,
		],
	];

	return (
		<div className="generation-report">
			<div className="generation-report-control">
				<div className="fake-background" />
				<div className="wrapper">
					<div className="generation-report-header">
						<div className="generation-report-header__tabs">
							<button
								className={classNames('button-reset', 'tab-button', {
									'is-selected': switchTab === TAB.RESTAURANT_ID,
								})}
								onClick={handleTabClick(TAB.RESTAURANT_ID)}
							>
								Restaurants
							</button>
							<button
								className={classNames('button-reset', 'tab-button', {
									'is-selected': switchTab === TAB.CSTORE_ID,
								})}
								onClick={handleTabClick(TAB.CSTORE_ID)}
							>
								c-stores
							</button>
							<button
								className={classNames('button-reset', 'tab-button', {
									'is-selected': switchTab === TAB.GROCERY_ID,
								})}
								onClick={handleTabClick(TAB.GROCERY_ID)}
							>
								Grocery
							</button>
						</div>
						<Notification>Reflects data on the live site (YE)</Notification>
					</div>

					<div className="generation-report-manager">
						<div className="generation-report-manager__navigation">
							<div>
								<Button
									isPrimary
									onClick={handleDownloadClick}
									disabled={
										validTabs.some(
											(tabConditions) => tabConditions.includes(false) === false
										) === false
									}
									isLoading={isLoading}
								>
									Create report
								</Button>
							</div>

							<div className="generation-report-export-select">
								<button
									className={classNames('button-reset', 'export-tab', {
										'is-selected': typeExport === TYPE_EXPORT.STANDARD,
									})}
									onClick={handleTypeExportCnange(TYPE_EXPORT.STANDARD)}
								>
									Standard Export
								</button>

								<button
									className={classNames('button-reset', 'export-tab', {
										'is-selected':
											typeExport === TYPE_EXPORT.SCORE_CARD ||
											typeExport === TYPE_EXPORT.SCORE_CARD_DEMOS,
									})}
									onClick={handleTypeExportCnange(TYPE_EXPORT.SCORE_CARD)}
								>
									SCORE CARD Export
								</button>

								<button
									className={classNames('button-reset', 'export-tab', {
										'is-selected':
											typeExport === TYPE_EXPORT.NET_PROMOTER ||
											typeExport === TYPE_EXPORT.UNMET_NEEDS,
									})}
									onClick={handleTypeExportCnange(TYPE_EXPORT.NET_PROMOTER)}
								>
									Measure details report
								</button>

								<button
									className={classNames('button-reset', 'export-tab', {
										'is-selected': typeExport === TYPE_EXPORT.DATA_REPORTS,
									})}
									onClick={handleTypeExportCnange(TYPE_EXPORT.DATA_REPORTS)}
								>
									Data Reports
								</button>
							</div>

							{collection && typeExport !== TYPE_EXPORT.DATA_REPORTS && (
								<div className="generation-report-manage__reset">
									<button
										className="button-reset button-clear"
										onClick={handleClearAllClick}
									>
										Clear all
									</button>
								</div>
							)}
						</div>

						{collection && (
							<div className="flex-row generation-report-manager__content">
								{typeExport.id === TYPE_EXPORT.STANDARD.id && (
									<>
										<div className="flex-col-3 description">
											Standard Export
											<br />
											(question per tab, all chains in rows)
										</div>
										<div className="flex-col-3">
											<BoxFormMultiselect
												className="multiselect"
												caption="Run a single question (instead<br /> of standard report w/all questions/ tabs)"
												optionList={collection.tableTypes}
												selectedList={selected.tableTypes}
												isClearSearchValue={isClearSearchValue}
												onChange={(options) => {
													setSelected({
														...selected,
														tableTypes: options,
													});
												}}
											/>
										</div>
									</>
								)}

								{(typeExport.id === TYPE_EXPORT.NET_PROMOTER.id ||
									typeExport.id === TYPE_EXPORT.UNMET_NEEDS.id) && (
									<div className="flex-col-4">
										<div className="score-card-switch">
											<label className="score-card-switch__item">
												<InputSwitch
													type="radio"
													name="type-export"
													checked={TYPE_EXPORT.NET_PROMOTER.id === typeExport.id}
													onChange={handleTypeExportCnange(
														TYPE_EXPORT.NET_PROMOTER
													)}
												/>
												<div className="score-card-switch__caption">Net Promoter</div>
											</label>
											<label className="score-card-switch__item">
												<InputSwitch
													type="radio"
													name="type-export"
													checked={TYPE_EXPORT.UNMET_NEEDS.id === typeExport.id}
													onChange={handleTypeExportCnange(TYPE_EXPORT.UNMET_NEEDS)}
												/>
												<div className="score-card-switch__caption">Unmet needs</div>
											</label>
										</div>
									</div>
								)}

								{(typeExport.id === TYPE_EXPORT.SCORE_CARD.id ||
									typeExport.id === TYPE_EXPORT.SCORE_CARD_DEMOS.id) && (
									<div className="flex-col-4">
										<div className="score-card-switch">
											<label className="score-card-switch__item">
												<InputSwitch
													type="radio"
													name="type-export"
													checked={TYPE_EXPORT.SCORE_CARD.id === typeExport.id}
													onChange={handleTypeExportCnange(TYPE_EXPORT.SCORE_CARD)}
												/>
												<div className="score-card-switch__caption">
													SCORE CARD Export
												</div>
											</label>
											<label className="score-card-switch__item">
												<InputSwitch
													type="radio"
													name="type-export"
													checked={
														TYPE_EXPORT.SCORE_CARD_DEMOS.id === typeExport.id
													}
													onChange={handleTypeExportCnange(
														TYPE_EXPORT.SCORE_CARD_DEMOS
													)}
												/>
												<div className="score-card-switch__caption">
													SCORE CARD Export by Demos (demos in headers)
												</div>
											</label>
										</div>
									</div>
								)}

								{typeExport !== TYPE_EXPORT.DATA_REPORTS &&
									typeExport !== TYPE_EXPORT.NET_PROMOTER &&
									typeExport !== TYPE_EXPORT.UNMET_NEEDS && (
										<div className="flex-col-3">
											<div className="caption">Send report to email</div>
											<InputText
												className="input-text-email"
												type="email"
												value={selected.email === null ? '' : selected.email}
												placeholder="Your email address"
												onChange={({ target }) => {
													setSelected({
														...selected,
														email: target.value ? target.value : null,
													});
												}}
											/>
											<div className="input-text-email-signature">
												(if empty - report will download directly from browser)
											</div>
										</div>
									)}
							</div>
						)}

						{typeExport.id === TYPE_EXPORT.SCORE_CARD_DEMOS.id && (
							<div className="flex-row data-format-selectors">
								<label className={classNames('data-format-selectors__label', {})}>
									<InputSwitch
										type="radio"
										checked={selected.isRawData === true}
										onChange={() => {
											setSelected({
												...selected,
												isRawData: !selected.isRawData,
											});
										}}
									/>
									<div className="combined__caption">Raw Data</div>
								</label>

								<label className={classNames('data-format-selectors__label', {})}>
									<InputSwitch
										type="radio"
										checked={selected.isRawData === false}
										onChange={() => {
											setSelected({
												...selected,
												isRawData: !selected.isRawData,
											});
										}}
									/>
									<div className="combined__caption">Percent value</div>
								</label>
							</div>
						)}

						{collection && typeExport !== TYPE_EXPORT.DATA_REPORTS && (
							<div className="notification-mark">
								<span className="required-mark">*</span>Indicated required
							</div>
						)}
					</div>
				</div>
			</div>
			<div className="wrapper">
				{collection ? (
					<div className="generation-report-content">
						{typeExport.id !== TYPE_EXPORT.DATA_REPORTS.id && (
							<div className="flex-row">
								<div className="flex-col-3">
									<label
										className={classNames('combined', {
											'combined--disabled':
												typeExport.id === TYPE_EXPORT.SCORE_CARD.id ||
												typeExport.id === TYPE_EXPORT.SCORE_CARD_DEMOS.id,
										})}
									>
										<InputSwitch
											type="checkbox"
											checked={selected.isCombined === true}
											disabled={
												typeExport.id === TYPE_EXPORT.SCORE_CARD.id ||
												typeExport.id === TYPE_EXPORT.SCORE_CARD_DEMOS.id
											}
											onChange={() => {
												setSelected({
													...selected,
													isCombined: !selected.isCombined,
												});
											}}
										/>
										<div className="combined__caption">Combined</div>
									</label>
								</div>
							</div>
						)}

						{(typeExport === TYPE_EXPORT.DATA_REPORTS) === true && <DataReports />}

						{typeExport.id === TYPE_EXPORT.STANDARD.id && (
							<CustomReportStandart
								collection={collection}
								selected={selected}
								isClearSearchValue={isClearSearchValue}
								onChange={(selected) => setSelected(selected)}
							/>
						)}

						{typeExport.id === TYPE_EXPORT.SCORE_CARD.id && (
							<CustomReportScoreCard
								collection={collection}
								selected={selected}
								isClearSearchValue={isClearSearchValue}
								onChange={(selected) => setSelected(selected)}
							/>
						)}

						{typeExport.id === TYPE_EXPORT.SCORE_CARD_DEMOS.id && (
							<CustomReportScoreCardDemos
								collection={collection}
								selected={selected}
								isClearSearchValue={isClearSearchValue}
								onChange={(selected) => setSelected(selected)}
							/>
						)}

						{typeExport.id === TYPE_EXPORT.NET_PROMOTER.id && (
							<CustomReportNetPromoter
								collection={collection}
								selected={selected}
								isClearSearchValue={isClearSearchValue}
								onChange={(selected) => setSelected(selected)}
							/>
						)}
						{typeExport.id === TYPE_EXPORT.UNMET_NEEDS.id && (
							<CustomReportUnmetNeeds
								collection={collection}
								selected={selected}
								isClearSearchValue={isClearSearchValue}
								onChange={(selected) => setSelected(selected)}
							/>
						)}
					</div>
				) : (
					<div className="generation-report-content">
						<Preloader />
					</div>
				)}
			</div>
		</div>
	);
}
