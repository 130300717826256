import React from 'react';
import { DropdownConsumer } from './DropdownContext';
import classNames from 'classnames';

const DropdownItem = ({ className = null, children, onClick = () => {}, ...otherProps }) => {
	return (
		<DropdownConsumer>
			{({ handleToggleClick }) => {
				return (
					<div
						onClick={handleToggleClick}
						className={classNames('dropdown-toggle', className)}
						{...otherProps}
					>
						{children}
					</div>
				);
			}}
		</DropdownConsumer>
	);
};

export default DropdownItem;
