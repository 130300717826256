export const objectSerialize = (obj, prefix) => {
	var str = [],
		p;
	for (p in obj) {
		if (obj.hasOwnProperty(p)) {
			var k = prefix ? prefix + '[' + p + ']' : p,
				v = obj[p];
			str.push(
				v !== null && typeof v === 'object'
					? objectSerialize(v, k)
					: encodeURIComponent(k) + '=' + encodeURIComponent(v)
			);
		}
	}
	return str.join('&');
};

export const stripHtml = (html) => {
	const node = document.createElement('div');
	node.innerHTML = html;
	return node.textContent || node.innerText || '';
};

export const smoothScrollTo = (to, duration) => {
	const element = document.scrollingElement || document.documentElement,
		start = element.scrollTop,
		change = to - start,
		startDate = +new Date(),
		// t = current time
		// b = start value
		// c = change in value
		// d = duration
		easeInOutQuad = (t, b, c, d) => {
			t /= d / 2;
			if (t < 1) return (c / 2) * t * t + b;
			t--;
			return (-c / 2) * (t * (t - 2) - 1) + b;
		},
		animateScroll = () => {
			const currentDate = +new Date();
			const currentTime = currentDate - startDate;
			element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration));
			if (currentTime < duration) {
				requestAnimationFrame(animateScroll);
			} else {
				element.scrollTop = to;
			}
		};

	setTimeout(() => {
		animateScroll();
	}, 1);
};

export function toLowerCaseFirstLetterJsonKeys(obj) {
	if (typeof obj !== 'object' || obj === null) {
		return obj;
	}
	if (Array.isArray(obj)) {
		return obj.map((o) => toLowerCaseFirstLetterJsonKeys(o));
	}
	return Object.keys(obj).reduce((prev, curr) => {
		prev[curr.charAt(0).toLowerCase() + curr.substr(1)] = toLowerCaseFirstLetterJsonKeys(obj[curr]);
		return prev;
	}, {});
}

export const defaultSortMethod = (a, b) => {
	// force null and undefined to the bottom
	a = a === null || a === undefined ? '' : a;
	b = b === null || b === undefined ? '' : b;
	// force any string values to lowercase
	a = typeof a === 'string' ? a.toLowerCase() : a;
	b = typeof b === 'string' ? b.toLowerCase() : b;
	// Return either 1 or -1 to indicate a sort priority
	if (a > b) {
		return 1;
	}
	if (a < b) {
		return -1;
	}
	// returning 0, undefined or any falsey value will defer to the next
	// sorting mechanism or eventually the columns index via the orderByFn
	return 0;
};
