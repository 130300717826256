import axios from 'axios';

class ConfigService {
	getConfig() {
		return axios({
			method: 'GET',
			url: 'InitialData/GetConfig',
			baseURL: process.env.REACT_APP_API,
		});
	}
}

export default new ConfigService();
