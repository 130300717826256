import React from 'react';

const Footer = () => (
	<footer className="main-footer">
		<div className="container main-footer__inner">
			<div className="main-footer__copyright">© Copyright {new Date().getFullYear()}</div>
		</div>
	</footer>
);

export default Footer;
