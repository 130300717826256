import React, { useState } from 'react';
import { createPortal } from 'react-dom';

const Portal = ({ children, className = '', element = 'div' }) => {
	const [node] = useState(document.createElement(element));

	if (className) node.classList.add(className);

	React.useEffect(() => {
		document.body.appendChild(node);

		return () => {
			document.body.removeChild(node);
		};
	}, [node]);

	return createPortal(children, node);
};

export default Portal;
