export const defaultSortMethod = (a, b) => {
	// force null and undefined to the bottom
	a = a === null || a === undefined ? '' : a;
	b = b === null || b === undefined ? '' : b;
	// force any string values to lowercase
	a = typeof a === 'string' ? a.toLowerCase() : a;
	b = typeof b === 'string' ? b.toLowerCase() : b;
	// Return either 1 or -1 to indicate a sort priority
	if (a > b) {
		return 1;
	}
	if (a < b) {
		return -1;
	}
	// returning 0, undefined or any falsey value will defer to the next
	// sorting mechanism or eventually the columns index via the orderByFn
	return 0;
};
