import React from 'react';
import classNames from 'classnames';
import BoxFormMultiselect from '../../components/BoxFormMultiselect';
import TipBlock from '../../components/TipBlock';
import InputSwitch from '../../components/InputSwitch';
import PropTypes from 'prop-types';
import { RESPONDENT_TYPE } from './CustomReport';
import { getActiveOption } from './helper';

const CustomReportNetPromoter = function ({
	className,
	onChange = () => {},
	collection,
	selected,
	isClearSearchValue,
}) {
	return (
		<div className={classNames(className)}>
			<div className="flex-row">
				<div className="flex-col-3">
					<BoxFormMultiselect
						className="multiselect"
						caption="Surveys"
						optionList={collection.surveys}
						selectedList={selected.surveys}
						isClearSearchValue={isClearSearchValue}
						isRequired={true}
						onChange={(options) => {
							onChange({
								...selected,
								surveys: options,
							});
						}}
					/>
				</div>
			</div>

			<TipBlock
				title="Filter the data by: "
				details="if choose none in this area, will run total US: "
			/>

			<div className="flex-row">
				<div className="flex-col-5">
					<label className="combined">
						<InputSwitch
							type="checkbox"
							checked={selected.isCombinedFilterByData === true}
							onChange={({ target }) => {
								onChange({
									...selected,
									isCombinedFilterByData: target.checked,
								});
							}}
						/>
						<div className="combined__caption">
							Combined <span>(if unchecked, selected demos in a separate XL file):</span>
						</div>
					</label>
				</div>
			</div>

			<div className="flex-row">
				<div className="flex-col-3">
					<BoxFormMultiselect
						className="multiselect"
						caption="Respondent Types"
						optionList={collection.respondentTypes.map((item) => {
							return {
								...item,
								disabled: getActiveOption(item, selected.respondentTypes, RESPONDENT_TYPE),
							};
						})}
						selectedList={selected.respondentTypes}
						isClearSearchValue={isClearSearchValue}
						onChange={(options) => {
							onChange({
								...selected,
								respondentTypes: options,
							});
						}}
					/>
				</div>
				<div className="flex-col-3">
					<BoxFormMultiselect
						className="multiselect"
						caption="Regions"
						optionList={collection.regions}
						selectedList={selected.regions}
						isClearSearchValue={isClearSearchValue}
						onChange={(options) => {
							onChange({
								...selected,
								regions: options,
							});
						}}
					/>
				</div>
				<div className="flex-col-3">
					<BoxFormMultiselect
						className="multiselect"
						caption="States"
						optionList={collection.states}
						selectedList={selected.states}
						isClearSearchValue={isClearSearchValue}
						onChange={(options) => {
							onChange({
								...selected,
								states: options,
							});
						}}
					/>
				</div>
				<div className="flex-col-3">
					<BoxFormMultiselect
						className="multiselect"
						caption="Region areas"
						optionList={collection.regionAreas}
						selectedList={selected.regionAreas}
						isClearSearchValue={isClearSearchValue}
						onChange={(options) => {
							onChange({
								...selected,
								regionAreas: options,
							});
						}}
					/>
				</div>
			</div>
			<div className="flex-row">
				<div className="flex-col-3">
					<BoxFormMultiselect
						className="multiselect"
						caption="DMA"
						optionList={collection.metroAreas}
						selectedList={selected.metroAreas}
						isClearSearchValue={isClearSearchValue}
						onChange={(options) => {
							onChange({
								...selected,
								metroAreas: options,
							});
						}}
					/>
				</div>

				<div className="flex-col-3">
					<BoxFormMultiselect
						className="multiselect"
						caption="DMA Group"
						optionList={collection.dmaRegions}
						selectedList={selected.dmaRegions}
						isClearSearchValue={isClearSearchValue}
						onChange={(options) => {
							onChange({
								...selected,
								dmaRegions: options,
							});
						}}
					/>
				</div>
			</div>
		</div>
	);
};

CustomReportNetPromoter.propTypes = {
	className: PropTypes.string,
	collection: PropTypes.object,
	selected: PropTypes.object,
	onChange: PropTypes.func,
	isClearSearchValue: PropTypes.bool,
};

CustomReportNetPromoter.defaultProps = {
	className: null,
	collection: null,
	selected: null,
	onChange: () => {},
	isClearSearchValue: false,
};

export default CustomReportNetPromoter;
