import React, { useRef } from 'react';
import classNames from 'classnames';

import ButtonSwitch from '../../../components/ButtonSwitch';

import { ReactComponent as IconFilter } from '../../../icons/filter.svg';

import { Dropdown, DropdownToggle, DropdownMenu } from '../../../components/Dropdown';

export default function DropdownTooltipWordType({
	className = null,
	options = [],
	selectedOptionId = null,
	onChange = () => {},
}) {
	const refDropdownComponent = useRef(null);

	const handleChangeButtonSwitch = (option) => {
		refDropdownComponent.current.closeDropdown();
		onChange(option);
	};

	return (
		<div className="dropdown-tooltip-word-type">
			<Dropdown
				className={classNames('dropdown-tooltip', className)}
				isOpened={false}
				ref={refDropdownComponent}
			>
				<DropdownToggle className="dropdown-tooltip-toggle">
					<IconFilter fill={selectedOptionId ? '#000000' : '#A8A8A8'} width={10} height={10} />
				</DropdownToggle>
				<DropdownMenu className="dropdown-tooltip-menu">
					<div className="arrow-icon" />
					<ButtonSwitch
						options={options}
						selectedOptionId={selectedOptionId}
						onChange={handleChangeButtonSwitch}
						name="word-type"
					/>
				</DropdownMenu>
			</Dropdown>
		</div>
	);
}
