import React, { useState } from 'react';
import classNames from 'classnames';

import { ReactComponent as IconSearch } from '../../icons/search.svg';
import { ReactComponent as IconRemove } from '../../icons/close.svg';

export default function InputSearch({
	className = null,
	placeholder = 'Search...',
	onChange = () => {},
	value = '',
}) {
	const [searchValue, setSearchValue] = useState(value);

	if (value !== searchValue) {
		setSearchValue(value);
	}

	const handleInputChange = (event) => {
		setSearchValue(event.target.value);
		onChange(event.target.value);
	};

	const handleRemoveButtonClick = (event) => {
		event.stopPropagation();
		setSearchValue('');
		onChange('');
	};

	return (
		<div className={classNames('input-search', className)}>
			{searchValue && (
				<button className="input-search__button-remove" onClick={handleRemoveButtonClick}>
					<IconRemove width={12} height={12} />
				</button>
			)}
			<input
				type="text"
				className="input-search__input"
				placeholder={placeholder}
				value={searchValue}
				onChange={handleInputChange}
			/>
			<IconSearch className="input-search__icon-search" />
		</div>
	);
}
