import React, { useState } from 'react';
import classNames from 'classnames';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import PropTypes from 'prop-types';

import InputSearch from '../../components/InputSearch';
import InputSwitch from '../../components/InputSwitch';
import { Tooltip, PLACEMENT } from '../../components/Tooltip';

const Row = ({
	index,
	style,
	data: { isMultiselect, selectedList, options, searchText, regex, onChange, isTooltipOption },
}) => {
	const { disabled = false, ...option } = options[index];

	const captionName = searchText ? option.name.replace(regex, ($1) => `<mark>${$1}</mark>`) : option.name;

	const SwitchItem = () => (
		<>
			<InputSwitch
				type={isMultiselect === true ? 'checkbox' : 'radio'}
				onChange={onChange(option.id)}
				checked={selectedList.indexOf(option.id) > -1}
				disabled={disabled}
			/>
			<div className="switch-caption" dangerouslySetInnerHTML={{ __html: captionName }} />
		</>
	);

	return (
		<div
			className={classNames('multiselect-selector-item', {
				'multiselect-selector-item--tooltipped': isTooltipOption === true,
			})}
			style={style}
		>
			{isTooltipOption === true ? (
				<Tooltip
					className="tooltip-box-form-multiselect"
					content={option.name}
					enterDelay={400}
					placement={PLACEMENT.TOP_LEFT}
				>
					<label className="multiselect-selector-item__switch">
						<SwitchItem />
					</label>
				</Tooltip>
			) : (
				<label
					className={classNames('multiselect-selector-item__switch', {
						'multiselect-selector-item--disabled': disabled === true,
					})}
					title={option.name}
				>
					<SwitchItem />
				</label>
			)}
		</div>
	);
};

const BoxFormMultiselect = function ({
	className,
	caption,
	description,
	onChange,
	optionList,
	selectedList,
	isDisabled = false,
	isMultiselect,
	isClearSearchValue,
	isRequired,
	isTooltipOption,
}) {
	const [searchText, setSearchText] = useState('');

	if (isClearSearchValue === true && searchText) setSearchText('');

	const handleClearClick = () => {
		onChange([]);
		setSearchText('');
	};

	const handleChekboxChange =
		(id) =>
		({ target }) => {
			if (target.checked === true) {
				onChange([...(isMultiselect === true ? selectedList : []), id]);
			} else {
				const selectedOptions = selectedList.filter((optionId) => optionId !== id);
				onChange(selectedOptions);
			}
		};

	const regex = new RegExp('\\b' + searchText.toLowerCase(), 'ig');
	const options = optionList.filter(({ name }) => name.toLowerCase().search(regex) !== -1);

	return (
		<div
			className={classNames(
				'box-form-multiselect',
				{
					'box-form-multiselect--disabled': isDisabled === true,
				},
				className
			)}
		>
			<div className="box-form-multiselect-header">
				<div className="box-form-multiselect-header__caption">
					{isRequired === true && <span className="required-mark">*</span>}
					<span dangerouslySetInnerHTML={{ __html: caption }} />
					<span className="count">
						{selectedList.length}/{optionList.length}
					</span>
				</div>

				<div className="box-form-multiselect-header__button">
					<button
						onClick={handleClearClick}
						className="button-reset button-clear"
						disabled={selectedList.length === 0 && searchText === ''}
					>
						Clear all
					</button>
				</div>
			</div>

			{description && <div className="box-form-multiselect-description">{description}</div>}

			<InputSearch
				value={searchText}
				className="box-form-multiselect-search"
				onChange={(valueText) => {
					const valueTextClean = valueText || '';
					setSearchText(valueTextClean);
				}}
			/>

			<div className="box-form-multiselect-selector">
				{options.length > 0 ? (
					<AutoSizer>
						{({ height, width }) => (
							<List
								height={height}
								width={width}
								itemCount={options.length}
								itemSize={24}
								itemData={{
									isMultiselect,
									selectedList,
									options,
									searchText,
									regex,
									onChange: handleChekboxChange,
									isTooltipOption,
								}}
							>
								{Row}
							</List>
						)}
					</AutoSizer>
				) : (
					<div className="box-form-multiselect-selector-message">Not found...</div>
				)}
			</div>
		</div>
	);
};

BoxFormMultiselect.propTypes = {
	className: PropTypes.string,
	caption: PropTypes.string,
	description: PropTypes.string,
	optionList: PropTypes.array,
	selectedList: PropTypes.array,
	isMultiselect: PropTypes.bool,
	isClearButtonActive: PropTypes.bool,
	isClearSearchValue: PropTypes.bool,
	isRequired: PropTypes.bool,
	isTooltipOption: PropTypes.bool,
};

BoxFormMultiselect.defaultProps = {
	className: null,
	caption: null,
	description: null,
	optionList: [],
	selectedList: [],
	onChange: () => {},
	isMultiselect: true,
	isClearSearchValue: false,
	isRequired: false,
	isTooltipOption: false,
};

export default BoxFormMultiselect;
