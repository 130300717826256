import React from 'react';
import classNames from 'classnames';
import { ReactComponent as CircleIcon } from '../../icons/info-circle.svg';

const Notification = ({ className = null, isInform = true, children }) => {
	return (
		<div className={classNames('notification', className)}>
			{isInform && <CircleIcon />}
			<div className="notification__description">{children}</div>
		</div>
	);
};

export default Notification;
