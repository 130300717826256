import React from 'react';
import { DropdownConsumer } from './DropdownContext';
import classNames from 'classnames';

const DropdownItem = ({ className = null, children, onClick = () => {}, ...otherProps }) => {
	return (
		<DropdownConsumer>
			{({ handleItemClick }) => {
				return (
					<div
						onClick={(event) => {
							handleItemClick(event);
							onClick(event);
						}}
						className={classNames('dropdown-item', className)}
						{...otherProps}
					>
						{children}
					</div>
				);
			}}
		</DropdownConsumer>
	);
};

export default DropdownItem;
