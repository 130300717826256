export const getActiveOption = (item, selected, RESPONDENT_TYPE) => {
	const conitions = [
		[selected.includes(RESPONDENT_TYPE.TOTAL_US) && item.id !== RESPONDENT_TYPE.TOTAL_US],
		[
			selected.length &&
				selected.every((item) => item !== RESPONDENT_TYPE.TOTAL_US) &&
				item.id === RESPONDENT_TYPE.TOTAL_US,
		],
		[
			selected.includes(RESPONDENT_TYPE._21_YEAR_OLD_PLUS) &&
				[
					RESPONDENT_TYPE.GEN_Z,
					RESPONDENT_TYPE.GEN_X,
					RESPONDENT_TYPE.MILLENNIALS,
					RESPONDENT_TYPE.BOOMERS,
				].some((type) => type === item.id),
		],
		[
			[
				RESPONDENT_TYPE.GEN_Z,
				RESPONDENT_TYPE.GEN_X,
				RESPONDENT_TYPE.MILLENNIALS,
				RESPONDENT_TYPE.BOOMERS,
			].some((type) => selected.includes(type)) && item.id === RESPONDENT_TYPE._21_YEAR_OLD_PLUS,
		],
		[
			[
				RESPONDENT_TYPE.BLACK,
				RESPONDENT_TYPE.HISPANIC,
				RESPONDENT_TYPE.ASIAN,
				RESPONDENT_TYPE.OTHER,
			].some((type) => selected.includes(type)) && item.id === RESPONDENT_TYPE.NON_WHITE,
		],
		[
			[RESPONDENT_TYPE.NON_WHITE, RESPONDENT_TYPE.WHITE].some((type) => selected.includes(type)) &&
				[
					RESPONDENT_TYPE.BLACK,
					RESPONDENT_TYPE.HISPANIC,
					RESPONDENT_TYPE.ASIAN,
					RESPONDENT_TYPE.OTHER,
				].some((type) => type === item.id),
		],
	];
	return conitions.some((conition) => conition.includes(true)) ? true : false;
};
