import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './reducer';

let composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const createStoreWithInitialState = (initialState = {}) => {
	if (process.env.NODE_ENV !== 'production') {
		composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
	} else {
		composeEnhancers = compose;
	}
	return createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
};

export const store = createStoreWithInitialState();
