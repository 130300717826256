import axios from 'axios';
import { saveAs } from 'file-saver';

class DownloadFileService {
	static getFileName(response) {
		const disposition =
			response.headers['content-disposition'] || response.headers['Content-Disposition'] || null;

		if (disposition && disposition.indexOf('attachment') !== -1) {
			const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
				matches = filenameRegex.exec(disposition);

			if (matches != null && matches[1]) return matches[1].replace(/['"]/g, '');
		}

		return 'NoNameFile';
	}

	saveResponseAs(response, resolve, reject) {
		try {
			const fileName = DownloadFileService.getFileName(response);
			saveAs(response.data, fileName);
			resolve();
		} catch (error) {
			reject(error);
		}
	}

	promiseWrap(request) {
		return new Promise((resolve, reject) => {
			request
				.then((response) => this.saveResponseAs(response, resolve, reject))
				.catch((error) => reject(error));
		});
	}

	get(resource = null, params = null) {
		const requestInstance = axios.get(resource, {
			...(params ? params : {}),
			responseType: 'blob',
		});

		return this.promiseWrap(requestInstance);
	}

	post(resource = null, request = null) {
		const requestInstance = axios.post(resource, request, {
			responseType: 'blob',
		});

		return this.promiseWrap(requestInstance);
	}
}

export default new DownloadFileService();
