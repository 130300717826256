import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { TableProvider } from './TableContext';

const columnDefault = {
	name: null,
	className: null,
	selector: null,
	sortable: false,
	columnHead: (column) => column.name,
	columnBody: ({ row, column }) => {
		return typeof row[column.selector] === 'undefined' ? null : row[column.selector];
	},
};

const Table = ({
	className = '',
	columns = [],
	onSortColumn = () => {},
	sort = {
		selector: null,
		isDesc: false,
	},
	children = null,
	isSyncSort = false,
}) => {
	const [sortColumn, setSortColumn] = useState(sort);

	useEffect(() => {
		if (isSyncSort === true) setSortColumn(sort);
	}, [sort, isSyncSort]);

	useEffect(() => {
		onSortColumn(sortColumn);
	}, [sortColumn, onSortColumn]);

	const handleColumnClick = (column) => (event) => {
		// event.preventDefault();

		if (column.sortable === true) {
			setSortColumn((prevState) => {
				const { selector: prevSortSelector = null, isDesc = false } = prevState || {};

				return {
					selector: column.selector,
					isDesc: column.selector === prevSortSelector ? !isDesc : isDesc,
				};
			});
		}
	};

	const columnList = columns.map((column) => ({
		...columnDefault,
		...column,
	}));

	return (
		<TableProvider
			value={{
				columnList,
				sortColumn,
				handleColumnClick,
			}}
		>
			<table className={classNames('table', className)}>{children}</table>
		</TableProvider>
	);
};

export default Table;
