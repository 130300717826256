import React, { useState, useEffect, Fragment, useRef, useImperativeHandle, forwardRef } from 'react';
import classNames from 'classnames';

import { Table, TableHeader, TableRows, TBody } from '../../components/Table';
import InputSearch from '../../components/InputSearch';
import DropdownPrimary from '../../components/DropdownPrimary';
import ModalDialog from '../../components/ModalDialog';
import { Tooltip, PLACEMENT } from '../../components/Tooltip';

import { ReactComponent as IconEye } from '../../icons/eye.svg';
import { ReactComponent as IconPencil } from '../../icons/pencil.svg';
import { ReactComponent as IconTrash } from '../../icons/trash.svg';

// import {smoothScrollTo} from '../../utils/helpers';

import PresetEditor from './PresetsTool.Editor';

const initialFilter = {
	typeList: [],
	segmentList: [],
	segmentGroupList: [],
	companyList: [],
};

const initialPreset = {
	id: 0,
	name: '',
	type: 1,
	segments: [],
	segmentGroup: null,
	chainsType: null,
	status: 1,
	chains: [],
	companiesList: [],
	isCustom: false,
};

const PresetsToolPresets = forwardRef(
	(
		{
			className = null,

			presets = [],
			chainList = [],
			types = [],
			segmentGroups = [],
			companies = [],
			presetStatusList = [],
			segments = [],
			filterChains = [],

			onRemovePreset = () => {},
			onSavePreset = () => {},
		},
		ref
	) => {
		const refNodeEditor = useRef(null);
		const refNodeEditorContainer = useRef(null);

		const [searchName, setSearchName] = useState('');
		const [filter, setFilter] = useState(initialFilter);
		const [viewPreset, setViewPreset] = useState(null);

		const [presetTrash, setTrashPreset] = useState(null);

		const viewPresetId = viewPreset ? viewPreset.id : null;

		useEffect(() => {
			refNodeEditorContainer.current.removeAttribute('style');

			if (refNodeEditor.current) {
				const nodeViewRow = document.querySelector('.cell-id-edit');

				refNodeEditor.current.parentNode.removeAttribute('style');

				if (nodeViewRow) {
					refNodeEditor.current.style.top = `${nodeViewRow.parentNode.offsetTop}px`;

					const DELTA_BOTTOM_PADDING = 150;

					setTimeout(() => {
						const offsetParentBottom =
							refNodeEditorContainer.current.offsetTop +
							refNodeEditorContainer.current.clientHeight;
						const offsetEditorBottom =
							nodeViewRow.parentNode.offsetTop +
							refNodeEditor.current.clientHeight +
							DELTA_BOTTOM_PADDING;

						if (offsetEditorBottom >= offsetParentBottom)
							refNodeEditorContainer.current.style.height = `${offsetEditorBottom}px`;
					}, 100);
				}

				setTimeout(() => {
					/* default browser behavior  */
					refNodeEditor.current.scrollIntoView({
						behavior: 'smooth',
						block: 'nearest',
						inline: 'center',
					});
				}, 310);
			}
		}, [viewPresetId]);

		useImperativeHandle(ref, () => ({
			openEditor() {
				setViewPreset(initialPreset);
			},
			closeEditor() {
				setViewPreset(null);
			},
		}));

		const handleClearAllClick = () => {
			setSearchName('');
			setFilter(initialFilter);
			setViewPreset(null);
		};

		const handleViewRowClick = (presetId) => () => {
			const preset = presets.find((preset) => preset.id === presetId);
			setViewPreset(preset);
		};

		const handlePresetChange = (presetProps) => {
			setViewPreset((preset) => ({
				...preset,
				...presetProps,
			}));
		};

		const handleToTrashClick = (preset) => () => {
			setViewPreset(null);
			setTrashPreset(preset);
		};

		const handleFromTrash = () => {
			setTrashPreset(null);
			onRemovePreset(presetTrash);
		};

		const handleSavePreset = () => {
			onSavePreset({
				...viewPreset,
				companiesList: viewPreset.companiesList.length === companies.length ? [] : viewPreset.companiesList,
			});
			setViewPreset(null);
		};

		const regex = new RegExp('\\b' + searchName.toLowerCase(), 'ig');

		const rows = presets
			.filter((preset) => {
				const filterPullList = [
					...(filterChains.length ? [filterChains.some((id) => preset.chains.includes(id))] : []),
					...(searchName.length ? [preset.name.toLowerCase().search(regex) !== -1] : []),

					...(filter.typeList.length ? [filter.typeList.includes(preset.type)] : []),
					...(filter.segmentGroupList.length
						? [filter.segmentGroupList.includes(preset.segmentGroup)]
						: []),
					...(filter.segmentList.length
						? [filter.segmentList.some((id) => preset.segments.includes(id))]
						: []),
					...(filter.companyList.length
						? [filter.companyList.some((id) => preset.companiesList.includes(id))]
						: []),
				];

				if (filterPullList.length === 0) return true;

				return filterPullList.every((state) => state === true);
			})
			.map((preset) => {
				return {
					...preset,

					typeName: types.find(({ id }) => id === preset.type)?.name.split(' ')[0],
					segmentNames: preset.segments.map((id) => {
						return segments.find((segment) => segment.id === id)?.name || '-';
					}),
					segmentGroupName: segmentGroups.find(({ id }) => id === preset.segmentGroup)?.name,
					companyNames: preset.companiesList.map((id) => {
						return companies.find((company) => company.id === id)?.name || '-';
					}),
					statusName: presetStatusList.find(({ id }) => id === preset.status)?.name || '-',
				};
			});

		const presetsByChains = presets.filter((preset) => {
			return filterChains.length ? filterChains.some((id) => preset.chains.includes(id)) : true;
		});

		return (
			<div className={classNames(className)}>
				<div className="preset-tool-table-control">
					<button className="button-reset button-clear" onClick={handleClearAllClick}>
						Clear filter
					</button>
				</div>
				<div ref={refNodeEditorContainer} className="preset-tool-table">
					<Table
						className="table-preset-control"
						columns={[
							{
								name: 'ID',
								selector: 'id',
								sortable: false,
								className: 'cell-id',
								columnBody: ({ column, row }) => {
									return (
										<span className={viewPreset?.id === row.id ? 'cell-id-edit' : null}>
											{row.id}
										</span>
									);
								},
							},
							{
								name: 'Name',
								selector: 'name',
								sortable: false,
								className: 'cell-name',
								columnHead: (column) => {
									return (
										<InputSearch
											className="name-search"
											value={searchName}
											placeholder={column.name}
											onChange={(valueText) => {
												const valueTextClean = valueText.trimStart() || '';
												setSearchName(valueTextClean);
												setViewPreset(null);
											}}
										/>
									);
								},
								columnBody: ({ column, row }) => row.name,
							},
							{
								name: 'Type',
								selector: 'type',
								sortable: false,
								className: 'cell-type',
								columnHead: (column) => {
									return (
										<DropdownPrimary
											className={classNames(
												'dropdown-filter',
												'dropdown-filter--without-scrollbar',
												{
													'dropdown-filter--changes': filter.typeList.length > 0,
												}
											)}
											placeholder={column.name}
											isMultiselect={true}
											isSearchField={true}
											isManageButtons={true}
											searchPlaceholder="Search type"
											optionList={types.map((type) => ({
												...type,
												name: type.name.split(' ')[0],
											}))}
											selectedList={filter.typeList}
											onChange={(options) =>
												setFilter({
													...filter,
													typeList: options,
												})
											}
											onToggle={(isOpened) => {
												if (isOpened) setViewPreset(null);
											}}
										/>
									);
								},
								columnBody: ({ column, row }) => row.typeName,
							},
							{
								name: 'Segment',
								selector: 'segment',
								sortable: false,
								className: 'cell-segment',
								columnHead: (column) => {
									return (
										<DropdownPrimary
											className={classNames('dropdown-filter', {
												'dropdown-filter--changes': filter.segmentList.length > 0,
											})}
											placeholder={column.name}
											isMultiselect={true}
											isSearchField={true}
											isManageButtons={true}
											searchPlaceholder="Search segment"
											optionList={segments.filter(({ id }) => {
												return presetsByChains.some((preset) =>
													preset.segments.includes(id)
												);
											})}
											selectedList={filter.segmentList}
											onChange={(options) =>
												setFilter({
													...filter,
													segmentList: options,
												})
											}
											onToggle={(isOpened) => {
												if (isOpened) setViewPreset(null);
											}}
										/>
									);
								},
								columnBody: ({ column, row }) => {
									return (
										<Tooltip
											className="tooltip-box-form-multiselect"
											content={row.segmentNames.join(', ')}
											enterDelay={400}
											placement={PLACEMENT.TOP_LEFT}
										>
											<div className="segment-name">{row.segmentNames.join(', ')}</div>
										</Tooltip>
									);
								},
							},
							{
								name: 'Segment group',
								selector: 'segmentGroup',
								sortable: false,
								className: 'cell-group',
								columnHead: (column) => {
									return (
										<DropdownPrimary
											className={classNames(
												'dropdown-filter',
												'dropdown-filter--without-scrollbar',
												{
													'dropdown-filter--changes':
														filter.segmentGroupList.length > 0,
												}
											)}
											placeholder={column.name}
											isMultiselect={true}
											isSearchField={true}
											isManageButtons={true}
											searchPlaceholder="Search group"
											optionList={segmentGroups.map((group) => {
												if (group.name.length) return group;
												return {
													...group,
													name: 'blank',
												};
											})}
											selectedList={filter.segmentGroupList}
											onChange={(options) =>
												setFilter({
													...filter,
													segmentGroupList: options,
												})
											}
											onToggle={(isOpened) => {
												if (isOpened) setViewPreset(null);
											}}
										/>
									);
								},
								columnBody: ({ column, row }) => row.segmentGroupName,
							},
							{
								name: 'Company',
								selector: 'companyNames',
								sortable: false,
								className: 'cell-company',
								columnHead: (column) => {
									return (
										<DropdownPrimary
											className={classNames('dropdown-filter', {
												'dropdown-filter--changes': filter.companyList.length > 0,
											})}
											placeholder={column.name}
											isMultiselect={true}
											isSearchField={true}
											isManageButtons={true}
											searchPlaceholder="Search company"
											optionList={companies.filter(({ id }) => {
												//return presets.some(preset => preset.companiesList.includes(id))
												return presetsByChains.some((preset) =>
													preset.companiesList.includes(id)
												);
											})}
											selectedList={filter.companyList}
											onChange={(options) =>
												setFilter({
													...filter,
													companyList: options,
												})
											}
											onToggle={(isOpened) => {
												if (isOpened) setViewPreset(null);
											}}
										/>
									);
								},

								columnBody: ({ column, row }) => {
									if (row.companyNames.length === 0) return 'All';

									if (row.companyNames.length === 1) return row.companyNames.join(', ');

									return `${row.companyNames.length} companies`;
								},
							},
							{
								name: 'Chains',
								selector: null,
								sortable: false,
								className: 'cell-chains',
								columnBody: ({ column, row }) => `${row.chains.length}`,
							},
							{
								name: 'Date modified',
								selector: null,
								sortable: false,
								className: 'cell-update',
								columnBody: ({ column, row }) => {
									return (
										<span>
											{row.lastUpdate && row.lastUpdate.date
												? new Date(row.lastUpdate.date)
														.toLocaleDateString('en')
														.replaceAll('/', '.')
												: '-'}
										</span>
									);
								},
							},
							{
								name: 'Editor Name',
								selector: null,
								sortable: false,
								className: 'cell-editor-name',
								columnBody: ({ column, row }) => {
									return (
										<span>
											{row.lastUpdate && row.lastUpdate.user
												? row.lastUpdate.user
												: '-'}
										</span>
									);
								},
							},
							{
								sortable: false,
								className: 'cell-buttons',
								columnBody: ({ column, row }) => {
									return (
										<div className="cell-buttons-group">
											<Fragment>
												<button
													className="button-edit"
													onClick={handleViewRowClick(row.id)}
												>
													<IconPencil />
												</button>
												<button
													className="button-remove"
													onClick={handleToTrashClick(row)}
												>
													<IconTrash />
												</button>
											</Fragment>
										</div>
									);
								},
							},
						]}
					>
						<TableHeader />
						<TBody>
							<TableRows rows={rows} />
						</TBody>
					</Table>

					{rows.length === 0 && (
						<div className="preset-tool-table-message">No matching records found</div>
					)}

					{viewPreset && (
						<PresetEditor
							ref={refNodeEditor}
							chainList={chainList}
							preset={{
								...viewPreset,
								typeName: types.find(({ id }) => id === viewPreset.type)?.name || null,
								segmentGroupName: segmentGroups.find(
									({ id }) => id === viewPreset.segmentGroup
								)?.name,
								segmentNames: viewPreset.segments.map((id) => {
									return segments.find((segment) => segment.id === id)?.name || '-';
								}),
							}}
							companies={companies}
							statuses={presetStatusList}
							onChange={handlePresetChange}
							onClose={() => setViewPreset(null)}
							onSave={handleSavePreset}
						/>
					)}

					{presetTrash && (
						<ModalDialog
							className="preset-tool-dialog"
							header={`Delete “${presetTrash.name}” preset`}
							onClose={handleToTrashClick(null)}
							buttons={[
								{
									className: 'button-cancel',
									name: 'Cancel',
									onClick: handleToTrashClick(null),
								},
								{
									className: 'button-delete',
									name: 'Delete',
									onClick: handleFromTrash,
								},
							]}
						>
							Are you sure that you want to delete it?
						</ModalDialog>
					)}
				</div>
			</div>
		);
	}
);

export default PresetsToolPresets;
