import React from 'react';
import classNames from 'classnames';

export default function ButtonSwitch({
	className = null,
	onChange = () => {},
	options = [],
	selectedOptionId = null,
	autocomplete = 'off',
	name = 'name',
}) {
	return (
		<div className={classNames('button-switch', className)}>
			{options?.map((option, index) => {
				const { name = null, id = null } = option;

				const handleInputChange = (option) => (event) => {
					event.stopPropagation();
					onChange(option);
				};

				const isChecked = id === selectedOptionId;

				return (
					<label
						className={classNames('button-switch-option', {
							'button-switch-option--selected': isChecked,
						})}
						key={`${index}-${id}`}
					>
						<input
							type="radio"
							name={name}
							autoComplete={autocomplete}
							onChange={handleInputChange(option)}
							checked={isChecked}
							className="button-switch-option__input"
						/>
						<div className="button-switch-option__caption">{name}</div>
					</label>
				);
			})}
		</div>
	);
}
