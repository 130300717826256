import React from 'react';
import classNames from 'classnames';

export default function InputText({ className = null, ...props }) {
	return (
		<div className={classNames('input-text', className)}>
			<input type="text" className="input-text__input" {...props} />
		</div>
	);
}
