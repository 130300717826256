import React from 'react';
import classNames from 'classnames';
import BoxFormMultiselect from '../../components/BoxFormMultiselect';
import TipBlock from '../../components/TipBlock';
import InputSwitch from '../../components/InputSwitch';

import PropTypes from 'prop-types';

import { stripHtml } from '../../utils/helpers';
import { RESPONDENT_TYPE } from './CustomReport';
import { getActiveOption } from './helper';

const CustomReportStandart = function ({
	className,
	onChange = () => {},
	collection,
	selected,
	isClearSearchValue,
}) {
	const {
		answers = [],
		options = [],
		isChainQuestion = false,
	} = collection.questions.find((question) => question.id === selected.questionId) || {};

	return (
		<div className={classNames(className)}>
			<div className="flex-row">
				<div className="flex-col-3">
					<BoxFormMultiselect
						className="multiselect"
						caption="Pick a Wave(s)"
						optionList={collection.surveys}
						selectedList={selected.surveys}
						isClearSearchValue={isClearSearchValue}
						isRequired={true}
						onChange={(options) => {
							onChange({
								...selected,
								surveys: options,
							});
						}}
					/>
				</div>
				<div className="flex-col-3">
					<BoxFormMultiselect
						className="multiselect"
						caption="History Wave(s)"
						optionList={collection.surveys}
						selectedList={selected.historySurveys}
						isClearSearchValue={isClearSearchValue}
						isRequired={false}
						onChange={(options) => {
							onChange({
								...selected,
								historySurveys: options,
							});
						}}
					/>
				</div>
			</div>

			<div className="flex-row">
				<div className="flex-col-3">
					<BoxFormMultiselect
						className="multiselect"
						caption="Status"
						isRequired
						optionList={collection.respondentStatuses}
						selectedList={selected.respondentStatuses}
						isClearSearchValue={isClearSearchValue}
						onChange={(options) => {
							onChange({
								...selected,
								respondentStatuses: options,
							});
						}}
					/>
				</div>
			</div>
			<TipBlock
				title="Filter the data by: "
				details="if choose none in this area, will run total US: "
			/>
			<div className="flex-row">
				<div className="flex-col-5">
					<label className="combined">
						<InputSwitch
							type="checkbox"
							checked={selected.isCombinedFilterByData === true}
							onChange={({ target }) => {
								onChange({
									...selected,
									isCombinedFilterByData: target.checked,
								});
							}}
						/>
						<div className="combined__caption">
							Combined <span>(if unchecked, selected demos in a separate XL file):</span>
						</div>
					</label>
				</div>
			</div>
			<div className="flex-row">
				<div className="flex-col-3">
					<BoxFormMultiselect
						className="multiselect"
						caption="Respondent Types"
						optionList={collection.respondentTypes.map((item) => {
							return {
								...item,
								disabled: getActiveOption(item, selected.respondentTypes, RESPONDENT_TYPE),
							};
						})}
						selectedList={selected.respondentTypes}
						isClearSearchValue={isClearSearchValue}
						onChange={(options) => {
							onChange({
								...selected,
								respondentTypes: options,
							});
						}}
					/>
				</div>
				<div className="flex-col-3">
					<BoxFormMultiselect
						className="multiselect"
						caption="Regions"
						optionList={collection.regions}
						selectedList={selected.regions}
						isClearSearchValue={isClearSearchValue}
						onChange={(options) => {
							onChange({
								...selected,
								regions: options,
							});
						}}
					/>
				</div>
				<div className="flex-col-3">
					<BoxFormMultiselect
						className="multiselect"
						caption="States"
						optionList={collection.states}
						selectedList={selected.states}
						isClearSearchValue={isClearSearchValue}
						onChange={(options) => {
							onChange({
								...selected,
								states: options,
							});
						}}
					/>
				</div>
				<div className="flex-col-3">
					<BoxFormMultiselect
						className="multiselect"
						caption="Region areas"
						optionList={collection.regionAreas}
						selectedList={selected.regionAreas}
						isClearSearchValue={isClearSearchValue}
						onChange={(options) => {
							onChange({
								...selected,
								regionAreas: options,
							});
						}}
					/>
				</div>
				<div className="flex-col-3">
					<BoxFormMultiselect
						className="multiselect"
						caption="DMA"
						optionList={collection.metroAreas}
						selectedList={selected.metroAreas}
						isClearSearchValue={isClearSearchValue}
						onChange={(options) => {
							onChange({
								...selected,
								metroAreas: options,
							});
						}}
					/>
				</div>

				<div className="flex-col-3">
					<BoxFormMultiselect
						className="multiselect"
						caption="DMA Group"
						optionList={collection.dmaRegions}
						selectedList={selected.dmaRegions}
						isClearSearchValue={isClearSearchValue}
						onChange={(options) => {
							onChange({
								...selected,
								dmaRegions: options,
							});
						}}
					/>
				</div>
			</div>

			<TipBlock
				title="Cross data by a specific measure/ chain:"
				details="(i.e. People who had an excellent last visit at Auntie Anns, how do they rate…)"
			/>

			<div className="flex-row">
				<div className="flex-col-6">
					<BoxFormMultiselect
						className="multiselect"
						caption="Cross data by a question"
						optionList={collection.questions.map((question) => {
							return {
								...question,
								name: stripHtml(question.name),
							};
						})}
						selectedList={[selected.questionId]}
						isClearSearchValue={isClearSearchValue}
						isMultiselect={false}
						isTooltipOption={true}
						onChange={([option = null]) => {
							onChange({
								...selected,
								questionId: option,
								answers: [],
								options: [],
							});
						}}
					/>
				</div>

				{answers.length > 0 && (
					<div className="flex-col-3">
						<BoxFormMultiselect
							className="multiselect"
							caption="Answers"
							optionList={answers}
							selectedList={selected.answers}
							isClearSearchValue={isClearSearchValue}
							isTooltipOption={true}
							onChange={(options) => {
								onChange({
									...selected,
									answers: options,
								});
							}}
						/>
					</div>
				)}

				{options.length > 0 && (
					<div className="flex-col-3">
						<BoxFormMultiselect
							className="multiselect"
							caption="Answers"
							optionList={options}
							selectedList={selected.options}
							isClearSearchValue={isClearSearchValue}
							isTooltipOption={true}
							onChange={(options) => {
								console.log(options);
								onChange({
									...selected,
									options: options,
								});
							}}
						/>
					</div>
				)}
			</div>

			{selected.questionId && isChainQuestion === true && (
				<div>
					<TipBlock details="(if choose none in this area, will run answers for ALL chains):" />
					<div className="flex-row">
						<div className="flex-col-3">
							<BoxFormMultiselect
								className="multiselect"
								type="checkbox"
								caption="Cross data by a chain"
								optionList={collection.chains}
								selectedList={selected.questionChains}
								isClearSearchValue={isClearSearchValue}
								isMultiselect={true}
								onChange={(options) => {
									onChange({
										...selected,
										questionChains: options,
									});
								}}
							/>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

CustomReportStandart.propTypes = {
	className: PropTypes.string,
	collection: PropTypes.object,
	selected: PropTypes.object,
	onChange: PropTypes.func,
	isClearSearchValue: PropTypes.bool,
};

CustomReportStandart.defaultProps = {
	className: null,
	collection: null,
	selected: null,
	onChange: () => {},
	isClearSearchValue: false,
};

export default CustomReportStandart;
