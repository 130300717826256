import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import Button from '../../components/Button';
import Notification from '../../components/Notification';
import InputSearch from '../../components/InputSearch';
import Preloader from '../../components/Preloader';

import { defaultFetchOptions, API_ROOT_URL } from '../../utils/api';
import DownloadFileService from '../../services/download-file-service';

import ChainsCategoriesService from '../../services/chains-categories-service';

import { ReactComponent as IconDownload } from '../../icons/download.svg';

const sortChains = ({ chains = [], sortColumn }) => {
	return chains
		.map((chain) => {
			const sortFiledIsChecked = chain.answers.includes(sortColumn.columnId);

			return {
				...chain,
				sortFiledIsChecked,
			};
		})
		.sort((a, b) => {
			if (sortColumn.isDesc === true) {
				if (a.sortFiledIsChecked < b.sortFiledIsChecked) return 1;
				if (a.sortFiledIsChecked > b.sortFiledIsChecked) return -1;
				return 0;
			} else {
				if (a.sortFiledIsChecked > b.sortFiledIsChecked) return 1;
				if (a.sortFiledIsChecked < b.sortFiledIsChecked) return -1;
				return 0;
			}
		});
};

const initialSortColumn = {
	columnId: null,
	isDesc: false,
};

export default function ChainsCategories() {
	const [isLoadingDownload, setIsLoadingDownload] = useState(false);
	const [isLoadingUpload, setIsLoadingUpload] = useState(false);
	const [file, setFile] = useState(null);
	const [sortColumn, setSortColumn] = useState(initialSortColumn);
	const [searchText, setSearchText] = useState('');
	const [chainsAnswers, setChainsAnswers] = useState(null);

	const refFileInput = useRef(null);

	useEffect(() => {
		if (chainsAnswers === null)
			ChainsCategoriesService.getChainsMenuCategories()
				.then(({ data }) => {
					if (data) setChainsAnswers(data);
				})
				.catch(() => {});
	}, [chainsAnswers]);

	const handleDownloadClick = () => {
		setIsLoadingDownload(true);

		DownloadFileService.get('DataRequests/MenuCategoriesReport').then(() => {
			setIsLoadingDownload(false);
		});
	};

	const handleUploadButtonClick = () => {
		refFileInput.current.click();
	};

	const handleUploadButtonChange = (event) => {
		const [file = null] = event.target.files;
		if (file && file.size > 0) setFile(file);
	};

	const handleUploadClick = () => {
		setIsLoadingUpload(true);

		const formData = new FormData();
		formData.append('file', file);

		fetch(`${API_ROOT_URL}Home/MapMenuCategories`, {
			...defaultFetchOptions,
			headers: {
				Accept: 'application/json',
			},
			method: 'POST',
			body: formData,
		}).then((response) => {
			setIsLoadingUpload(false);
			setChainsAnswers(null);
			setFile(null);
			setSortColumn(initialSortColumn);
		});
	};

	const handleColumnClick =
		(column = null) =>
		() => {
			if (column) {
				setSortColumn({
					columnId: column.id,
					isDesc: column.id === sortColumn.columnId ? !sortColumn.isDesc : initialSortColumn.isDesc,
				});
			} else {
				setSortColumn(initialSortColumn);
			}
		};

	const { chains = [], answers = [] } = chainsAnswers || {};

	const regex = new RegExp('\\b' + searchText.toLowerCase(), 'ig');

	const chainList = (sortColumn.columnId === null ? chains : sortChains({ chains, sortColumn })).filter(
		({ displayName }) => displayName.toLowerCase().search(regex) !== -1
	);

	return (
		<div className="wrapper chains-categories">
			<Notification>
				UPDATE ON QA SITE!!!! Programmers will move to Live site - make changes on the QA before
				launching the survey
			</Notification>
			<div className="chains-categories-header">
				<div className="chains-categories-header__step">
					<div className="step-title">1. Download current file to do changes</div>
					<Button
						onClick={handleDownloadClick}
						isLoading={isLoadingDownload}
						className="button-download"
						hasIcon={true}
					>
						<IconDownload />
						Dowload XL
					</Button>
				</div>
				<div className="chains-categories-header__step">
					<div className="step-title">2. Upload file with changes</div>
					{file && <div className="uploaded-file">{file.name}</div>}
					<Button onClick={handleUploadButtonClick} className="button-upload">
						Upload Changes
					</Button>
					<input
						accept=".xlsx"
						className="file-input"
						type="file"
						ref={refFileInput}
						onChange={handleUploadButtonChange}
					/>
				</div>
				<div className="chains-categories-header__step">
					<div className="step-title">3. Changes shown on the table</div>
					<Button
						className="button-map"
						isLoading={isLoadingUpload}
						onClick={handleUploadClick}
						disabled={file === null}
					>
						Map Menu Categories
					</Button>
				</div>
				<div className="chains-categories-header__step">
					<div className="step-title">4. Email programmer to move to live site</div>
				</div>
			</div>

			<div className="chains-categories-content">
				{chainsAnswers === null ? (
					<Preloader />
				) : (
					<table className="table chains-categories-table">
						<thead className="chains-categories-table-head">
							<tr className="row">
								<th className="cell cell--group">
									<div className="caption caption--category" onClick={handleColumnClick()}>
										Category
									</div>

									<InputSearch
										className="chains-categories-table__search"
										placeholder={'Chain name'}
										value={searchText}
										onChange={(valueText) => {
											const valueTextClean = valueText.trim() || '';
											setSearchText(valueTextClean);
										}}
									/>
								</th>
								{answers.map((answer) => {
									return (
										<th
											key={answer.id}
											onClick={handleColumnClick(answer)}
											className={classNames('cell', 'cell--category', 'cell--sorting')}
										>
											<div className="category" title={answer.text}>
												<div className="category__name">{answer.text}</div>
											</div>

											{answer.id === sortColumn.columnId && (
												<div
													className={classNames('sort-icon', {
														'sort-icon--asc':
															answer.id === sortColumn.columnId &&
															sortColumn.isDesc === false,
														'sort-icon--desc':
															answer.id === sortColumn.columnId &&
															sortColumn.isDesc === true,
													})}
												/>
											)}
										</th>
									);
								})}
							</tr>
						</thead>
						<tbody className="chains-categories-table-body">
							{chainList.map((chain, index) => {
								const captionName = searchText
									? chain.displayName.replace(regex, ($1) => `<mark>${$1}</mark>`)
									: chain.displayName;
								return (
									<tr key={`${index}${chain.fireflyChainId}`}>
										<td className="cell cell--chain">
											<span
												dangerouslySetInnerHTML={{
													__html: captionName,
												}}
											/>
										</td>
										{chainsAnswers.answers.map((answer) => {
											const isChecked = chain.answers.includes(answer.id);

											return (
												<td key={answer.id} className="cell cell--category">
													{isChecked === true && `✓`}
												</td>
											);
										})}
									</tr>
								);
							})}
						</tbody>
					</table>
				)}
				{chainList.length === 0 && searchText && (
					<div className="chains-categories-message">Not found ...</div>
				)}
			</div>
		</div>
	);
}
