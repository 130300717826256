export const API_ROOT_URL = `${process.env.REACT_APP_API}admin/`;

export const defaultFetchOptions = {
	method: 'GET',
	credentials: 'include',
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	},
};

export const API_LIST = {
	GET_CONFIG: {
		source: 'Api/GetConfig/',
		method: 'GET',
	},
};

export async function getChainsMenuCategories() {
	const response = await fetch(`${API_ROOT_URL}Home/GetChainsMenuCategories`, {
		...defaultFetchOptions,
		method: 'GET',
	});

	if (response.ok === true) return response.json();

	return Promise.reject(response);
}

export async function getCustomReportCollections(chainType) {
	const response = await fetch(
		`${API_ROOT_URL}DataRequests/GetCustomGenerationReportCollections?chainType=${chainType}`,
		{
			...defaultFetchOptions,
			method: 'GET',
		}
	);

	if (response.ok === true) return response.json();
}

export async function getGeneralCollections() {
	const response = await fetch(`${API_ROOT_URL}Presets/GetInitialData`, {
		...defaultFetchOptions,
		method: 'GET',
	});

	if (response.ok === true) return response.json();

	return Promise.reject(response);
}

export async function getPresets() {
	const response = await fetch(`${API_ROOT_URL}Presets/GetAll`, {
		...defaultFetchOptions,
		method: 'GET',
	});

	if (response.ok === true) return response.json();
}

export async function getChainDefaultPresetSettings() {
	const response = await fetch(`${API_ROOT_URL}Presets/GetChainDefaultPresetSettings`, {
		...defaultFetchOptions,
		method: 'GET',
	});

	if (response.ok === true) return response.json();
}

export async function setChainDefaultPresetSettings(request) {
	const response = await fetch(`${API_ROOT_URL}Presets/SetChainDefaultPresetSettings`, {
		...defaultFetchOptions,
		method: 'POST',
		body: JSON.stringify(request),
	});

	if (response.ok === true) return response;
}

export async function savePreset(request) {
	const response = await fetch(`${API_ROOT_URL}Presets/SavePreset`, {
		...defaultFetchOptions,
		method: 'POST',
		body: JSON.stringify(request),
	});

	if (response.ok === true) return response.json();
}

export async function removePreset(request) {
	const response = await fetch(`${API_ROOT_URL}Presets/DeletePreset`, {
		...defaultFetchOptions,
		method: 'POST',
		body: JSON.stringify(request),
	});

	if (response.ok === true) return response;
}

export async function getLastUpdateLog() {
	const response = await fetch(`${API_ROOT_URL}Presets/GetLastUpdateData`, {
		...defaultFetchOptions,
		method: 'GET',
	});

	if (response.ok === true) return response.json();
}

export async function getDataReportsLinks() {
	const response = await fetch(`${API_ROOT_URL}DataRequests/GetDataRequestsLinks`, {
		...defaultFetchOptions,
		method: 'GET',
	});

	if (response.ok === true) return response.json();
}

export async function updateWordCloud() {
	const response = await fetch(`${API_ROOT_URL}TopOfMind/UpdateWordCloud`, {
		...defaultFetchOptions,
		method: 'GET',
	});

	if (response.ok === true) return response.json();
}

export async function getWordCloudData() {
	const response = await fetch(`${API_ROOT_URL}TopOfMind/GetWordCloudData`, {
		...defaultFetchOptions,
		method: 'GET',
	});

	if (response.ok === true) return response.json();
}

export async function saveWordCloudScreenshot(request) {
	const response = await fetch(`${API_ROOT_URL}TopOfMind/SaveWordCloudScreenshot`, {
		credentials: 'include',
		headers: {
			Accept: 'application/json',
			// 'Content-Type': 'multipart/form-data',
			// 'Content-Type': 'application/x-www-form-urlencoded',
		},
		method: 'POST',
		body: request,
	});

	if (response.ok === true) return response.json();

	return null;
}
