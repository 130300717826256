import React from 'react';
// import { Link } from 'react-router-dom';

const NotFound = () => (
	<div className="container">
		<div className="not-found">
			<div className="not-found__caption">Page not found</div>
			<div className="not-found__name">404</div>
		</div>
	</div>
);

export default NotFound;
