import React from 'react';

const Preloader = ({ isCurtain = false, isCaption = true }) => {
	if (isCurtain === true)
		return (
			<div className="curtain-loader">
				{isCaption === true && (
					<div className="curtain-loader-word">
						<span className="curtain-loader-word__char">W</span>
						<span className="curtain-loader-word__char">A</span>
						<span className="curtain-loader-word__char">I</span>
						<span className="curtain-loader-word__char">T</span>
					</div>
				)}
			</div>
		);

	return (
		<div className="content-loader">
			<div className="content-loader-block content-loader-block--one" />
			<div className="content-loader-block content-loader-block--two" />
			<div className="content-loader-block content-loader-block--three" />
			<div className="content-loader-block content-loader-block--four" />
			<div className="content-loader-block content-loader-block--five" />
		</div>
	);
};

export default Preloader;
