import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import Button from '../../components/Button';
import Preloader from '../../components/Preloader';
import DropdownPrimary from '../../components/DropdownPrimary';

import PresetsToolPresets from './PresetsTool.Presets';
import PresetsToolChains from './PresetsTool.Chains';

import {
	getPresets,
	savePreset,
	removePreset,
	getChainDefaultPresetSettings,
	setChainDefaultPresetSettings,
	getLastUpdateLog,
} from '../../utils/api';

import DownloadFileService from '../../services/download-file-service';

const TAB = {
	PRESETS: 1,
	CHAINS: 2,
};

export default function PresetsTool() {
	const refPTComponent = useRef();

	const [switchTab, setSwitchTab] = useState(TAB.PRESETS);
	const [isLoadingExport, setIsLoadingExport] = useState(false);
	const [filterChains, setFilterChains] = useState([]);

	const [presets, setPresets] = useState([]);
	const [chains, setChains] = useState([]);

	const [lastUpdate, setLastUpdate] = useState(null);

	const {
		isLoaded: isLoadedCollection,
		companies,
		segmentGroups,
		chainSegmentGroups,
		types,
		presetStatusList,
		segments,
	} = useSelector(({ collection }) => {
		return {
			...collection,
		};
	});

	const blankChainSegmentGroup = {
		id: 3,
		name: 'blank',
	};

	useEffect(() => {
		getLastUpdateLog().then((response) => response && setLastUpdate(response));
	}, [presets]);

	useEffect(() => {
		const presets = getPresets();
		const chains = getChainDefaultPresetSettings();

		Promise.all([presets, chains]).then(([presets, chains]) => {
			setPresets(presets);
			setChains(chains);
		});
	}, []);

	const handleTabClick = (tab) => () => {
		setSwitchTab(tab);
		setFilterChains([]);
	};

	const handleOpenEditorClick = () => refPTComponent.current.openEditor();
	const handleCloseEditor = () => refPTComponent.current && refPTComponent.current.closeEditor();

	const handleExportPresetsClick = () => {
		setIsLoadingExport(true);

		DownloadFileService.get('DataRequests/GetPresetsReport').then(() => {
			setIsLoadingExport(false);
		});
	};

	const onChangeDefaultPreset = ({ defaultPresetId, chainId }) => {
		setChainDefaultPresetSettings({
			presetId: defaultPresetId,
			chainId,
		}).then(() => {
			const updatedChains = chains.map((chain) => {
				if (chain.id === chainId)
					return {
						...chain,
						defaultPresetId,
					};

				return chain;
			});

			setChains(updatedChains);
		});
	};

	const onSavePreset = (updatedPreset) => {
		savePreset(updatedPreset).then((responsePreset) => {
			const updatedPresets = updatedPreset.id
				? presets.map((preset) => {
						if (preset.id === responsePreset.id) {
							return {
								...preset,
								...responsePreset,
							};
						}

						return preset;
				  })
				: [...presets, responsePreset];

			setPresets(updatedPresets);
		});
	};

	const onRemovePreset = (preset) => {
		removePreset({
			id: preset.id,
		}).then(() => {
			const updatedPesets = presets.filter(({ id }) => id !== preset.id);
			setPresets(updatedPesets);
		});
	};

	const chainList = chains.map((chain) => {
		return {
			...chain,
			segmentGroupName: chainSegmentGroups.find(({ id }) => id === chain.segmentGroup)?.name,
			defaultPresetName: presets.find(({ id }) => id === chain.defaultPresetId)?.name,
		};
	});

	const isReady = isLoadedCollection === true && presets.length > 0;

	return (
		<div className="wrapper preset-tool">
			<div className="preset-tool-header">
				<div className="modify">
					{lastUpdate ? (
						<span>
							Last update {lastUpdate.user && `was by ${lastUpdate.user}`}
							{` on `}
							{new Date(lastUpdate.date).toLocaleDateString('en').replaceAll('/', '.')}
						</span>
					) : (
						<span>No changes</span>
					)}
				</div>
				<div className="flex-row preset-tool-header__nav">
					<div className="flex-col-6">
						<div className="tab-nav">
							<button
								className={classNames('button-reset', 'tab-nav__button', {
									'is-selected': switchTab === TAB.PRESETS,
								})}
								onClick={handleTabClick(TAB.PRESETS)}
							>
								Presets
							</button>
							<button
								className={classNames('button-reset', 'tab-nav__button', {
									'is-selected': switchTab === TAB.CHAINS,
								})}
								onClick={handleTabClick(TAB.CHAINS)}
							>
								Chains
							</button>
						</div>
					</div>
					{switchTab === TAB.PRESETS && (
						<div className="flex-col-6 preset-tool-header__control">
							<DropdownPrimary
								className="dropdown-chains"
								placeholder="All chains"
								toggleLabel={
									filterChains.length
										? `Chains ${filterChains.length} of ${chains.length}`
										: `All chains`
								}
								isMultiselect={true}
								isSearchField={true}
								searchPlaceholder="Search chain"
								optionList={chainList}
								selectedList={filterChains}
								onChange={(options) => setFilterChains(options)}
								onToggle={(isOpened) => {
									if (isOpened) handleCloseEditor();
								}}
								isManageButtons={true}
							/>
							<Button
								className="button-create"
								isSecondary
								onClick={handleOpenEditorClick}
								disabled={isReady === false}
							>
								Add new preset
							</Button>
						</div>
					)}
					{switchTab === TAB.CHAINS && (
						<div className="flex-col-6 preset-tool-header__control">
							<Button
								className="button-create"
								isSecondary
								onClick={handleExportPresetsClick}
								isLoading={isLoadingExport}
								disabled={isReady === false}
							>
								Export Presets Report
							</Button>
						</div>
					)}
				</div>
			</div>

			{isReady === true ? (
				<div className="preset-tool-content">
					{switchTab === TAB.PRESETS && (
						<PresetsToolPresets
							ref={refPTComponent}
							presets={presets}
							chainList={chainList}
							types={types}
							segmentGroups={segmentGroups}
							companies={companies}
							segments={segments}
							presetStatusList={presetStatusList}
							filterChains={filterChains}
							onRemovePreset={onRemovePreset}
							onSavePreset={onSavePreset}
						/>
					)}
					{switchTab === TAB.CHAINS && (
						<PresetsToolChains
							chainList={chainList}
							presets={presets}
							chainSegmentGroups={[...chainSegmentGroups, blankChainSegmentGroup]}
							segments={segments}
							onChangeDefaultPreset={onChangeDefaultPreset}
						/>
					)}
				</div>
			) : (
				<div className="preset-tool-content">
					<Preloader />
				</div>
			)}
		</div>
	);
}
