import React from 'react';
import classNames from 'classnames';

export default function Button({
	className = null,
	type = 'button',
	children,
	isPrimary = false,
	isSecondary = false,
	hasIcon = false,
	isLoading = false,
	...otherProps
}) {
	return (
		<button
			type={type}
			className={classNames(
				'button',
				{
					'button-primary': isPrimary === true,
					'button-secondary': isSecondary === true,
					'button--has-icon': hasIcon === true,
					'button--loading': isLoading === true,
				},
				className
			)}
			{...otherProps}
		>
			{children}
		</button>
	);
}
