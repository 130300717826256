import React, { useState } from 'react';

import Button from '../../components/Button';
import DropdownPrimary from '../../components/DropdownPrimary';
import InputText from '../../components/InputText';
import InputSwitch from '../../components/InputSwitch';

import { SURVEY_TYPE } from '../../utils/constants';

const surveyTypeOptions = [
	{
		id: SURVEY_TYPE.RESTAURANT,
		name: 'Restaurant',
	},
	{
		id: SURVEY_TYPE.SUPERMARKET_CSTORE,
		name: 'SupermarketCStore',
	},
];

export default function SurveyEdit({ providers = [], onSave = () => {}, onCancel = () => {} }) {
	const [firstProvider] = providers;

	const [name, setName] = useState('');
	const [quantity, setQuantity] = useState(null);
	const [providerId, setProviderId] = useState(firstProvider?.id);
	const [surveyTypeId, setSurveyTypeId] = useState(SURVEY_TYPE.RESTAURANT);

	const [isWithCustomStateDmaLogic, setIsWithCustomStateDmaLogic] = useState(false);
	const [isWithCustomStateDmaLogicDisabled, setIsWithCustomStateDmaLogicDisabled] = useState(false);

	const [completeLink, setCompleteLink] = useState('');
	const [overquotaLink, setOverquotaLink] = useState('');
	const [terminateLink, setTerminateLink] = useState('');
	const [qualifyTerminateLink, setQualifyTerminateLink] = useState('');

	const handleSaveSurveyClick = () =>
		onSave({
			respondentsCount: quantity,
			providerId,
			name,
			surveyType: surveyTypeId,
			withCustomStateDmaLogic: isWithCustomStateDmaLogic,
			completeLink,
			overquotaLink,
			terminateLink,
			qualifyTerminateLink
		});

	const handleTypeSurveyChange = ([id]) => {
		if (id === SURVEY_TYPE.SUPERMARKET_CSTORE) {
			setIsWithCustomStateDmaLogic(false);
			setIsWithCustomStateDmaLogicDisabled(true);
		} else {
			setIsWithCustomStateDmaLogicDisabled(false);
		}

		setSurveyTypeId(id);
	};

	const handleWithCustomStateDmaLogic = () => setIsWithCustomStateDmaLogic((currentState) => !currentState);

	return (
		<div className="surveys-modal-form">
			<div className="surveys-modal-form-header">Create New Survey</div>

			<div className="surveys-modal-form-content">
				<div className="surveys-modal-form-column">
					<div className="surveys-modal-form-field">
						<div className="surveys-modal-form-field__caption">Survey name:</div>
						<InputText placeholder="Name" value={name} onChange={({ target }) => setName(target.value)} />
					</div>
					<div className="surveys-modal-form-field">
						<div className="surveys-modal-form-field__caption">Respondents quantity:</div>
						<InputText
							placeholder="Quantity"
							value={quantity || ''}
							onChange={({ target }) => {
								const quantity = Number(target.value) > 0 ? Number(target.value) : null;
								setQuantity(quantity);
							}}
						/>
					</div>
					<div className="surveys-modal-form-field">
						<div className="surveys-modal-form-field__caption">Provider:</div>
						<DropdownPrimary
							className=""
							optionList={providers}
							selectedList={[providerId]}
							toggleLabel={providers.find(({ id }) => id === providerId)?.name}
							onChange={([id]) => setProviderId(id)}
						/>
					</div>
					<div className="surveys-modal-form-field">
						<div className="surveys-modal-form-field__caption">Survey type:</div>
						<DropdownPrimary
							className=""
							placeholder="Survey type"
							optionList={surveyTypeOptions}
							selectedList={[surveyTypeId]}
							toggleLabel={surveyTypeOptions.find(({ id }) => id === surveyTypeId)?.name}
							onChange={handleTypeSurveyChange}
						/>
					</div>

					<div className="surveys-modal-form-field">
						<label className="state-dma-logic">
							<div className="surveys-modal-form-field__caption">With State/DMA logic</div>
							<InputSwitch
								disabled={isWithCustomStateDmaLogicDisabled}
								className="state-dma-logic__switch"
								type="checkbox"
								onChange={handleWithCustomStateDmaLogic}
								checked={isWithCustomStateDmaLogic}
								autoComplete="off"
							/>
						</label>
					</div>
				</div>
				<div className="surveys-modal-form-column">
					<div className="surveys-modal-form-field">
						<div className="surveys-modal-form-field__caption">Complete link:</div>
						<InputText placeholder="" value={completeLink} onChange={({ target }) => setCompleteLink(target.value)} />
					</div>
					<div className="surveys-modal-form-field">
						<div className="surveys-modal-form-field__caption">Overquota link:</div>
						<InputText placeholder="" value={overquotaLink} onChange={({ target }) => setOverquotaLink(target.value)} />
					</div>
					<div className="surveys-modal-form-field">
						<div className="surveys-modal-form-field__caption">Terminate link:</div>
						<InputText placeholder="" value={terminateLink} onChange={({ target }) => setTerminateLink(target.value)} />
					</div>
					<div className="surveys-modal-form-field">
						<div className="surveys-modal-form-field__caption">Qualify terminate link:</div>
						<InputText placeholder="" value={qualifyTerminateLink} onChange={({ target }) => setQualifyTerminateLink(target.value)} />
					</div>
				</div>
			</div>
			
			<div className="surveys-modal-form-buttons">
				<Button
					isSecondary
					className="button-save"
					onClick={handleSaveSurveyClick}
					disabled={name.length === 0 || quantity === null}
				>
					Save
				</Button>
				<Button isSecondary className="button-cancel" onClick={onCancel}>
					Cancel
				</Button>
			</div>
		</div>
	);
}
