import React, { useState } from 'react';
import classNames from 'classnames';

import InputSearch from '../../components/InputSearch';
import InputSwitch from '../../components/InputSwitch';

import { ReactComponent as IconCheckMark } from '../../icons/check-mark.svg';

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from '../../components/Dropdown';

export default function DropdownPrimary({
	className = null,
	optionList = [],
	selectedList = null,
	onChange = () => {},
	isSearchField = false,
	isMultiselect = false,
	isManageButtons = false,
	placeholder = 'All options',
	toggleLabel = null,
	searchPlaceholder = 'Search...',
	isOpened = false,
	onToggle = () => {},
	isEditable = true,
}) {
	const [searchText, setSearchText] = useState('');

	const handleChekboxChange =
		({ id }) =>
		({ target }) => {
			if (target.checked === true) {
				onChange([...(isMultiselect === true ? selectedList : []), id]);
			} else {
				const selectedOptions = selectedList.filter((optionId) => optionId !== id);
				onChange(selectedOptions);
			}
		};

	const handleOptionClick =
		({ id }) =>
		() =>
			onChange([id]);

	const handleOnToggle = (isOpen) => {

		if (isOpen === false)
			setSearchText('');

		onToggle(isOpen);
	};

	const regex = new RegExp('\\b' + searchText.toLowerCase(), 'ig');
	const options = optionList.filter(({ name }) => name.toLowerCase().search(regex) !== -1);

	return (
		<Dropdown
			className={classNames('dropdown-primary', className)}
			isOpened={isOpened}
			onToggle={handleOnToggle}
		>
			<DropdownToggle className={classNames('dropdown-primary-toggle')}>
				<div className="dropdown-primary-toggle__caption">
					{toggleLabel ? toggleLabel : placeholder}
				</div>
				<div className="dropdown-primary-toggle__marker" />
			</DropdownToggle>
			<DropdownMenu className={classNames('dropdown-primary-menu')}>
				{isSearchField === true && (
					<InputSearch
						value={searchText}
						className="dropdown-primary-menu__search"
						placeholder={searchPlaceholder}
						onChange={(valueText) => {
							const valueTextClean = valueText.trimStart() || '';
							setSearchText(valueTextClean);
						}}
					/>
				)}

				{isMultiselect === true && isManageButtons === true && options.length > 0 && (
					<div className="dropdown-primary-menu-manage-buttons">
						<button
							className="dropdown-primary-menu-manage-buttons__button"
							onClick={() => {
								const selectedOptions = optionList?.map(({ id }) => id) ?? [];
								onChange(selectedOptions);
							}}
						>
							select all
						</button>
						<button
							className="dropdown-primary-menu-manage-buttons__button"
							onClick={() => {
								onChange([]);
							}}
						>
							clear all
						</button>
					</div>
				)}

				{options.length > 0 ? (
					<div className="dropdown-primary-options">
						{options.map((option, index) => {
							if (isMultiselect === false)
								return (
									<DropdownItem
										className={classNames('dropdown-primary-option', {
											'dropdown-primary-option--selected':
												selectedList.includes(option.id),
										})}
										onClick={handleOptionClick(option)}
										key={`${index}-${option.id}`}
									>
										<div className="dropdown-primary-option__caption">{option.name}</div>
									</DropdownItem>
								);

							return (
								<label
									className={classNames('dropdown-primary-option', {
										'dropdown-primary-option--disabled': isEditable === false,
									})}
									key={`${index}-${option.id}`}
								>
									{isEditable ? (
										<InputSwitch
											type="checkbox"
											onChange={handleChekboxChange(option)}
											checked={selectedList.includes(option.id) === true}
										/>
									) : (
										selectedList.includes(option.id) && (
											<IconCheckMark stroke={'#50565B'} viewBox={'0 0 14 11'} />
										)
									)}
									<div className="dropdown-primary-option__caption">{option.name}</div>
								</label>
							);
						})}
					</div>
				) : (
					<div className="message-empty-list">Nothing found...</div>
				)}
			</DropdownMenu>
		</Dropdown>
	);
}
