import React, { Fragment } from 'react';
import { TableConsumer } from './TableContext';
import { defaultSortMethod } from './utils';
import classNames from 'classnames';

const sortRows = (rows, columnList, sort) => {
	const { selector: sortSelector = null, isDesc = false } = sort || {};

	if (sortSelector) {
		const sortedRows = rows.slice().sort((a, b) => {
			a = a[sortSelector];
			b = b[sortSelector];

			return defaultSortMethod(a, b);
		});

		return isDesc === true ? sortedRows.reverse() : sortedRows;
	}

	return rows;
};

const TableRows = ({ className = '', rows = [], onRowClick = null, takeRows = null }) => {
	const handleRownClick = (row) => (event) => {
		if (typeof onRowClick === 'function') onRowClick(row, event);
	};

	return (
		<TableConsumer>
			{({ columnList, sortColumn }) => {
				const orderedRows = sortRows(rows, columnList, sortColumn);
				const rowList = Number.isInteger(takeRows)
					? orderedRows.filter((_, index) => index <= takeRows)
					: orderedRows;

				return (
					<Fragment>
						{rowList.map((row, indexRow) => {
							return (
								<tr key={indexRow} onClick={handleRownClick(row)}>
									{columnList.map((column, indexCell) => {
										const { className = null } = column;

										return (
											<td
												key={`${indexRow}-${indexCell}`}
												className={classNames('table-cell', className)}
											>
												{column.columnBody({
													row,
													column,
												})}
											</td>
										);
									})}
								</tr>
							);
						})}
					</Fragment>
				);
			}}
		</TableConsumer>
	);
};

export default TableRows;
