import React from 'react';
import { TableConsumer } from './TableContext';
import classNames from 'classnames';

const TableHeader = React.forwardRef(({ className }, ref) => {
	return (
		<TableConsumer>
			{({ columnList, sortColumn, handleColumnClick }) => {
				return (
					<thead ref={ref} className={classNames('table-head', className)}>
						<tr>
							{columnList.map((column, index) => {
								const { selector: sortSelector = null, isDesc = false } = sortColumn || {};

								return (
									<th
										key={index}
										className={classNames('table-head-cell', column.className, {
											'table-head-cell--sortable': column.sortable === true,
											'table-head-cell--asc':
												column.selector === sortSelector && isDesc === false,
											'table-head-cell--desc':
												column.selector === sortSelector && isDesc === true,
										})}
										onClick={handleColumnClick(column)}
									>
										<span className="table-head-caption">
											{column.columnHead(column)}
											{column.sortable === true && (
												<span className="table-head-cell-icon" />
											)}
										</span>
									</th>
								);
							})}
						</tr>
					</thead>
				);
			}}
		</TableConsumer>
	);
});

export default TableHeader;
