import React from 'react';
import classNames from 'classnames';

const Card = ({ className = null, children = null, header = null }) => {
	return (
		<div className={classNames('card', className)}>
			{header && <div className={classNames('card-header')}>{header}</div>}
			{children && <div className="card-content">{children}</div>}
		</div>
	);
};

export default Card;
