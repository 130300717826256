import React from 'react';

export default function TipBlock({ title = '', details = '', isRequired = false }) {
	return (
		<div className="tip-block">
			<div className="tip-block__title">
				{isRequired === true && <span className="required-mark">*</span>}
				{title}
			</div>
			<div className="tip-block__details">{details}</div>
		</div>
	);
}
