import React from 'react';
import { DropdownConsumer } from './DropdownContext';
import classNames from 'classnames';

const DropdownMenu = ({ className = null, children, ...otherProps }) => {
	return (
		<DropdownConsumer>
			{({ isOpen }) => {
				if (isOpen === false) return null;

				return (
					<div className={classNames('dropdown-menu', className)} {...otherProps}>
						{children}
					</div>
				);
			}}
		</DropdownConsumer>
	);
};

export default DropdownMenu;
