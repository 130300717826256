import React, { useState } from 'react';
import classNames from 'classnames';

import { Table, TableHeader, TableRows, TBody } from '../../components/Table';
import InputSearch from '../../components/InputSearch';
import DropdownPrimary from '../../components/DropdownPrimary';
import ModalDialog from '../../components/ModalDialog';

const initialFilter = {
	segmentIdList: [],
	segmentList: [],
	segmentGroupList: [],
	defaultPresetIdList: [],
	defaultPresetNameList: [],
};

const PresetsToolChains = function ({
	className = null,
	chainList = [],
	presets = [],

	chainSegmentGroups = [],
	segments = [],
	onChangeDefaultPreset = () => {},
}) {
	const [editChainId, setEditChainId] = useState(null);
	const [defaultPresetId, setDefaultPresetId] = useState(null);
	const [editChain, setEditChain] = useState(null);
	const [searchName, setSearchName] = useState('');
	const [filter, setFilter] = useState(initialFilter);

	const handleSetEditChainClick = (id) => () => setEditChainId(id);

	const handleClearAllClick = () => {
		setSearchName('');
		setFilter(initialFilter);
	};

	const regex = new RegExp('\\b' + searchName.toLowerCase(), 'ig');
	const rows = chainList.filter((chain) => {
		const filterPullList = [
			...(searchName.length ? [chain.name.toLowerCase().search(regex) !== -1] : []),

			...(filter.segmentIdList.length ? [filter.segmentIdList.includes(chain.segmentId)] : []),
			...(filter.segmentGroupList.length
				? [
						chain.segmentGroup === null
							? filter.segmentGroupList.includes(3)
							: filter.segmentGroupList.includes(chain.segmentGroup),
				  ]
				: []),
			...(filter.segmentList.length ? [filter.segmentList.includes(chain.segmentId)] : []),
			...(filter.defaultPresetIdList.length
				? [
						chain.defaultPresetId === null
							? filter.defaultPresetIdList.includes(null)
							: filter.defaultPresetIdList.includes(chain.defaultPresetId),
				  ]
				: []),
			...(filter.defaultPresetNameList.length
				? [
						chain.defaultPresetId === null
							? filter.defaultPresetNameList.includes(null)
							: filter.defaultPresetNameList.includes(chain.defaultPresetId),
				  ]
				: []),
		];

		if (filterPullList.length === 0) return true;

		return filterPullList.every((state) => state === true);
	});

	const uniqdefaultPresetId = chainList
		.map((chain) => chain.defaultPresetId)
		.filter((id, index, self) => self.indexOf(id) === index);

	const blankChainDefaultPresetName = {
		id: null,
		name: 'blank',
	};

	const editPreset = presets.find((preset) => preset.id === defaultPresetId);

	return (
		<>
			<div className="preset-tool-table-control">
				<button className="button-reset button-clear" onClick={handleClearAllClick}>
					Clear filter
				</button>
			</div>
			<div className={classNames('preset-tool-table preset-chains-table', className)}>
				<Table
					className="table-chains-control"
					columns={[
						{
							name: 'ID',
							selector: 'id',
							sortable: false,
							className: 'cell-id',
						},
						{
							name: 'Name',
							selector: 'name',
							sortable: false,
							className: 'cell-name',
							columnHead: (column) => {
								return (
									<InputSearch
										className="name-search"
										value={searchName}
										placeholder={column.name}
										onChange={(valueText) => {
											const valueTextClean = valueText.trimStart() || '';
											setSearchName(valueTextClean);
										}}
									/>
								);
							},
							columnBody: ({ column, row }) => row.name,
						},
						{
							name: 'Display Name',
							selector: 'displayName',
							sortable: false,
							className: 'cell-display',
						},
						{
							name: 'Segment ID',
							selector: 'segmentId',
							sortable: false,
							className: 'cell-segment-id',
							columnHead: (column) => {
								return (
									<DropdownPrimary
										className={classNames(
											'dropdown-filter',
											'dropdown-filter--without-scrollbar',
											{
												'dropdown-filter--changes': filter.segmentIdList.length > 0,
											}
										)}
										placeholder={column.name}
										isMultiselect={true}
										isSearchField={true}
										isManageButtons={true}
										searchPlaceholder="Search Segment ID"
										optionList={segments
											.filter(({ id }) => {
												return presets.some((preset) => preset.segments.includes(id));
											})
											.map(({ id }) => ({
												id,
												name: `${id}`,
											}))}
										selectedList={filter.segmentIdList}
										onChange={(options) =>
											setFilter({
												...filter,
												segmentIdList: options,
											})
										}
									/>
								);
							},
						},
						{
							name: 'Segment Name',
							selector: 'segmentName',
							sortable: false,
							className: 'cell-segment-name',
							columnHead: (column) => {
								return (
									<DropdownPrimary
										className={classNames('dropdown-filter', {
											'dropdown-filter--changes': filter.segmentList.length > 0,
										})}
										placeholder={column.name}
										isMultiselect={true}
										isSearchField={true}
										isManageButtons={true}
										searchPlaceholder="Search segment"
										optionList={segments.filter(({ id }) => {
											return presets.some((preset) => preset.segments.includes(id));
										})}
										selectedList={filter.segmentList}
										onChange={(options) => {
											setFilter({
												...filter,
												segmentList: options,
											});
										}}
									/>
								);
							},
						},
						{
							name: 'Segment Group',
							selector: 'segmentGroupName',
							sortable: false,
							className: 'cell-segment-group-name',
							columnHead: (column) => {
								return (
									<DropdownPrimary
										className={classNames(
											'dropdown-filter',
											'dropdown-filter--without-scrollbar',
											{
												'dropdown-filter--changes':
													filter.segmentGroupList.length > 0,
											}
										)}
										placeholder={column.name}
										isMultiselect={true}
										isSearchField={true}
										isManageButtons={true}
										searchPlaceholder="Search group"
										optionList={chainSegmentGroups}
										selectedList={filter.segmentGroupList}
										onChange={(options) =>
											setFilter({
												...filter,
												segmentGroupList: options,
											})
										}
									/>
								);
							},
						},
						{
							name: 'Default Preset ID',
							selector: 'defaultPresetId',
							sortable: false,
							className: 'cell-preset-id',
							columnHead: (column) => {
								return (
									<DropdownPrimary
										className={classNames(
											'dropdown-filter',
											'dropdown-filter--without-scrollbar',
											{
												'dropdown-filter--changes':
													filter.defaultPresetIdList.length > 0,
											}
										)}
										placeholder={column.name}
										isMultiselect={true}
										isSearchField={true}
										isManageButtons={true}
										searchPlaceholder="Search Preset ID"
										optionList={[
											...uniqdefaultPresetId
												.map((id) => {
													return presets.filter((preset) => preset.id === id);
												})
												.flat()
												.map(({ id }) => ({
													id,
													name: `${id}`,
												})),
											blankChainDefaultPresetName,
										]}
										selectedList={filter.defaultPresetIdList}
										onChange={(options) =>
											setFilter({
												...filter,
												defaultPresetIdList: options,
											})
										}
									/>
								);
							},
						},
						{
							name: 'Default Preset Name',
							selector: 'defaultPresetName',
							sortable: false,
							className: 'cell-preset-name',
							columnHead: (column) => {
								return (
									<DropdownPrimary
										className={classNames(
											'dropdown-filter',
											'dropdown-filter--without-scrollbar',
											{
												'dropdown-filter--changes':
													filter.defaultPresetNameList.length > 0,
											}
										)}
										placeholder={column.name}
										isMultiselect={true}
										isSearchField={true}
										isManageButtons={true}
										searchPlaceholder="Search Preset Name"
										optionList={[
											...uniqdefaultPresetId
												.map((id) => {
													return presets.filter((preset) => preset.id === id);
												})
												.flat(),
											blankChainDefaultPresetName,
										]}
										selectedList={filter.defaultPresetNameList}
										onChange={(options) =>
											setFilter({
												...filter,
												defaultPresetNameList: options,
											})
										}
									/>
								);
							},
							columnBody: ({ column, row }) => {
								if (row.id === editChainId) {
									return (
										<DropdownPrimary
											isOpened
											className="cell-dropdown"
											toggleLabel={row.defaultPresetName}
											isMultiselect={false}
											isSearchField={true}
											isManageButtons={true}
											searchPlaceholder="Search preset name"
											optionList={presets.filter(
												(preset) =>
													preset.isCustom === false &&
													row.type === preset.chainsType
											)}
											selectedList={[row.defaultPresetId]}
											onChange={([option]) => {
												setDefaultPresetId(option);
												setEditChain(row);
											}}
											onToggle={(isOpened) => {
												isOpened === false && setEditChainId(null);
											}}
										/>
									);
								} else {
									return (
										<div
											onClick={handleSetEditChainClick(row.id)}
											className="preset-selector"
										>
											{row.defaultPresetName}
											<div className="preset-selector__marker"></div>
										</div>
									);
								}
							},
						},
					]}
				>
					<TableHeader />
					<TBody>
						<TableRows rows={rows} />
					</TBody>
				</Table>

				{editChain && (
					<ModalDialog
						className="preset-tool-dialog default-name-modal"
						header={`“${editChain.name}” default preset is changed to “${editPreset.name}”`}
						onClose={() => setEditChain(null)}
						buttons={[
							{
								className: 'button-cancel',
								name: 'Cancel',
								onClick: () => setEditChain(null),
							},
							{
								className: 'button-save',
								name: 'Save',
								onClick: () => {
									onChangeDefaultPreset({
										defaultPresetId: defaultPresetId,
										chainId: editChain.id,
									});
									setEditChain(null);
								},
							},
						]}
					></ModalDialog>
				)}
			</div>
		</>
	);
};

export default PresetsToolChains;
