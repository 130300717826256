import React, { useState, useEffect, useRef, Fragment } from 'react';
import classNames from 'classnames';
import { defaultTableRowRenderer, AutoSizer, Column, Table, SortDirection } from 'react-virtualized';

import Preloader from '../../../components/Preloader';
import Button from '../../../components/Button';
import InputSearch from '../../../components/InputSearch';

import { DropdownTooltipChains } from './../DropdownTooltip';
import HeaderColumn from './../HeaderColumn';

import { sortRows } from './../helper';

import { MIN_COUNT_SEARCH_LETTERS, EXTENTION_TYPE } from './../constants';

import Notify from '../../../utils/notify';
import TOMService from '../../../services/tom-service';

import { ReactComponent as IconEye } from '../../../icons/eye.svg';
import { ReactComponent as IconRemove } from '../../../icons/remove.svg';

import { defaultSortMethod } from '../../../utils/helpers';

const notifyFactory = new Notify();

const initialTableConfig = {
	sortBy: 'n',
	sortDirection: SortDirection.DESC,
};

const initialOpenedTooltipChainsState = {
	indexId: null,
	useChainsState: []
};


export default function KeywordExtensionCard({
	className = null,
	extensionType = null,
	caption = null,
	keyword = null,
	chains = [],
	chainId = null,
	chainTypeId = null,
	extensions = null,
	isLoadingExtensions = false,
	onRemoveExtension = () => {},
	onAddedExtension = () => {},
	onChainsChange = () => {},
}) {
	const refTable = useRef(null);
	const [isLoading, setIsLoading] = useState(false);
	const [searchWord, setSearchWord] = useState('');
	const [busyRowIndexId, setBusyRowIndexId] = useState(null);
	const [tableConfig, setTableConfig] = useState(initialTableConfig);
	const [openedTooltipChainsState, setOpenedTooltipChainsState] = useState(initialOpenedTooltipChainsState);

	const extensionList = extensions
		? extensions.filter((extension) => {
				return extension.t !== extensionType ? false : true;
		  })
		: [];

	useEffect(() => {
		if (refTable.current) refTable.current.Grid._scrollingContainer.scrollTo(0, 0);
	}, [searchWord]);

	useEffect(() => {
		setTableConfig(initialTableConfig);
		setSearchWord('');
	}, [keyword]);

	const handleInputSearchChange = (valueText) => setSearchWord(valueText);

	const handleCreateExtentionClick = () => {
		const searchWordLowerCase = searchWord.toLowerCase();

		const sameExtensionByText =
			extensions?.find((extension) => extension.txt.toLowerCase() === searchWordLowerCase) || null;

		if (sameExtensionByText) {
			notifyFactory.add(
				`${sameExtensionByText.t === EXTENTION_TYPE.INCLUDE ? 'Includes' : 'Excludes'} already exists`
			);
		} else if (keyword.t.toLowerCase() === searchWordLowerCase) {
			notifyFactory.add(`The word is the same as the parent`);
		} else {
			setIsLoading(true);

			if (chainId === null) {
				TOMService.createGlobalExtension({
					text: searchWord,
					type: extensionType,
					keywordId: keyword.k || null,
				}).then(() => {
					setIsLoading(false);
					setSearchWord('');
					onAddedExtension();
				});
			} else {
				TOMService.createChainExtension({
					text: searchWord,
					type: extensionType,
					keywordId: keyword.k || null,
					chainId,
				}).then(() => {
					setIsLoading(false);
					setSearchWord('');
					onAddedExtension();
				});
			}
		}
	};

	const handleSortChange = ({ sortBy, sortDirection }) => {
		setTableConfig((prevState) => {
			return {
				...prevState,
				sortBy,
				sortDirection,
			};
		});

		if (refTable.current) refTable.current.Grid._scrollingContainer.scrollTo(0, 0);
	};

	const handleRemoveClick = (extension) => (event) => {
		event.stopPropagation();
		event.preventDefault();

		if (busyRowIndexId === null) {
			setBusyRowIndexId(extension.indexId);

			if (extension.i === null) {
				TOMService.deleteExtensionByText({
					text: extension.txt,
					keywordId: keyword.k || null,
				}).then(() => {
					setBusyRowIndexId(null);
					onRemoveExtension(extension);
				});
			} else {
				TOMService.deleteExtensionById({
					extensionId: extension.i,
				}).then(() => {
					setBusyRowIndexId(null);
					onRemoveExtension(extension);
				});
			}
		}
	};

	const handleChangeRowChains = ({ chains = [], rowData }) => {
		setBusyRowIndexId(rowData.indexId);

		TOMService.assignExtensionToChains({
			chainType: chainTypeId,
			keywordId: keyword.k || null,
			type: extensionType,
			chainsIds: chains.filter((chain) => chain.u).map((chain) => chain.i),
			text: rowData.txt,
		}).then(() => {
			setBusyRowIndexId(null);
			onChainsChange();
		});
	};

	const handleTooltipChainsState = useChains => {
		setOpenedTooltipChainsState(tooltipChainsState => ({
			...tooltipChainsState,
			useChainsState: useChains,
		}));
	}

	const handleTooltipChainsToggle = ({isOpen, rowData}) => {
		if (isOpen === true) {

			const useChains = Array.isArray(rowData.c) === true
			? rowData.c
			: [];

			const useChainList = useChains
				.map((useChain) => {
					const chain = chains.find((chain) => useChain.i === chain.id) || null;

					if (chain)
						return {
							...useChain,
							...chain,
							uDefault: useChain.u,
						};

					return null;
				})
				.sort((a, b) => {
					a = a['name'];
					b = b['name'];

					return defaultSortMethod(a, b);
				});

				console.log(useChainList);

			if (openedTooltipChainsState.indexId !== rowData.indexId)
				setOpenedTooltipChainsState({
					indexId: rowData.indexId,
					useChainsState: useChainList
				});
		}

		if (isOpen === false && rowData.indexId === openedTooltipChainsState.indexId)
			setOpenedTooltipChainsState(initialOpenedTooltipChainsState);
	}

	const regEx = new RegExp(searchWord.toLowerCase(), 'ig');
	const extensionsSelected = extensionList
		.filter((extension) => {
			return extension.txt?.toLowerCase().search(regEx) !== -1;
		})
		.map((word) => {
			return {
				...word,
				countUseChains: word.c?.reduce((sum, chain) => {
					return chain.u === true ? sum + 1 : sum;
				}, 0),
				countChains: word.c ? word.c?.length : null,
			};
		});

	const extensionsSorted = sortRows(extensionsSelected, {
		sortBy: tableConfig.sortBy,
		sortDirection: tableConfig.sortDirection,
	});

	const isAllowCreateRecord = searchWord.length >= MIN_COUNT_SEARCH_LETTERS;

	return (
		<div className="tom-card tom-card--extentions">
			{keyword === null ? (
				<div className="tom-card-cover">
					<div className="tom-card-cover__caption">
						Select a keyword
						<IconEye width={16} className="icon" fill="#30BFE7" />
					</div>
				</div>
			) : (
				<Fragment>
					<div className="tom-card-header">
						<div className="header-caption">
							{`${caption} for “`}
							{keyword && (
								<div className="word" title={keyword.t}>
									{keyword.t}
								</div>
							)}
							{`”`}
						</div>
					</div>
					<div className="tom-card-search">
						{isAllowCreateRecord === true && (
							<Button
								isSecondary
								className="tom-card-search__button"
								onClick={handleCreateExtentionClick}
							>
								Create
							</Button>
						)}
						<InputSearch
							value={searchWord}
							placeholder={`Search or create ${caption}...`}
							onChange={handleInputSearchChange}
						/>
					</div>
					<div className="tom-card-table">
						<AutoSizer>
							{({ width, height }) => (
								<Table
									className="tom-table"
									ref={refTable}
									rowClassName="row"
									gridClassName="grid"
									headerClassName="column"
									headerHeight={24}
									width={width}
									height={height}
									rowHeight={27}
									rowCount={extensionsSorted.length}
									rowGetter={({ index }) => extensionsSorted[index]}
									sort={handleSortChange}
									sortBy={tableConfig.sortBy}
									sortDirection={tableConfig.sortDirection}
									rowRenderer={({ className, rowData, ...row }) => {
										return defaultTableRowRenderer({
											className: classNames(className, {
												'row--busy': busyRowIndexId === rowData.indexId,
											}),
											rowData,
											...row,
										});
									}}
									noRowsRenderer={() => {
										return (
											<div className="tom-card-message tom-card-message--no-records">
												No {caption}
											</div>
										);
									}}
									rowStyle={{ overflow: 'visible' }}
									estimatedRowSize={1000}
								>
									<Column
										className="cell cell-remove"
										label={null}
										dataKey={'i'}
										width={18}
										cellRenderer={({ rowData }) => {
											if (rowData.c === null && rowData.g === true) return null;

											return (
												<button
													onClick={handleRemoveClick(rowData)}
													className="cell-remove__button"
												>
													<IconRemove width={10} height={10} fill="#ff0000" />
												</button>
											);
										}}
									/>
									<Column
										className="cell cell-text"
										dataKey={'txt'}
										width={chainId === null ? width * 0.35 : width * 0.55}
										headerRenderer={(column) => HeaderColumn('Name', column)}
										cellRenderer={({ rowData }) => {
											return <span className="word-name">{rowData.txt}</span>;
										}}
									/>
									<Column
										className="cell cell-count"
										headerClassName="column-count"
										headerRenderer={(column) => HeaderColumn('Count', column)}
										dataKey="n"
										width={60}
										defaultSortDirection={SortDirection.DESC}
										cellRenderer={({ rowData }) => {
											return rowData.n;
										}}
									/>
									{chainId === null && (
										<Column
											className="cell cell-chains"
											headerClassName="column-chains"
											headerRenderer={(column) => HeaderColumn('Chains', column)}
											dataKey="countUseChains"
											defaultSortDirection={SortDirection.DESC}
											width={64}
											style={{ overflow: 'visible' }}
											cellRenderer={({ rowData }) => {
												const countCaption =
													rowData.c === null
														? `-`
														: `${rowData.countUseChains}/${rowData.countChains}`;

												return (
													<DropdownTooltipChains
														captionToggle={countCaption}
														useChains={openedTooltipChainsState.useChainsState}
														isOpened={rowData.indexId === openedTooltipChainsState.indexId}
														nodeOffsetPosition={
															refTable.current.Grid._scrollingContainer
														}
														isEditable={rowData.s !== true}
														onToggle={(isOpen) => handleTooltipChainsToggle({isOpen, rowData})}
														onChange={handleTooltipChainsState}
														onApply={(chains) => {
															handleChangeRowChains({
																chains,
																rowData,
															});
														}}
													/>
												);
											}}
										/>
									)}
								</Table>
							)}
						</AutoSizer>
					</div>
				</Fragment>
			)}
			{[isLoadingExtensions, isLoading].includes(true) === true && <Preloader isCurtain={true} />}
		</div>
	);
}
