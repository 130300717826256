import { COLLECTION_ACTIONS } from './actionTypes';

const initialState = {
	isLoaded: false,
	chains: [],
	companies: [],
	segmentGroups: [],
	chainSegmentGroups: [],
	types: [],
	presetStatusList: [],
	segments: [],
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case COLLECTION_ACTIONS.STORE_COLLECTIONS:
			return {
				...state,
				isLoaded: true,
				...action.payload,
			};

		default:
			return state;
	}
}
