export const APP_NAME = 'BrandFingerprints by Datassential - Admin';

export const ROOT_URL = '/';

export const IS_ENV_DEV = process.env.NODE_ENV === 'development';

export const SURVEY_TYPE = {
	RESTAURANT: 1,
	SUPERMARKET_CSTORE: 2,
};

export const HEADER_MENU = [
	{
		menuName: 'Survey settings',
		menuNamePath: 'survey-settings',
		subMenu: [
			{
				name: 'Survey Panel',
				path: '/survey-settings/survey-panel/',
			},
			{
				name: "Chain's Menu Categories",
				path: '/survey-settings/chains-menu-categories/',
			},
			{
				name: 'Menu Questions',
				path: '/survey-settings/menu-questions/',
			},
		],
	},
	{
		menuName: 'Analytics settings',
		menuNamePath: 'analytics-settings',
		subMenu: [
			{
				name: 'Mapping Tool',
				path: '/analytics-settings/mapping-tool/',
			},
			{
				name: 'Top Of Mind Items',
				path: '/analytics-settings/top-of-mind-items/',
			},
		],
	},
	{
		name: 'Custom Reports',
		path: '/custom-generation-reports/',
	},
	{
		name: 'Presets Tool',
		path: '/presets-tool/',
	},
];