import React, { useState, useRef } from 'react';
import Button from '../../components/Button';
import Notification from '../../components/Notification';
import { ReactComponent as IconDownload } from '../../icons/download.svg';

import { API_ROOT_URL, defaultFetchOptions } from '../../utils/api';
import DownloadFileService from '../../services/download-file-service';

export default function MappingTool() {
	const [isLoadingDownloadSettings, setIsLoadingDownloadSettings] = useState(false);
	const [isLoadingDownloadResults, setIsLoadingDownloadResults] = useState(false);

	const [isLoadingUpload, setIsLoadingUpload] = useState(false);
	const [file, setFile] = useState(null);

	const refFileInput = useRef(null);

	const handleDownloadSettingsClick = () => {
		setIsLoadingDownloadSettings(true);

		DownloadFileService.get('Mapping/DownloadSettings').then(() => {
			setIsLoadingDownloadSettings(false);
		});
	};

	const handleDownloadResultsClick = () => {
		setIsLoadingDownloadResults(true);

		DownloadFileService.get('Mapping/DownloadResults').then(() => {
			setIsLoadingDownloadResults(false);
		});
	};

	const handleUploadButtonClick = () => {
		refFileInput.current.click();
	};

	const handleUploadButtonChange = (event) => {
		const [file] = event.target.files;
		if (file.size > 0) setFile(file);
	};

	const handleUploadClick = () => {
		setIsLoadingUpload(true);

		const formData = new FormData();
		formData.append('file', file);

		fetch(`${API_ROOT_URL}Mapping/MapChains`, {
			...defaultFetchOptions,
			headers: {
				Accept: 'application/json',
			},
			method: 'POST',
			body: formData,
		}).then((response) => {
			setIsLoadingUpload(false);
			setFile(null);
		});
	};

	return (
		<div className="wrapper mapping-tool">
			<Notification>
				Reflects data on the live site (YE) - make changes on the QA after the survey is closed
			</Notification>

			<div className="mapping-tool-navigation">
				<div className="mapping-tool-navigation__step">
					<div className="step-title">1. Download current file to do changes</div>
					<Button
						onClick={handleDownloadSettingsClick}
						isLoading={isLoadingDownloadSettings}
						className="button-download"
						hasIcon={true}
					>
						<IconDownload />
						Dowload Settings
					</Button>

					<Button
						onClick={handleDownloadResultsClick}
						isLoading={isLoadingDownloadResults}
						className="button-download"
						hasIcon={true}
					>
						<IconDownload />
						Dowload Results
					</Button>
				</div>
				<div className="mapping-tool-navigation__step">
					<div className="step-title">2. Upload file with changes</div>
					{file && <div className="uploaded-file">{file.name}</div>}
					<Button onClick={handleUploadButtonClick} className="button-upload">
						Upload Changes
					</Button>
					<input
						accept=".xlsx"
						className="file-input"
						type="file"
						ref={refFileInput}
						onChange={handleUploadButtonChange}
					/>
				</div>
				<div className="mapping-tool-navigation__step">
					<div className="step-title">3. Click to apply changes</div>
					<Button
						className="button-map"
						isLoading={isLoadingUpload}
						onClick={handleUploadClick}
						disabled={file === null}
					>
						Map Chains
					</Button>
				</div>
				<div className="mapping-tool-navigation__step">
					<div className="step-title">4. Email programmer to move to live site</div>
				</div>
			</div>
		</div>
	);
}
