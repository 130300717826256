import React from 'react';
import classNames from 'classnames';
import BoxFormMultiselect from '../../components/BoxFormMultiselect';
import TipBlock from '../../components/TipBlock';
import PropTypes from 'prop-types';
import { RESPONDENT_TYPE } from './CustomReport';
import { getActiveOption } from './helper';

const CustomReportScoreCard = function ({
	className,
	onChange = () => {},
	collection,
	selected,
	isClearSearchValue,
}) {
	return (
		<div className={classNames(className)}>
			<div className="flex-row">
				<div className="flex-col-3">
					<BoxFormMultiselect
						className="multiselect"
						caption="Pick a Wave(s)"
						optionList={collection.surveys}
						selectedList={selected.surveys}
						isClearSearchValue={isClearSearchValue}
						isRequired={true}
						onChange={(options) => {
							onChange({
								...selected,
								surveys: options,
							});
						}}
					/>
				</div>
				<div className="flex-col-3">
					<BoxFormMultiselect
						className="multiselect"
						caption="History Wave(s)"
						optionList={collection.surveys}
						selectedList={selected.historySurveys}
						isClearSearchValue={isClearSearchValue}
						isRequired={true}
						onChange={(options) => {
							onChange({
								...selected,
								historySurveys: options,
							});
						}}
					/>
				</div>
			</div>
			<TipBlock
				title="Filter the data by: "
				details="if choose none in this area, will run total US: "
			/>
			<div className="flex-row">
				<div className="flex-col-3">
					<BoxFormMultiselect
						className="multiselect"
						caption="Respondent Types"
						optionList={collection.respondentTypes.map((item) => {
							return {
								...item,
								disabled: getActiveOption(item, selected.respondentTypes, RESPONDENT_TYPE),
							};
						})}
						selectedList={selected.respondentTypes}
						isClearSearchValue={isClearSearchValue}
						onChange={(options) => {
							onChange({
								...selected,
								respondentTypes: options,
							});
						}}
					/>
				</div>
			</div>

			<TipBlock title="Select a chain: " />

			<div className="flex-row">
				<div className="flex-col-3">
					<BoxFormMultiselect
						className="multiselect"
						caption="Chains"
						optionList={collection.chains}
						selectedList={selected.chainId === null ? [] : [selected.chainId]}
						isClearSearchValue={isClearSearchValue}
						isMultiselect={false}
						isTooltipOption={true}
						isRequired={true}
						onChange={([option = null]) => {
							onChange({
								...selected,
								chainId: option,
							});
						}}
					/>
				</div>
			</div>
			<TipBlock
				title="Select a competitor: "
				details="(choose only one of the area, Competitive set or Specific brands): "
			/>

			<div className="flex-row">
				<div className="flex-col-3">
					<BoxFormMultiselect
						className="multiselect"
						caption="Competitive set"
						optionList={collection.presets}
						selectedList={selected.presetId === null ? [] : [selected.presetId]}
						isClearSearchValue={isClearSearchValue}
						isTooltipOption={true}
						isMultiselect={false}
						isRequired={true}
						onChange={([option = null]) => {
							onChange({
								...selected,
								presetId: option,
								specificBrandId: null,
							});
						}}
					/>
				</div>

				<div className="flex-col-3">
					<BoxFormMultiselect
						className="multiselect"
						caption="Specific brands"
						optionList={collection.chains}
						selectedList={selected.specificBrandId === null ? [] : [selected.specificBrandId]}
						isClearSearchValue={isClearSearchValue}
						isTooltipOption={true}
						isMultiselect={false}
						isRequired={true}
						onChange={([option = null]) => {
							onChange({
								...selected,
								specificBrandId: option,
								presetId: null,
							});
						}}
					/>
				</div>
			</div>
		</div>
	);
};

CustomReportScoreCard.propTypes = {
	className: PropTypes.string,
	collection: PropTypes.object,
	selected: PropTypes.object,
	onChange: PropTypes.func,
	isClearSearchValue: PropTypes.bool,
};

CustomReportScoreCard.defaultProps = {
	className: null,
	collection: null,
	selected: null,
	onChange: () => {},
	isClearSearchValue: false,
};

export default CustomReportScoreCard;
